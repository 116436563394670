/* responsive css */
@media all and (max-width: 1160px){
    .all-ticket{
        .card{
            h4{
                font-size: 14px;
            }
        }
    }
    .all-ticket-list {
        li{
            &:nth-child(2){
                padding:15px 0 15px 15px;
            }
        }
    }
    
    .all-ticket-header{
        .logo-box {
            img{
                max-width:76px;
            }
        }
        .title-box{
            padding:0 0 0 89px;
            h5 {
                font-size: 16px;
                line-height: 26px;
            }
        }
    }
}
@media all and (max-width: 768px) {
    .all-ticket-list {
        li{
            width:100%;
            &:nth-child(1){
                width: 100%;
            }
            &:nth-child(2){
                padding:15px 0 15px 1px;
            }
        }
        .ticket-details{
            ul{
                li{
                    width:50%;
                }
            }
        }
        .esclation-box{
            width:auto;
            margin: 10px 15px;
            .level-one,.level-two,.level-three{
                margin:5px 21px;
            }
        }
        .email-chain{
            width: 90%;
            margin: 0 0 0 20px;
            ul {
                li{
                    ul{
                        &.inbound-call-option{
                            text-align: left;
                            margin: 8px 0 5px;
                        }
                    }
                    // .mail-timing{
                    //     top:-24px;
                    //     right:4px;
                    // }
                    button{
                        &.response-chip{
                            margin:10px;
                        }
                    }
                    .create-update{
                        padding:0;
                        text-align: left;
                    }
                    .mail-data{
                        .audio-box{
                            .player{
                                width:73%;
                            }
                            .audio-progress{
                                width:75%;
                            }
                        }
                    }
                }
            }
            
            .sub-status{
                width:91.1%;
                margin:25px 0 10px 11px;
                &::before{
                    left:-16px;
                }
                .status-txt{
                    h5{
                        padding:25px 15px 0 0;
                    }
                }
                .status-date-time{
                    right:2px;
                    top:0;
                }
            }
            
        }
        .esclation-resoution-timer{
            ul{
                li{
                    padding: 7px 0 8px;
                    width: 100%;
                    text-align: center;
                    p{
                        line-height: 16px;
                    }
                    &:nth-child(1){
                        &::after{
                            display:none;
                        }
                    }
                }
            }
        }
        .topbar-data{
            position: relative;
            text-align: left;
            .ticket-id-flag{
                position: relative;
            }
            ul{
                text-align: right;
            }
        }
    }
    .all-ticket{
        position:unset;
        padding:0;
        width:100%;
        > div{
            padding: 10px;
        }
    }
    
    .activity-box {
        .view-comments{
            text-align: center;
            margin:10px 0 0
        }
    }
    .dialog-box-comments > .MuiDialog-container{
        padding-left:0;
    }
    .communication-box {
        ul{
            li{
                width: 25% !important;
            }
        }
    }
    .topbar-data{
        button{
            min-width: 82px !important;
            padding: 0 6px!important;
        }
    }
    .comment-submit-data{
        li{
            &:nth-child(1){
                width:50%;
            }
        }
    }
    .escalation-popup{
        .MuiDialog-paperWidthMd{
            max-width: 100% !important;
            padding: 0 !important;
            left: 0;
            right: 0;
            margin: auto;
            height: 100vh;
            top: 7px;
            border-radius: 0 !important;
        }
    }
}

