// communication Box
.communication-box {
  margin: 15px auto 0;
  padding: 0px;
  width: 100%;
  text-align: center;
  ul {
    display: block;
    padding: 0;
    li {
      vertical-align: middle;
      background: #fff;
      border-radius: 8px;
      padding: 5px 0 !important;
      width: 96px;
      margin: 5px 2px;
      display: inline-block;
      text-align: left;
      box-shadow: 0px 4px 3px #00000024; // independence day changes 
      &:nth-child(1) {
        width: 74px;
      }
      &:nth-child(2) {
        button {
          background-image: url("/images/whatsapp_icon.svg");
        }
      }
      button {
        // border-radius: 46px;
        padding: 0;
        margin: 0 5px 0 3px;
        height: 26px;
        min-width: 23px;
        display: inline-block;
        span {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          display: block;
          margin: 0 auto;
          text-align: center;
          line-height: 23px;
          color: #fff;
          &.phone {
            background-color: #4ace45;
            i {
              font-size: 13px;
              padding: 7px;
              display: inline-block;
            }
          }
          &.envelope {
            background-color: #1977d5;
            i {
              font-size: 16px;
              padding: 7px;
              display: inline-block;
            }
          }
          &.calender {
            background-color: #f08a67;
            i {
              font-size: 16px;
              padding: 7px;
              display: inline-block;
            }
          }
          &.assign {
            background-color: #decb49;
            i {
              font-size: 16px;
              padding: 7px;
              display: inline-block;
            }
          }
        }
        &:hover {
          // border: 1px solid #99D6E8;
          background-color: transparent;
        }
        &.phone {
          min-width: 34px;
          cursor: pointer;
          span {
            display: inline-block;
            .phone-icon {
              display: inline-block;
              text-align: left;
              width: 65px;
              color: #414141;
              font-size: 12px;
              line-height: 14px;
              color: #253858;
              font-style: normal;
              text-transform: capitalize;
              i {
                background-color: #4ace45;
                font-size: 12px;
                color: #fff;
                padding: 4px 5px 5px;
                display: inline-block;
                margin: 0 5px 0 0;
                border-radius: 50%;
                width: 9px;
                height: 10px;
              }
            }
          }
        }
        &.policy-copy {
          font-size: 12px;
          line-height: 14px;
          color: #253858;
          font-style: normal;
          text-decoration: none;
          display: inline-block;
          padding: 5px;
          margin: 0;
          span {
            width: 100%;
            font-size: 11px;
            line-height: 14px;
            color: #253858;
            font-style: normal;
            cursor: pointer;
            font-weight: 499;
            text-transform: capitalize;
          }
          img {
            max-width: 15px;
            margin-left: 4px;
            margin: -3px 0 0 3px;
          }
        }
      }
      em {
        font-size: 12px;
        line-height: 14px;
        color: #253858;
        font-style: normal;
        cursor: pointer;
        font-weight: 499;
        margin-left: 26px;
        text-transform: capitalize;
      }
    }
  }
}
.dialog-box-assign > .MuiDialog-container {
  padding-left: 50%;
  h6 {
    color: #414141;
  }
  .assign-to-data {
    margin: 20px 15px;
    .MuiDialog-paperFullScreen {
      border-radius: 5px 0 0 5px;
    }
    .MuiAppBar-colorPrimary {
      background-color: #00458b;
    }
    .MuiToolbar-regular {
      min-height: 50px;
      padding: 0;
    }
    .MuiOutlinedInput-input {
      padding: 12.5px 14px;
      font-size: 12px;
      color: #253858;
      // text-transform: capitalize;
    }
    .MuiInputLabel-outlined {
      top: -5px;
      font-size: 12px;
      color: #253858;
      opacity: 1;
      min-width: auto;
      text-transform: capitalize;
    }
    .MuiSelect-select.MuiSelect-select,
    .MuiSelect-select:focus {
      background-color: #fff;
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      top: 1px;
      font-size: 14px;
      background-color: #fff;
    }
    .MuiSelect-select:focus {
      background-color: transparent;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $blueM;
      border-width: 1px;
    }
    button {
      &.search-btn {
        background-color: #0065ff;
        border: 1px solid #0065ff;
        color: #fff;
        border-radius: 8px;
        min-width: 120px;
        height: 42px;
        line-height: 42px;
        padding: 0;
        text-align: center;
        text-transform: capitalize;
        margin: 14px 0 5px;
        font-size: 12px;
      }
    }
    .border-bottom {
      border-bottom: 1px solid #eaeaea;
      padding: 0 0 30px;
    }
    h2 {
      margin: 40px 0 0;
    }
    ul {
      .MuiFormGroup-root {
        display: block;
      }
      li {
        border: 1px solid #5e6c84;
        border-radius: 8px;
        height: 46px;
        line-height: 56px;
        padding: 0 15px;
        margin: 15px 8px 0 0;
        font-size: 12px;
        width: 46%;
        display: inline-block;
        line-height: 46px;

        .MuiTypography-body1 {
          font-size: 12px;
          line-height: 16px;
          color: #253858;
        }
        .MuiFormControlLabel-root {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
}

.sme-detail > .MuiDialog-container {
  padding-left: 50%;
  h6 {
    color: #414141;
  }
  .MuiDialogTitle-root {
    padding: 9px 0;
    h6 {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .sme-detail-data {
    margin: 20px 5px;
    .MuiDialog-paperFullScreen {
      border-radius: 5px 0 0 5px;
    }
    .MuiDialogContent-root {
      padding: 8px 15px;
    }
    .MuiAppBar-colorPrimary {
      background-color: #00458b;
    }
    .MuiFormControl-marginNormal {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .MuiToolbar-regular {
      min-height: 50px;
      padding: 0;
    }
    .MuiOutlinedInput-input {
      padding: 12.5px 14px;
      font-size: 12px;
      color: #253858;
      // text-transform: capitalize;
    }
    .MuiInputLabel-outlined {
      top: -5px;
      font-size: 12px;
      color: #253858;
      opacity: 1;
      min-width: auto;
      text-transform: capitalize;
    }
    .MuiSelect-select.MuiSelect-select,
    .MuiSelect-select:focus {
      background-color: #fff;
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      top: 1px;
      font-size: 14px;
      background-color: #fff;
    }
    .MuiSelect-select:focus {
      background-color: transparent;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $blueM;
      border-width: 1px;
    }
    .form-control-box {
      width: 100%;
    }
    button {
      &.search-btn {
        background-color: #0065ff;
        border: 1px solid #0065ff;
        color: #fff;
        border-radius: 4px;
        min-width: 60px;
        height: 39px;
        line-height: 42px;
        padding: 0 8px;
        text-align: center;
        text-transform: capitalize;
        margin: 0 8px 5px 0;
        font-size: 12px;
        font-weight: 400;
      }
      &.add-new {
        background-color: #0065ff;
        border: 1px solid #0065ff;
        color: #fff;
        border-radius: 4px;
        min-width: 80px;
        height: 39px;
        line-height: 42px;
        padding: 0;
        text-align: center;
        text-transform: capitalize;
        margin: 0 15px 5px 0;
        font-size: 12px;
        font-weight: 500;
      }
    }
    .border-bottom {
      border-bottom: 1px solid #eaeaea;
      padding: 0 0 30px;
    }
    h2 {
      margin: 40px 0 0;
    }
    ul {
      .MuiFormGroup-root {
        display: block;
      }
      li {
        border: 1px solid #5e6c84;
        border-radius: 8px;
        height: 46px;
        line-height: 56px;
        padding: 0 15px;
        margin: 0px 8px 0 0;
        font-size: 12px;
        width: 46%;
        display: inline-block;
        line-height: 46px;

        .MuiTypography-body1 {
          font-size: 12px;
          line-height: 16px;
          color: #253858;
        }
        .MuiFormControlLabel-root {
          width: 100%;
          margin-right: 0;
        }
      }
    }
    .sme-form-data {
      margin-top: 25px;
      border-top: 1px solid #ccc;
      padding-top: 10px;
    }
    .form-control {
      width: 100%;
      border: 1px solid #dcdcdc;
      border-radius: 4px;
      background: transparent;
      .MuiInput-underline:before,
      .MuiInput-underline:after {
        border: transparent;
      }
      .MuiOutlinedInput-input {
        padding: 14.5px 14px;
      }
      .MuiInput-underline:hover:not(.Mui-disabled):before {
        border: transparent;
      }
      label + .MuiInput-formControl {
        margin-top: 0;
        padding: 4px;
      }
      .MuiInputLabel-shrink {
        top: -8px;
        background: #fbfbfb5c;
        left: 13px;
        z-index: 99;
        font-size: 15px;
      }
      .MuiInputLabel-formControl {
        top: -8px;
        left: 7px;
        font-size: 14px;
        color: #253858;
      }
      .MuiInputBase-input {
        padding: 8px 6px;
        font-size: 15px;
        font-weight: 500;
      }
      .MuiOutlinedInput-input {
        padding: 12.5px 14px;
        font-size: 12px;
        color: #253858;
        // text-transform: capitalize;
      }
      .MuiInputLabel-outlined {
        top: -5px;
        font-size: 12px;
        color: #253858;
        opacity: 1;
        min-width: auto;
        text-transform: capitalize;
      }
      .MuiSelect-select.MuiSelect-select,
      .MuiSelect-select:focus {
        background-color: #fbfbfb5c;
      }
      .MuiInputLabel-outlined.MuiInputLabel-shrink {
        top: 1px;
        font-size: 14px;
        background-color: transparent;
      }
      .MuiSelect-select:focus {
        background-color: transparent;
      }
      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #ccc;
        border-width: 1px;
      }
      .MuiSelect-selectMenu {
        padding: 6px;
        padding-right: 24px;
        font-size: 12px;
        color: #253858;
        font-weight: 400;
      }
    }
  }
  button {
    &.save-btn {
      background-color: #0065ff;
      border: 1px solid #0065ff;
      color: #fff;
      border-radius: 5px;
      min-width: 69px;
      height: 32px;
      line-height: 32px;
      padding: 0;
      text-align: center;
      text-transform: capitalize;
      margin: 15px 0;
      font-size: 12px;
      font-weight: 500;
    }
  }
  .sme-table-list {
    border-top: 1px solid #ccc;
    margin: 25px 0 0;
    .MuiTableCell-head {
      line-height: 0.5rem;
      background: #0065ff;
      color: #fff;
    }
    .MuiRadio-root {
      color: rgba(0, 0, 0, 0.54);
      padding: 0;
      width: 15px;
      margin-right: 7px;
      svg {
        width: 18px;
        height: 18px;
      }
    }
    .MuiRadio-colorSecondary.Mui-checked {
      color: #2196f3;
    }
    .sme-body-list {
      .MuiTableCell-alignLeft {
        padding: 5px 10px;
      }
      .MuiFormGroup-row {
        display: inline-block;
        padding: 0;
      }
      .MuiFormControlLabel-root {
        padding: 0;
        height: 30px;
        margin: 0;
      }
    }

    .MuiTableRow-root {
      padding: 5px 10px;
      &:nth-child(2n + 1) {
        background-color: #ededed;
      }
      .MuiTableCell-root {
        line-height: 16px;
        padding: 5px 10px;
        width: 20%;
        font-size: 12px;
        word-break: break-word;
        &:nth-child(1),
        &:nth-child(3) {
          width: 40%;
        }
      }
    }
    .col-one {
      width: 20px;
    }
    &.add-mannual {
      margin: 0;
      .MuiTableCell-root {
        &:nth-child(3) {
          width: auto;
        }
        &:nth-child(1) {
          width: 15%;
        }
        &:nth-child(2) {
          width: 20%;
        }
      }
    }
  }
}
.mannual-table-list {
  background-color: #fafafa;
  width: 100%;
  margin: 10px 0 0;
  box-shadow: 1px 0px 9px -3px #00000029;
  button {
    margin: 10px 0;
    &.edit-btn {
      color: #0065ff;
      font-size: 12px;
      font-weight: 400;
      text-transform: capitalize;
      &:hover {
        background-color: transparent;
      }
    }
  }
}

// user-detail-dialog
.dialog-user-details > .MuiDialog-container {
  padding-left: 35%;
  h6 {
    color: #414141;
  }
  .MuiToolbar-regular {
    min-height: 36px;
    background-color: #fffefe;
  }
  .form-control {
    width: 100%;
    &.password {
      .MuiInputLabel-formControl {
        top: -7px;
        left: 14px;
        z-index: 99;
      }
      .MuiInputLabel-outlined.MuiInputLabel-shrink {
        top: 1px;
        font-size: 16px;
        color: #00458b;
      }
    }
  }
  .error-msg {
    color: #ff0000;
    font-size: 11px;
  }
  .MuiDialogTitle-root {
    background-color: #fff;
    box-shadow: 0px 2px 4px -1px #0a0a0aa6;
    padding: 8px 21px;
    margin: 0 0 5px;
    h2 {
      font-size: 14px;
      font-weight: 600;
    }
  }
  .MuiOutlinedInput-input {
    padding: 14px 14px;
    font-size: 12px;
    color: #253858;
    // text-transform: capitalize;
  }
  .MuiInputLabel-formControl {
    top: -7px;
    font-size: 14px;
    color: #00458b;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    top: 1px;
    font-size: 16px;
  }
  .MuiSelect-outlined.MuiSelect-outlined {
    color: #00458b;
    font-size: 12px;
    font-weight: 400;
    padding: 13px;
  }
  button {
    &.add-btn {
      border: 1px solid #0065ff;
      color: #0065ff;
      font-size: 14px;
      text-decoration: none;
      text-transform: capitalize;
      border-radius: 5px;
      height: 34px;
      line-height: 30px;
      margin: 10px 10px 10px 0;
    }
    &.reset-btn {
      border: 1px solid #97979785;
      background-color: #2196f3;
      color: #fff;
      font-size: 14px;
      text-decoration: none;
      text-transform: capitalize;
      border-radius: 5px;
      height: 34px;
      line-height: 30px;
      margin-right: 10px;
    }
    &.cancel-btn {
      border: 1px solid #97979785;
      color: #808080;
      font-size: 14px;
      text-decoration: none;
      text-transform: capitalize;
      border-radius: 5px;
      height: 34px;
      line-height: 30px;
      margin-right: 10px;
    }
  }
  .add-btn-box {
    margin: 20px 0;
  }
  .user-edit-add-list {
    background-color: #f2f7ff;
    ul {
      padding: 0 10px;
      li {
        padding: 12px 5px;
        margin: 0;
        font-size: 12px;
        display: inline-block;
        word-break: break-word;
        width: 30%;
        span {
          font-size: 12px;
          font-weight: 500;
        }
        &:nth-child(3) {
          width: auto;
        }
        strong {
          display: block;
          font-size: 12px;
          font-weight: 400;
          color: #253858;
        }
      }
    }
    .user-edit-add-list-head {
      background-color: #408bff;
      border-radius: 8px 8px 0 0;
      ul {
        li {
          span {
            color: #fff;
          }
        }
      }
    }
    .user-edit-add-list-body {
      margin: 0 0 20px;
      ul {
        background-color: #ffffff;
        border-radius: 0 0 8px 8px;
        margin-top: 5px;
        box-shadow: 6px 7px 5px -3px #00000029;
        li {
          padding: 0 5px;
          span {
            &.heading {
              display: none;
            }
            .MuiSvgIcon-root {
              width: 15px;
            }
          }
          strong {
            span {
              display: block;
            }
          }
        }
      }
    }
  }
}
.dialog-user-details {
  .add-edit-box {
    margin: 20px 0 0;
  }
}
// user-details
.user-details-view {
  margin: 20px 25px;
  h3 {
    margin: 0 0 10px;
    border-bottom: 1px solid #253858;
    padding: 0 0 10px;
  }
  .form-control {
    width: 100%;
  }

  .MuiDialog-paperFullScreen {
    border-radius: 5px 0 0 5px;
  }
  .MuiAppBar-colorPrimary {
    background-color: #00458b;
  }
  .MuiToolbar-regular {
    min-height: 50px;
    padding: 0;
  }
  .MuiOutlinedInput-input {
    padding: 12.5px 14px;
    font-size: 12px;
    color: #253858;
    // text-transform: capitalize;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }
  .MuiInputLabel-outlined {
    top: -5px;
    font-size: 12px;
    color: #253858;
    opacity: 1;
    min-width: auto;
    text-transform: capitalize;
  }
  .MuiSelect-select.MuiSelect-select,
  .MuiSelect-select:focus {
    background-color: #fff;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    top: 1px;
    font-size: 14px;
    background-color: #fff;
  }
  .MuiSelect-select:focus {
    background-color: transparent;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $blueM;
    border-width: 1px;
  }
  button {
    &.search-btn {
      background-color: #0065ff;
      border: 1px solid #0065ff;
      color: #fff;
      border-radius: 8px;
      min-width: 120px;
      height: 42px;
      line-height: 42px;
      padding: 0;
      text-align: center;
      text-transform: capitalize;
      margin: 14px 0 5px;
      font-size: 12px;
    }
  }
  .border-bottom {
    border-bottom: 1px solid #eaeaea;
    padding: 0 0 30px;
  }
  h2 {
    margin: 40px 0 0;
  }
  ul {
    .MuiFormGroup-root {
      display: block;
    }
    li {
      // border: 1px solid #5e6c84;
      padding: 0 5px;
      margin: 15px 8px 0 0;
      font-size: 12px;
      width: 11%;
      display: inline-block;
      .MuiTypography-body1 {
        font-size: 12px;
        line-height: 16px;
        color: #253858;
      }
      .MuiFormControlLabel-root {
        width: 100%;
        margin-right: 0;
      }
    }
  }
  .user-details-list {
    margin: 20px 0 10px;
    ul {
      padding: 0 8px;
      li {
        padding: 12px 5px;
        margin: 0;
        font-size: 12px;
        width: 13.5%;
        display: inline-block;
        word-break: break-word;
        &.edit {
          width: 5%;
        }
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7) {
          width: 8%;
        }
        &:nth-child(4) {
          width: 20%;
        }
        &:nth-child(1) {
          width: 5%;
        }
        &:nth-child(2) {
          width: 11%;
        }
        // &:nth-child(3) {
        //   width: 20%;
        // }
        span {
          font-size: 12px;
          font-weight: 500;
        }
        strong {
          display: block;
          font-size: 12px;
          font-weight: 400;
          color: #253858;
          i {
            color: #0065ff;
          }
        }
        button {
          cursor: pointer;
          min-width: auto;
          padding: 0;
          &.edit-btn {
            span {
              display: block;
            }
          }
        }
      }
    }
    .user-details-list-head {
      background-color: #eeebeb;
      border-radius: 8px 8px 0 0;
    }
    .user-details-list-body {
      ul {
        background-color: #f4f3f385;
        border-radius: 0 0 8px 8px;
        margin-top: 5px;
        box-shadow: 6px 7px 5px -3px #00000029;
        &:nth-child(1) {
          border-radius: 0 0 8px 8px;
        }
        li {
          vertical-align: middle;
          span {
            display: none;
          }
        }
      }
    }
  }
  button {
    &.add-btn {
      border: 1px solid #0065ff;
      color: #0065ff;
      font-size: 14px;
      text-decoration: none;
      text-transform: capitalize;
      border-radius: 5px;
      height: 34px;
      line-height: 30px;
      margin-right: 10px;
    }
    &.reset-btn {
      border: 1px solid #97979785;
      background-color: #2196f3;
      color: #fff;
      font-size: 14px;
      text-decoration: none;
      text-transform: capitalize;
      border-radius: 5px;
      height: 34px;
      line-height: 30px;
      margin-right: 10px;
    }
  }
}

// communication-dailog
.communication-dailog {
  margin: 25px 0 10px;
  padding: 20px 20px 15px;
  box-shadow: 0px 2px 8px 4px #6767675c;
  border-radius: 1px;
  .MuiDialog-paperWidthMd {
    width: 650px;
    max-width: 100%;
    padding: 10px 15px 15px 5px;
    border-radius: 5px;
  }
  h6 {
    margin-bottom: 20px;
  }
  .MuiDialogTitle-root {
    padding: 0 24px;
  }
  .form-control {
    width: 100%;
    .MuiFormControl-marginNormal {
      margin-top: 0;
    }
    .MuiInputLabel-animated {
      top: -3px;
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      top: 0;
      // background-color: #fff;
      padding: 0 4px;
      color: #0065ff;
    }
    .MuiOutlinedInput-input {
      padding: 14.5px 14px;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: #0065ff;
    }
    .user-comments {
      padding: 15px;
      min-height: 70px;
      border: 1px solid #d1d1d1;
      border-radius: 4px;
      margin-bottom: 10px;
      resize: none;
      font-family: "Roboto", sans-serif;
      &:focus {
        border: 1px solid #1977d5;
      }
    }
  }
  button {
    &.save-btn {
      border: 1px solid #0065ff;
      color: #0065ff;
      font-size: 14px;
      text-decoration: none;
      text-transform: capitalize;
      border-radius: 5px;
      height: 34px;
      line-height: 30px;
      margin-left: 10px;
    }
    &.cancle-btn {
      border: 1px solid #97979785;
      color: #253858;
      font-size: 14px;
      text-decoration: none;
      text-transform: capitalize;
      border-radius: 5px;
      height: 34px;
      line-height: 30px;
      margin-right: 10px;
    }
    
  }
}

// communication-dailog-new
.communication-dailog-new{
  .MuiDialog-paperWidthMd{
    width: 507px;
    max-width: 100%;
    padding: 10px;
    border-radius: 5px;
  }
  .form-control{
    .MuiFormControl-marginNormal{
      margin-top:5px;
    }
    .MuiOutlinedInput-input{
      padding: 12.5px 14px;
      // width: 87%;
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink{
      font-size: 13px;
      top: 2px;
      background: #fff;
      padding: 0;
      width: auto;
    }
    input:-internal-autofill-selected{
      background-color: #fff;
    }
  }
  .MuiDialogTitle-root{
    padding: 0 15px;
    h6{
      margin-bottom: 10px;
    }
  }
//   .MuiSelect-select.MuiSelect-select, .reply-popup .MuiSelect-select:focus {
//     background-color: #fff;
//     width: 83%;
// }
ul{
  &.list{
    width: 100%;
    .form-control-new{
      border:1px solid #0052cc;
      border-radius: 5px;
      margin-bottom: 15px;
      .MuiInput-underline:before,.MuiInput-underline:after{
        display: none;
      }
      .MuiInputLabel-animated {
        top: -12px;
        left: 9px;
        font-size: 14px;
        font-weight: 500;
      }
      .MuiInputLabel-shrink{
        top: -7px;
        left: 12px;
        background: #fff;
        font-size: 14px;
      }
      label + .MuiInput-formControl{
        margin: 2px;
      }
      .MuiSelect-selectMenu{
        padding: 8px 12px;
        font-size: 14px;
      }
    }
  }
}
hr{
  margin: 10px;;
}
button{
  &.delete-btn{
    color: #808080;
    font-size: 11px;
    text-transform: capitalize;
    background: #dedede;
    font-weight: 400;
  }
  &.add-btn{
    background-color: #0065ff;
    margin: 0 10px;
    height: 34px;
    font-size: 12px;
    color: #fff;
  }
}
  
}

// .user-details-view
.user-details-view {
  margin: 0;
  background: #fff;
  padding: 23px;
   h3 {
    margin: 0 0 10px;
    border-bottom: 1px solid #253858;
    padding: 0 0 10px;
    font-size: 20px;
  }
  .form-control {
    width: 100%;
  }

  .MuiDialog-paperFullScreen {
    border-radius: 5px 0 0 5px;
  }
  .MuiAppBar-colorPrimary {
    background-color: #00458b;
  }
  .MuiToolbar-regular {
    min-height: 50px;
    padding: 0;
  }
  .MuiOutlinedInput-input {
    padding: 12.5px 14px;
    font-size: 12px;
    color: #253858;
    // text-transform: capitalize;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }
  .MuiInputLabel-outlined {
    top: -5px;
    font-size: 12px;
    color: #253858;
    opacity: 1;
    min-width: auto;
    text-transform: capitalize;
  }
  .MuiSelect-select.MuiSelect-select,
  .MuiSelect-select:focus {
    background-color: #fff;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    top: 1px;
    font-size: 14px;
    background-color: #fff;
  }
  .MuiSelect-select:focus {
    background-color: transparent;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $blueM;
    border-width: 1px;
  }
  button {
    &.search-btn {
      background-color: #0065ff;
      border: 1px solid #0065ff;
      color: #fff;
      border-radius: 8px;
      min-width: 120px;
      height: 42px;
      line-height: 42px;
      padding: 0;
      text-align: center;
      text-transform: capitalize;
      margin: 14px 0 5px;
      font-size: 12px;
    }
  }
  .border-bottom {
    border-bottom: 1px solid #eaeaea;
    padding: 0 0 30px;
  }
  h2 {
    margin: 40px 0 0;
  }
  ul {
    .MuiFormGroup-root {
      display: block;
    }
    li {
      // border: 1px solid #5e6c84;
      padding: 0 5px;
      margin: 15px 8px 0 0;
      font-size: 12px;
      width: 11%;
      display: inline-block;
      .MuiTypography-body1 {
        font-size: 12px;
        line-height: 16px;
        color: #253858;
      }
      .MuiFormControlLabel-root {
        width: 100%;
        margin-right: 0;
      }
    }
  }
  .user-details-list {
    margin: 20px 0 10px;
    height: calc(63vh - -20px);
    overflow-y: scroll;
    ul {
      padding: 0 8px;
      li {
        padding: 12px 5px;
        margin: 0;
        font-size: 12px;
        width: 13.5%;
        display: inline-block;
        word-break: break-word;
        &.edit {
          width: 5%;
        }
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7) {
          width: 8%;
        }
        &:nth-child(1) {
          width: 5%;
        }
        &:nth-child(2) {
          width: 11%;
        }
        span {
          font-size: 12px;
          font-weight: 500;
        }
        strong {
          display: block;
          font-size: 12px;
          font-weight: 400;
          color: #253858;
          i {
            color: #0065ff;
          }
        }
        button {
          cursor: pointer;
          min-width: auto;
          padding: 0;
          &.edit-btn {
            span {
              display: block;
            }
          }
        }
      }
    }
    .user-details-list-head {
      background-color: #408bff;
      border-radius: 8px 8px 0 0;
      ul {
        li {
          span {
            color: #fff;
          }
        }
      }
    }
    .user-details-list-body {
      ul {
        background-color: #f4f3f385;
        border-radius: 0 0 8px 8px;
        margin-top: 5px;
        box-shadow: 6px 7px 5px -3px #00000029;
        &:nth-child(1) {
          border-radius: 0 0 8px 8px;
        }
        li {
          span {
            display: none;
          }
        }
      }
    }
  }
}
