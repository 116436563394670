.dialog-box-comments>.MuiDialog-container {
  padding-left: 75%;

  .MuiDialog-paperFullScreen {
    border-radius: 5px 0 0 5px;
  }

  .MuiAppBar-colorPrimary {
    background-color: #00458b;
  }

  .MuiToolbar-regular {
    min-height: 50px;
    padding: 0;
  }
}

.dialog-create-ticket>.MuiDialog-container {
  padding-left: 70%;

  .MuiDialog-paperFullScreen {
    border-radius: 5px 0 0 5px;
  }

  .MuiToolbar-regular {
    min-height: 50px;
    padding: 0;
  }

  .sourceticket {
    color: #f1f3f4;
    line-height: 14px;
    padding-bottom: 9px;
    font-size: 11px;
  }
}

.dialog-create-endorsement>.MuiDialog-container {
  padding-left: 20%;

  .MuiDialog-paperFullScreen {
    border-radius: 5px 0 0 5px;
  }

  .MuiToolbar-regular {
    min-height: 50px;
    padding: 0;
  }
}

.dialog-show-details>.MuiDialog-container {
  padding-left: 20%;

  .MuiDialog-paperFullScreen {
    border-radius: 5px 0 0 5px;
  }

  .MuiToolbar-regular {
    min-height: 50px;
    padding: 0;
  }
}

.all-comments {
  padding: 10px 5px;
  width: 95%;

  button {
    &.close-btn {
      position: absolute;
      min-width: 29px;
      padding: 0;
      font-size: 16px;
      // background: #f7f6f6;
      border-radius: 9px;
      left: 6px;
      top: 13px;
    }

    &:hover {
      background-color: transparent;
    }
  }

  h6 {
    color: #253858;
    font-size: 18px;
    padding: 3px 20px;
  }

  button {
    &.add-comment-btn {
      position: absolute;
      right: 2%;
      top: 6%;
      color: #0065ff;
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;
      line-height: 16px;

      img {
        color: #2179ff;
        margin: 0 5px;
      }
    }
  }

  .all-comment-view {
    background: #cfcfcf36;
    padding: 10px 7px;
    border-radius: 5px;
    // box-shadow: 5px 6px 6px #4c4c4c29;
    width: 95%;
    margin: 0 auto 10px;

    >ul {
      padding: 0;
      margin: 5px 8px 8px !important;
      white-space: inherit;

      p {
        font-size: 12px;
        font-weight: 400;
        color: #253858;
        line-height: 19px;
        padding: 0 78px 0 0;
        word-break: break-word;
        white-space: break-spaces;
      }

      span {
        white-space: initial;
      }
    }
  }

  .all-insurer-view {
    padding: 10px 7px 20px;
    border-radius: 5px;
    // box-shadow: 5px 6px 6px #4c4c4c29;
    width: 95%;
    margin: 0 auto;
    background-color: #607d8b1f;
    overflow-x: scroll;
    height: 300px;

    >ul {
      padding: 0;
      margin: 0 0 25px;
    }

    p {
      font-size: 12px;
      font-weight: 400;
      color: #253858;
      line-height: 19px;
      padding: 0 78px 0 0;
      word-break: break-word;
      white-space: break-spaces;
    }

    span {
      white-space: initial;
    }
  }

  ul {
    &.comments {
      padding: 2px 15px 10px 35px;
      max-height: 140px;
      min-height: 30px;
      overflow-y: auto;
      overflow-x: hidden;

      li {
        position: relative;
        list-style: none;
        width: 100%;
        display: block;
        min-height: 62px;
        vertical-align: top;
        padding: 0 5px;

        .timeline {
          border-left: 2px solid #808080;
          position: absolute;
          top: 29px;
          left: -21px;
          height: 55%;
        }
      }

      .all-insurer-view {
        padding: 10px 7px 20px;
        border-radius: 5px;
        // box-shadow: 5px 6px 6px #4c4c4c29;
        width: 95%;
        margin: 0 auto;
        background-color: #607d8b1f;
        overflow-x: scroll;
        height: 274px;

        >ul {
          padding: 0;
          margin: 0 0 25px;
        }
      }

      ul {
        &.comments {
          padding: 2px 15px 10px 35px;
          max-height: 140px;
          min-height: 30px;
          overflow-y: auto;
          overflow-x: hidden;

          li {
            position: relative;
            list-style: none;
            width: 100%;
            display: block;
            min-height: 62px;
            vertical-align: top;
            padding: 0 5px;

            .timeline {
              border-left: 2px solid #808080;
              position: absolute;
              top: 29px;
              left: -21px;
              height: 55%;
            }

            .comment-txt {
              word-break: break-all;
              white-space: break-spaces;

              p {
                font-size: 12px;
                font-weight: 400;
                color: #253858;
                line-height: 19px;
                padding: 0 78px 0 0;
                word-break: break-word;
                white-space: break-spaces;
              }

              strong {
                font-size: 11px;
                font-weight: 400;
                color: #253858;
                line-height: 14px;
                padding: 0 15px 0 0;
                display: block;
              }
            }

            span {
              font-size: 11px;
              font-weight: 400;
              color: #253858;
              line-height: 14px;
              white-space: initial;

              &.comment-time {
                position: absolute;
                right: -6px;
                top: 2px;
                font-size: 10px;
                color: #253858;
              }
            }

            .short-name {
              height: 10px;
              width: 10px;
              border-radius: 50%;
              position: absolute;
              left: -34px;
              top: -3px;
              width: 32px;
              height: 32px;
              line-height: 32px;
              text-align: center;
              font-size: 12px;
              text-transform: uppercase;
              color: #253858;

              &.comm-one {
                background-color: #2f8efd4d;
              }

              &.comm-two {
                background-color: #ffe9cb;
              }

              &.comm-three {
                background-color: #c97ffa;
              }

              &.comm-four {
                background-color: #e59866;
              }

              &.short-name:nth-child(2n) {
                background-color: #afffce;
              }
            }

            button {
              min-width: auto;
              padding: 0;
              position: absolute;

              &.reply-btn {
                span {
                  color: #2179ff;
                  font-size: 12px;
                  font-weight: 500;
                  text-transform: capitalize;
                  line-height: 16px;
                }
              }
            }

            ul {
              padding: 12px 26px;
              margin: 23px 0 0;

              li {
                min-height: 70px;
                padding: 0;
              }
            }

            .upload-item {
              border: 1px solid #eaeaea;
              position: relative;
              padding: 5px 0px;
              width: 125px;
              display: inline-block;
              border-radius: 8px;
              min-height: 32px;
              vertical-align: middle;
              margin: 2px 6px 5px 0;

              // box-shadow: 0px 0px 3px 1px #3469cb29;
              .file-extn {
                position: absolute;
                left: 10px;
                top: 6px;

                img {
                  width: 20px;
                  margin: 3px 0 0;
                }
              }

              p {
                padding: 3px 2px 0 34px;
                color: #303030;
                font-size: 10px;
                font-weight: 400;
                line-height: 14px;
                white-space: break-spaces;

                // overflow: hidden;
                // white-space: nowrap;
                // text-overflow: ellipsis;
                span {
                  &.doc-type {
                    font-size: 12px;
                    color: $lightG;
                    display: block;
                    font-weight: 400;
                  }
                }
              }
            }
          }
        }
      }

      .comment-reply-txtarea {
        margin: 20px 0 10px;

        textarea {
          color: #808080;
          border: 1px solid #99d6e8;
          width: 90%;
          display: block;
          margin: 0 auto;
          border-radius: 4px;
          font-size: 12px;
          font-weight: 400;
          color: #253858;
          line-height: 19px;
          padding: 0 78px 0 0;
          word-break: break-word;
          white-space: break-spaces;
        }

        strong {
          font-size: 11px;
          font-weight: 400;
          color: #253858;
          line-height: 14px;
          padding: 0 15px 0 0;
          display: block;
        }
      }

      span {
        font-size: 11px;
        font-weight: 400;
        color: #253858;
        line-height: 14px;
        word-break: break-all;
        white-space: initial;

        &.comment-time {
          position: absolute;
          right: 10px;
          top: -6px;
          font-size: 10px;
          color: #253858;
        }

        &.attachemt-link {
          cursor: pointer;
        }
      }

      .short-name {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        position: absolute;
        left: -34px;
        top: -3px;
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        font-size: 12px;
        text-transform: uppercase;
        color: #253858;

        &.comm-one {
          background-color: #2f8efd4d;
        }

        &.comm-two {
          background-color: #ffe9cb;
        }

        &.comm-three {
          background-color: #c97ffa;
        }

        &.comm-four {
          background-color: #e59866;
        }

        &.short-name:nth-child(2n) {
          background-color: #afffce;
        }
      }

      button {
        min-width: auto;
        padding: 0;
        position: absolute;

        &.reply-btn {
          span {
            color: #2179ff;
            font-size: 12px;
            font-weight: 500;
            text-transform: capitalize;
            line-height: 16px;
          }
        }
      }

      ul {
        padding: 12px 26px;
        margin: 23px 0 0;

        li {
          min-height: 70px;
          padding: 0;
        }
      }

      a {
        color: #414141;
        text-decoration: none;
      }

      .upload-item {
        border: 1px solid #eaeaea;
        position: relative;
        padding: 5px 0px;
        width: 125px;
        display: block;
        border-radius: 8px;
        min-height: 32px;
        vertical-align: middle;
        margin: 2px 6px 5px 0;
        box-shadow: 0px 0px 3px 1px #3469cb29;

        .file-extn {
          position: absolute;
          left: 10px;
          top: 6px;

          img {
            width: 20px;
            margin: 3px 0 0;
          }
        }

        p {
          padding: 3px 2px 0 34px;
          color: #303030;
          font-size: 10px;
          font-weight: 400;
          line-height: 24px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          span {
            &.doc-type {
              font-size: 12px;
              color: $lightG;
              display: block;
              font-weight: 400;
            }
          }
        }
      }
    }

    &.only-comments-list {
      min-height: 480px;
      padding-bottom: 20px;
    }
  }

  &.matrix-ticket {
    background-color: #fff;
    width: 97%;
    padding: 0 5px;
    height: 490px;

    // overflow-y: scroll;
    .create-new-ticket {
      padding: 20px;
      padding: 20px 10px;
      height: 458px;
      overflow-y: scroll;

      button {
        &.submit-btn {
          background-color: #408BFF;
          color: #fff;
          font-size: 14px;
          min-width: 99%;
          margin: 0 auto;
          display: block;
          line-height: 20px;
          height: 39px;
          margin: 0;
        }

        &.fetch-details-btn {
          color: #408bff;
          border: 1px solid #408bff;
          border-radius: 4px;
          margin: 0;
          padding: 0;
        }
      }

      .uplaod-doc-view {
        display: inline-block;
      }

      .MuiFormControl-marginNormal {
        margin-top: 0;
      }
    }
  }
}

.comment-reply-txtarea {
  margin: 20px 0 10px;

  textarea {
    color: #808080;
    border: 1px solid #99d6e8;
    width: 90%;
    display: block;
    margin: 0 auto;
    border-radius: 4px;
    font-size: 12px;
    min-height: 40px;
    padding: 15px 8px;
    resize: none;
  }
}

.select-box {
  position: relative;
  width: 96%;
  margin: 20px 5px 10px;

  .MuiOutlinedInput-root {
    height: 42px;
    font-size: 14px;
  }

  .MuiInputLabel-formControl {
    top: -5px;
    font-size: 12px;
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    top: 1px;
    color: $blueM;
    font-size: 14px;
  }

  .MuiSelect-outlined.MuiSelect-outlined {
    color: $blueM;
    font-size: 12px;
    font-weight: 400;
  }

  .MuiSelect-select:focus {
    background-color: transparent;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $blueM;
    border-width: 1px;
  }
}

h3 {
  width: auto;
  color: #253858;
  border-bottom: 2px solid #607d8b29;
  padding: 0 0 4px;
  position: relative;
  margin: 0 0 15px -30px;
  font-size: 14px;
  font-weight: 600;
  // &::before{
  // 	content: "_";
  // 	position: absolute;
  // 	right: -15px;
  // 	top: -4px;
  // }
}

// create-new-ticket
.create-new-ticket {
  padding: 20px 15px;

  h6 {
    color: 253858;
  }

  .source-box {
    border: 1px solid #bdb9b9;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
    margin: 0 0 22px;

    &:hover {
      border: 1px solid #000;
    }
  }

  .select-box {
    margin: 0 0 10px;

    .souce-name {
      position: relative;
      border: 1px solid #408bff;
      padding: 12px 11px;
      border-radius: 4px;
      color: #408bff;

      label {
        background-color: #fff;
        top: -7px;
        left: 11px;
        color: #408bff;
        position: absolute;
        font-size: 11px;
      }
    }
  }

  .MuiFormControl-marginNormal {
    margin-top: 10px;
  }

  .MuiOutlinedInput-input {
    padding: 12.5px 14px;
    font-size: 12px;
    color: #253858;
    // text-transform: capitalize;
  }

  .MuiInputLabel-outlined {
    top: -5px;
    font-size: 12px;
    color: #253858;
    opacity: 1;
    min-width: auto;
    text-transform: capitalize;
  }

  .MuiSelect-select.MuiSelect-select,
  .MuiSelect-select:focus {
    background-color: #fff;
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    top: 1px;
    font-size: 14px;
    background-color: #fff;
  }

  .MuiSelect-select:focus {
    background-color: transparent;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $blueM;
    border-width: 1px;
  }

  button {
    border-radius: 8px;
    min-width: 120px;
    text-align: center;
    font-size: 12px;
    height: 36px;
    margin: 10px 8px;
    text-transform: capitalize;

    &.fetch-details-btn {
      color: #408bff;
      border: 1px solid #408bff;
      margin: 10px 0;
    }

    &.remove-doc-btn {
      color: #eb3b32;
      border: 1px solid #eb3b32;
      min-width: 90px;
      margin: 10px 3px;
      padding: 5px 0;
    }

    &.submit-btn {
      background-color: #408bff;
      color: #fff;
      font-size: 14px;
      text-align: center;
      min-width: 95%;
      margin: 0 auto;
      display: block;
    }
  }

  textarea {
    min-width: 94%;
    margin: 0;
    display: block;
    resize: none;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    color: #808080;
    min-height: 60px;
    padding: 15px 10px;
  }

  .uplaod-doc-view {
    position: relative;
    padding: 10px 10px;

    .MuiLinearProgress-barColorPrimary {
      background-color: #4ace45;
      border-radius: 8px;
    }

    span {
      font-size: 12px;
      color: #253858;
      line-height: 20px;
    }

    .file-detail {
      display: inline-block;
      position: relative;
      font-size: 11px;
      width: 46%;
      height: 30px;
      padding: 0 5px 0 0;
      vertical-align: middle;

      button {
        font-size: 11px;
        min-width: 14px;
        background-color: #808080;
        height: 14px;
        color: #fff;
        position: relative;
        right: 0px;
        top: 0;
        padding: 0;
        text-align: center;
        display: inline-block;

        svg {
          vertical-align: middle;
          font-size: 11px;
        }
      }
    }
  }

  .spinner-loader {
    position: relative;
    left: 48%;
    right: 0;
    margin: 27px auto 0;
    width: 100%;
    top: 0;

    svg {
      color: $blueM;
    }
  }

  .spinner-btn {
    svg {
      color: #fff;
    }
  }
}

.comment-submit-data {
  display: table;
  margin: 2px 8px 5px !important;
  width: 95%;
  border: 1px solid #99d6e8;
  border-radius: 5px;

  li {
    display: table-cell;
    vertical-align: middle;
    width: 30%;
    padding: 0;

    &:nth-child(1) {
      width: 70%;
    }

    textarea {
      min-height: 10px;
      resize: none;
      border: transparent;
      padding: 10px;
    }

    &.attach-submit {
      text-align: right;
      background-color: #fff;

      i {
        font-size: 16px;
        margin: 0px;
        transform: rotate(-90deg);
        vertical-align: middle;
      }

      button {
        color: #0065ff;
        min-width: 60px;
        font-size: 10px;
        padding: 0 5px 0 0;
        background-color: transparent;
      }
    }

    .txtarea {
      color: #253858;
      width: 98%;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-size: 12px;

      &:focus-visible {
        outline: none;
      }
    }
  }

  .MuiSelect-select {
    background-color: #fff;
    padding: 8px 9px;
    color: #808080;
    font-size: 12px;
  }

  .MuiSelect-select:focus {
    background-color: transparent;
    padding: 8px 9px;
    color: #414141;
    font-size: 12px;
  }

  .MuiInput-underline:after {
    -webkit-transform: none;
    border-bottom: 2px solid #eceff1;
  }
}

.insurer-comments {
  p {
    font-size: 12px;
    font-weight: 400;
    color: #253858;
    line-height: 19px;
  }

  span {
    font-size: 12px;
    font-weight: 400;
    color: #253858;
    line-height: 16px;

    &.inner-commnet {
      display: block;
      font-size: 12px;
      font-weight: 400;
      color: #253858;
      line-height: 16px;
    }

    &.comment-time {
      font-size: 10px;
      color: #253858;
    }
  }
}

.logs-comments {
  position: relative;

  .view-comments {
    position: absolute;
    right: 9px;
    top: -31px;
    width: 131px;
    display: block;

    button {
      font-size: 12px;
      cursor: pointer;
      padding: 0 5px 0 0;
      border: transparent;
      text-transform: capitalize;

      &:hover {
        background-color: transparent;
      }

      &.comment-btn {
        color: $whiteM;
        font-size: 12px;
        cursor: pointer;
        padding: 0 5px 0 10px;
        border: transparent;
        text-transform: capitalize;
        top: 34%;
        height: 45px;
        line-height: 40px;
        background: #0065ffb5;
        border-radius: 18px 0 0 18px;
        box-shadow: 0px 0px 16px #00000014;
        width: 163px;
        transition: left 0.8s ease-in-out;
        -o-transition: left 0.8s ease-in-out;
        -ms-transition: left 0.8s ease-in-out;
        -moz-transition: left 0.8s ease-in-out;
        -webkit-transition: left 0.8s ease-in-out;
        position: fixed;
        right: -120px;
        z-index: 88;

        .comment-icon {
          margin: 0 15px 0 0;
        }

        &:hover {
          background: #0065ff;
          right: 0;

          .comment-icon {
            margin: 0 5px 0 0;
          }
        }
      }

      i {
        color: #0065ff;
        font-size: 16px;
        padding: 0 0 0 5px;
      }

      span {
        font-size: 12px;
        line-height: 22px;
        font-weight: 600;
      }
    }
  }
}

// name-file
.name-file {
  position: relative;

  button {
    position: relative;
    top: 2px;

    svg {
      font-size: 12px;
    }
  }
}