$whiteM: #ffffff;
$blackB: #253858;
$blackM: #303030;
$blueM: #0065ff;

.all-ticket-list {
  .escalation-loader {
    position: relative;
    .spinner-loader {
      position: relative;
      left: 48%;
      right: 0;
      margin: 27px auto 0;
      width: 100%;
      top: 0;
      svg {
        color: $blueM;
      }
    }
  }
  .esclation-box {
    margin: 30px 12px 0;
    padding: 8px 0 0;
    background: $whiteM;
    border-radius: 8px;
    border: 1px solid #eeeeee;
    width: 98%;
    h4 {
      font-size: 12px;
      line-height: 16px;
      color: $blackB;
      margin: 5px 0 0;
      font-family: "robotobold";
    }
    .srs-timeline {
      text-align: center;
      position: relative;
      span {
        position: absolute;
        left: -20px;
        top: -3px;
        font-size: 12px;
        font-weight: 900;
      }
      em {
        font-size: 12px;
        font-style: normal;
        display: inline-block;
        margin: 15px 0 0;
        min-width: 100px;
        background-color: #ededed;
        color: #253858;
        height: 30px;
        line-height: 30px;
        border-radius: 4px;
        position: relative;
        &:before {
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 13px solid #ededed;
          font-size: 0;
          line-height: 0;
          top: -10px;
          position: absolute;
          right: 0;
          left: 0;
          margin: auto;
          box-shadow: none;
          content: "";
        }
      }
    }
    .MuiLinearProgress-root {
      height: 6px;
    }
    .MuiLinearProgress-colorPrimary {
      border-radius: 8px;
    }
    .level-one {
      margin: 5px 0 0 10px;
      width: 90%;
      .MuiLinearProgress-barColorPrimary {
        background-color: #4ace45;
        border-radius: 8px;
      }
      em {
        &.active {
          border-bottom: 13px solid #fbebdf;
          border: 1px dashed #a86100;
          background-color: #fbebdf;
          color: #a86100;
          &::before {
            border-bottom: 13px solid #fbebdf;
          }
        }
      }
    }
    .level-two {
      margin: 5px 0 0 10px;
      width: 90%;
      .MuiLinearProgress-barColorPrimary {
        background-color: #ffcc6a;
        border-radius: 8px;
      }
      em {
        &.active {
          border-bottom: 13px solid #fbebdf;
          border: 1px dashed #a86100;
          background-color: #fbebdf;
          color: #a86100;
          &::before {
            border-bottom: 13px solid #fbebdf;
          }
        }
      }
    }
    .level-three {
      margin: 5px 0 0 10px;
      width: 90%;
      .MuiLinearProgress-barColorPrimary {
        background-color: #eb3b32;
        border-radius: 8px;
      }
      em {
        &.active {
          border-bottom: 13px solid #fbebdf;
          border: 1px dashed #a86100;
          background-color: #fbebdf;
          color: #a86100;
          &::before {
            border-bottom: 13px solid #fbebdf;
          }
        }
      }
    }
    .MuiGrid-container {
      padding: 0 15px 5px;
    }
  }
  .esclation-resoution-timer {
    margin: 0 auto;
    background-color: #ebeff9;
    padding: 5px 0;
    border-radius: 0px 0px 8px 8px;
    width: 828px;
    min-height: 26px;
    max-width: 95.5%;
    margin: 0 auto;
    ul {
      text-align: left;
      padding: 0 15px;
      position: relative;
      li {
        width: 48%;
        margin: 0px;
        display: inline-block;
        position: relative;
        padding: 0;
        p {
          font-size: 11px;
          color: #0065ff;
          font-weight: 700;
          line-height: 28px;
          text-transform: uppercase;
          font-family: "robotobold";
          span {
            font-size: 10px;
            color: #253858;
            font-weight: 600;
            padding: 0 8px;
          }
        }
        &:nth-child(1) {
          &::after {
            content: "";
            position: absolute;
            background-image: url("/images/arrow_icon.svg");
            height: 24px;
            width: 29px;
            background-repeat: no-repeat;
            background-position-x: 100%;
            background-position-y: 0;
            right: 20px;
            top: 3px;
          }
        }
      }
      button {
        &.assign {
          color: #0065ff;
          position: absolute;
          right: 0;
          font-size: 12px;
          font-weight: 700;
          line-height: 16px;
          text-transform: capitalize;
          top: 0;
          right: 5px;
          &:hover {
            background-color: transparent;
          }
          &:after {
            content: "\f105";
            right: 0;
            top: 0;
            font-family: FontAwesome;
            text-decoration: inherit;
            font-style: normal;
            font-weight: normal;
            color: #0065ff;
            padding: 0 5px;
            font-size: 15px;
          }
        }
      }
    }
  }
}

.gradient-border {
  --borderWidth: 2px;
  position: relative;
  border-radius: var(--borderWidth);
  width: 89px;
  &:after {
    content: "";
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: linear-gradient(
      60deg,
      #f79533,
      #f37055,
      #ef4e7b,
      #ff0000,
      #f37055,
      #ff0000,
      #f79533,
      #ef4e7b
    );
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.assign-confirm-box {
  .MuiDialog-paperWidthSm {
    width: 500px;
    max-width: 100%;
    button {
      text-transform: capitalize;
      min-width: 50px;
      margin: 0 0 0 10px;
      height: 30px;
      border-radius: 5px;
      &.yes-btn {
        color: #0065ff;
        border: 1px solid #0065ff;
        font-size: 14px;
      }
      &.no-btn {
        color: #808080;
        border: 1px solid #808080;
        font-size: 14px;
      }
    }
  }
}
