$whiteM: #ffffff;
$blackB: #253858;
$blackM: #303030;
$blueM: #0065ff;

.main-container {
  width: 100%;
  margin: 0;
  // font-family: "Roboto", sans-serif;
}

.scrool-toll::-webkit-scrollbar-track {
  box-shadow: transparent;
  background-color: #f8f8f8;
}

.scrool-toll::-webkit-scrollbar {
  width: 4px;
  background-color: #d8d8d8;
  height: 4px;
  border: 4px;
}

.scrool-toll::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
}

button {
  background: transparent;
  border: transparent;
  cursor: pointer;
}

/*all-ticket-list */
.all-ticket {
  background: #f2f7ff;
  padding: 8px 20px;
  box-shadow: 1px -2px 7px -4px #00000063;
  z-index: 99;
  height: 98vh;
  max-width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  width: 22%;
  top: 0;
  background: linear-gradient(to bottom, #ff980026, #ffff, #4caf502b); // independence day changes 

  > div {
    padding: 0 0 30px;
  }

  a {
    &.home-link {
      font-size: 14px;
      color: #0065ff;
      padding: 2px 3px 6px;
      display: inline-block;
      margin: 5px 0 0;

      svg {
        width: 18px;
        height: 18px;
        padding: 0;
        font-size: 9px;
        vertical-align: bottom;
      }

      &:hover {
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  button {
    &.next-btn {
      background-color: #0065ff;
      color: #fff;
      position: absolute;
      right: 10px;
      font-size: 11px;
      top: 12px;
      height: 30px;
      min-width: 69px;
      text-transform: capitalize;
      border-radius: 22px;
      border: none;
      outline: none;
      box-shadow: 0px 0px 5px 1px #0000001a;
      i {
        font-size: 14px;
        margin: 0 0 0 4px;
        position: relative;
        top: -1px;
      }
    }
  }

  h2 {
    color: #808080;
    text-align: left;
    display: inline-block;
    font-size: 14px;
    position: relative;
    font-weight: 500;
    margin: 0 0 5px;
    font-family: "robotomedium";

    // &::before{
    //     content: "_";
    //     position: absolute;
    //     right: -15px;
    //     top: -4px;
    // }
    &.card-heading {
      margin: 10px 0 0;
    }

    span {
      &.lead-id {
        a {
          color: #0065ff;
          font-size: 12px;
          font-weight: 600;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .agent-name {
    position: relative;
    margin: 5px 0 8px;
    min-height: 60px;
    vertical-align: middle;

    span {
      &.short-name {
        position: absolute;
        background-color: #c9e5f8;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border-radius: 50%;
        border: 4px solid #ffffff;
        left: 0px;
        color: #0065ff;
        font-size: 14px;
        font-weight: 900;
      }
    }

    h5 {
      padding: 12px 0 0 75px;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      font-family: "robotomedium";

      span {
        color: #0065ff;
        display: block;
        font-size: 12px;
      }
    }
  }

  .booking-deatil-box {
    margin: 5px 0 0;

    .edit-mode-view {
      margin: 15px 0;

      .MuiFormControl-marginNormal {
        margin: 0;
      }

      .MuiOutlinedInput-input {
        padding: 12.5px 14px;
        font-size: 12px;
        color: #253858;
        text-transform: capitalize;
      }

      .MuiInputLabel-outlined {
        top: -5px;
        font-size: 12px;
        color: #253858;
        opacity: 1;
        min-width: auto;
        text-transform: capitalize;
      }

      .MuiInputLabel-outlined.MuiInputLabel-shrink {
        top: 1px;
        font-size: 14px;
        // background-color: #fff;
      }

      .MuiSelect-select:focus {
        background-color: transparent;
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $blueM;
        border-width: 1px;
      }
    }

    ul {
      &.booking-list-view {
        margin: 0 0 5px;

        li {
          width: 46%;
          display: inline-block;
          margin: 6px 0;
          padding: 0 5px;

          // &:nth-child(even){
          //     width: 60%;
          //     padding:0;
          // }
          span {
            font-size: 10px;
            color: #253858;
            line-height: 16px;
            font-weight: 300;
          }

          strong {
            font-size: 12px;
            color: #253858;
            display: block;
            line-height: 19px;
            font-weight: 400;
            word-break: break-word;

            // overflow: hidden;
            // white-space: nowrap;
            // text-overflow: ellipsis;
            a {
              color: #0065ff;
              font-weight: 600;
              font-size: 12px;
              display: block;
              overflow: hidden;
              white-space: nowrap;
              line-height: 18px;
              text-overflow: ellipsis;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }

    .booking-status-bar {
      h2 {
        margin: 20px 0 5px;
        font-size: 16px;
      }

      .step-bar {
        ul {
          padding: 15px 0 10px;

          li {
            display: inline-block;
            padding: 2px;
            position: relative;
            width: 100%;
            min-height: 70px;

            p {
              font-size: 14px;
              font-weight: 500;
              color: #253858;
              position: relative;
              padding: 0px 0 0 25px;
              opacity: 1;

              span {
                font-size: 10px;
                font-weight: 400;
                color: #253858;
                position: absolute;
                right: 0;
                top: 5px;

                a {
                  background-color: #deebff;
                  border-radius: 4px;
                  padding: 10px;
                  min-width: 64px;
                  height: 24px;
                  color: #0065ff;
                  font-size: 10px;
                }
              }
            }

            span.checked {
              position: absolute;
              top: 0;
            }

            &:after {
              border: 1px solid #2fc38a;
              content: "";
              left: 10px;
              position: absolute;
              height: 80%;
              z-index: -2;
              top: -55px;
            }

            &.active p {
              opacity: 0.6;
            }

            &:first-child:after {
              content: none;
            }
          }
        }
      }
    }
  }

  .card {
    border-radius: 8px;
    margin: 8px 0 20px;
    position: relative;
    box-shadow: 0px 6px 16px #3469cb29;
    background-color: $whiteM;
    cursor: pointer;

    &.active {
      border: 2px solid $blueM;
      .tiked-icon {
        display: block;
      }
    }
    &.pb-parnter {
      background-color: #c9dffd;
    }

    .tiked-icon {
      position: absolute;
      right: -9px;
      top: 7px;
      display: none;
    }

    h4 {
      padding: 5px 15px 5px;
      font-size: 15px;
      line-height: 20px;
      margin: 0;
      font-weight: 500;
      font-family: "robotomedium";
      button {
        &.auto-closue-btn {
          background-color: #0065ff;
          margin: 5px;
          display: inline-block;
          min-width: 20px;
          height: 20px;
          line-height: 20px;
          padding: 0 3px;
          font-weight: 700;
          span {
            color: #fff;
          }
        }
      }
    }

    .ticket-card-list {
      ul {
        display: block;
        padding: 0 15px 10px 15px;
        margin: 0;

        li {
          display: inline-block;
          width: 49%;
          margin: 5px 0;
          padding: 0;
          word-break: break-word;
          span {
            color: #919bab;
            font-weight: 300;
            line-height: 16px;
            font-size: 11px;
            font-family: "robotomedium";

            &.cust-res {
              color: #00bcd4;
            }

            &.target-res {
              color: #ff900b;
            }
          }

          strong {
            color: $blackB;
            font-size: 13px;
            line-height: 19px;
            display: block;
            padding: 0 4px 0 0;
            font-weight: 400;

            &.ticket-id-bold {
              font-weight: 900;
              font-family: "robotobold";
            }

            span {
              display: block;
              word-break: break-word;
              color: #253858;
              font-size: 12px;
            }
          }
        }

        div {
          &.issue-name {
            li {
              margin: 0 0 5px;
              width: 100%;

              span {
                color: #172b4d;
                font-weight: 300;
                line-height: 16px;
                font-size: 12px;
              }

              strong {
                color: $blackB;
                font-size: 14px;
                line-height: 19px;
                display: block;
                font-weight: 400;

                span {
                  font-size: 12px;
                  font-weight: 500;
                }

                button {
                  &.auto-closue-btn {
                    background-color: #0065ff;
                    margin: 5px;
                    display: inline-block;
                    min-width: 20px;
                    height: 20px;
                    line-height: 20px;
                    padding: 0 3px;
                    font-weight: 700;

                    span {
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    button {
      &.right-btn {
        position: absolute;
        right: 15px;
        top: 18px;
      }
    }

    .ticket-card-footer {
      background: #fffbed;
      position: relative;
      padding: 10px 15px;
      border-radius: 0 0 8px 8px;

      &.resolved {
        background: #e3fcef;

        .footer-data {
          h6 {
            color: #19b24d;
            margin: 3px 0 5px;
            line-height: 21px;
            font-weight: 600;
          }
        }

        img {
          top: 16px;
          left: 14px;
        }
      }

      img {
        position: absolute;
        left: 15px;
        top: 14px;
      }

      .footer-data {
        padding: 0 0 0 20px;

        h6 {
          color: #ff900b;
          font-size: 16px;
          line-height: 21px;
          font-weight: 600;
          margin: 0px 0 3px;
          line-height: 21px;
        }

        strong {
          font-size: 14px;
          color: #172b4d;
          line-height: 19px;
          font-weight: 500;
          display: block;
          margin-bottom: 3px;
          font-family: "robotobold";
        }

        span {
          color: #5e6c84;
          font-size: 12px;
        }
      }
    }

    &:hover {
      cursor: pointer;
    }
    .tag-btn {
      position: relative;
      width: 81%;
      padding: 10px 15px 0;
      span {
        display: inline-block;
        min-width: 40px;
        padding: 5px;
        height: 16px;
        line-height: 14px;
        text-align: center;
        background: #f6bfbe;
        color: #b65757;
        border-radius: 4px;
        margin-right: 5px;
        font-family: "robotobold";
        &.krg-icon {
          background: #dcf6e2;
          color: #4daa51;
        }
      }
    }
  }

  button {
    &.edit-mode-btn {
      color: #fff;
      min-width: 54px;
      height: 24px;
      position: absolute;
      background-color: #0763f6;
      border-radius: 4px;
      font-size: 11px;
      line-height: 24px;
      top: 111px;
      right: 9px;
      text-transform: capitalize;
    }
  }

  .spinner-loader {
    position: relative;
    left: 128px;
    right: 129px;
    margin: 27px auto 0;
    width: 100%;
    top: 0;

    svg {
      color: $blueM;
    }
  }
}

.all-ticket-list {
  background-color: $whiteM;
  display: block;

  li {
    display: inline-block;
    padding: 0;
    width: 74%;
    vertical-align: top;

    // &:nth-child(2){
    //     padding: 5px;
    // }
    &:nth-child(1) {
      width: 25%;
    }
  }
  table{
    tbody{
      tr{
        td{
          ul,ol{
            li{
              display: block;
              background-color: transparent;
              border:none;
              line-height: 18px;
              padding: 0;
              margin:0 0 4px;
              font-size: 16px;
              font-weight:400;
              &::before{
                display: none;
              }
              &:hover,&:focus{
                border:none;
              }
            }
          }
          ul{
            li{
              display: list-item;
              list-style-position: inside;
              list-style-type: disc;
              font-weight:400;
              strong{
                font-weight:400;
              }
            }
          }
          ol{
            li{
              list-style-type:decimal;
              display: list-item;
              list-style-position: inside;
              font-weight:400;
              strong{
                font-weight:400;
              }
            }
          }
        }
      }
    }
  }
}

.all-ticket-header {
  position: relative;
  vertical-align: middle;
  margin: 10px 0 20px;

  .user-info-detail {
    margin: 0 0 8px;

    .logo-box {
      position: absolute;
      top: 5px;
      left: 0;
      background: $whiteM;
      padding: 4px 0;

      img {
        max-width: 100px;
        max-height: 42px;
      }
    }

    .title-box {
      padding: 4px 0 0 115px;
      min-height: 45px;

      h5 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        font-family: "robotobold";
        padding: 7px 0 0;
      }
    }
  }
}


// pendcy-listing
.pendency-listing{
  .multi-select-box{
    height: 39px;
    position: relative;
    top: -1px;
    .MuiInputBase-root{
      padding: 8px 10px 4px 4px;
      overflow-y: scroll !important;
      height: 42px;
      position: relative;
    }
    .MuiOutlinedInput-input{
      padding:16px 30px 11px 10px;
    }
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
       .MuiAutocomplete-tag{
          padding: 0;
          font-size: 11px;
          margin: 3px 2px 1px 3px;
          height: 22px;
      }
    }
    .MuiFormControl-root{
      border: 1px solid #E2E2E2;
      border-radius: 4px;
      background: #fff;
      position: relative;
    }
    svg{
      &.MuiChip-deleteIcon{
        width: 13px;
      }
    }
    .MuiOutlinedInput-notchedOutline{
      display: none;
    }
    .MuiSvgIcon-fontSizeSmall{
      width: 13px;
    }
    button{
      background-color: transparent;
      min-width: auto;
      color:#000;
      &:hover{
        background-color: transparent;
      }
    }
  }
  
}