// dashboard
.pbdashboard-summary {
  background-color: #ffffff;
  // padding-bottom: 20px;

  .pbdashboard-topbar {
    // background-color: #2e86c1;
    background-color: #deebff;
    padding: 4px 16px;
    background:linear-gradient(to bottom, #ff980085, #ffff, #4caf5054); // independence day changes 
    @media (max-width: 768px){
      padding: 0 10px;
      text-align: center;
    }
    @media (max-width: 1366px){
      padding: 4px 10px 2px;
    }
    h2 {
      font-size: 16px;
      color: #253858;
      margin: 0;
      padding: 8px 0;
      font-weight: 600;
      vertical-align: middle;
      @media (max-width: 1366px){
        font-size: 14px;
        font-weight: 900;
        padding: 10px 0 2px;
      }
      @media (max-width: 1140px){
        padding: 10px 0 2px;
      }
      span {
        color: #253858eb;
        font-size: 18px;
        text-transform: capitalize;
        position: relative;
        // padding: 0 5px 0px 9px;
        // background: #fff;
        // &::before{
        //   content: "";
        //   position: absolute;
        //   left: 0;
        //   bottom: 0;
        //   width: 55px;
        //   border-left: 4px solid #2196f3;
        //   height: 100%;
        // }
      }

    }

    ul {
      padding: 5px 0;
      @media (max-width: 768px){
        text-align: center;
      }
      li {
        padding: 0;
        vertical-align: middle;
        list-style: none;
        display: inline-block;
        width: auto;
        margin: 0px 5px 6px;

        a {
          background-color: #d0e0f9;
          padding: 0 8px;
          border-radius: 30px;
          color: #253858eb;
          width: 14px;
          display: block;
          text-align: center;
          height: 28px;
          line-height: 28px;
          cursor: pointer;

          i {
            color: #03a9f4;
            font-size: 14px;
          }
        }
        button {
          &.next-btn {
            background-color: #0065ff;
            color: #fff;
            position: relative;
            right: 0;
            font-size: 11px;
            top: 0;
            height: 30px;
            min-width: 69px;
            text-transform: capitalize;
            border-radius: 22px;
            border: none;
            outline: none;
            box-shadow: 0px 0px 5px 1px #0000001a;
            i {
              font-size: 14px;
              margin: 0 0 0 4px;
              position: relative;
              top: -1px;
            }
          }
        }
      }
    }

    .top-head-list {
      li {
        a {
          background-color: #fff;
          min-width: 40px;
          padding: 0 5px;
          height: 30px;
          max-width: 100%;
          box-shadow: 0px 3px 6px #00000029;
          color: #0052cc;
          font-size: 13px;
          cursor: pointer;
          &:hover{
            text-decoration: none;
          }

          i {
            color: #0065ff;
            @media (max-width: 768px){
              font-size:16px;
            }
          }

          &.home {
            i {
              font-size: 21px;
              margin: 4px;
              @media (max-width: 768px){
                font-size:16px;
              }
            }
            
          }

          &.file {
            i {
              font-size: 18px;
              margin: 5px;
              @media (max-width: 768px){
                font-size:16px;
              }
            }
          }
        }
      }

      button {
        &.create-ticket {
          height: 34px;
          min-width: 50px;
          padding: 15px 8px;
          text-align: center;
          // margin: 0 5px;
          border-radius: 30px;
          text-transform: capitalize;
          background-color: #fff;
          font-size: 13px;
          color: #0052cc;
          background-color: #ffffff;
          box-shadow: 0px 3px 6px #00000029;
          font-weight: 500;
          em{
            font-style: normal;
            background-color: #0165ff;
            color: #fff;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            padding: 3px;
            text-align: center;
            line-height: 11px;
            margin: 0 6px 0 0;
            font-size: 10px;
          }
          @media (max-width: 768px){
            font-size: 12px;
          }
          &.pull-request {
            img {
              margin-right: 5px;
            }
          }
          img{
            @media (max-width: 768px){
              width:16px
            }
          }
        }
      }
    }

  }

  .ticket-summary-list {

    // margin: 0 17px;
    // box-shadow: 0px 3px 6px #00000029;
    // background: #fcfcfc;
    // padding: 10px;
    h4 {
      font-size: 18px;
      padding: 10px 20px 5px;
      color: #253858;
    }

    ul {
      padding: 0 20px;
      margin: 5px 0 0;
      @media (max-width: 768px){
        padding: 0 5px;
        text-align: center;
      }
      li {
        padding: 0;
        vertical-align: middle;
        list-style: none;
        display: inline-block;
        width: 6.8%;
        @media (max-width: 1120px){
          width:7.8%
        }
        margin: 0px 5px 20px;
        cursor: pointer;
        @media (max-width: 768px){
          width: 30%;
        }

        div {
          background-color: #fff;
          position: relative;
          min-height: 64px;
          width: auto;
          box-shadow: 0 2px 4px -1px #00000033, 0 4px 5px 0 #00000024, 0 1px 10px 0 #0000001f;
          text-align: center;
          line-height: 18px;
          border-radius: 5px;
          padding: 2px 0;
          border: 2px solid #fff;

          em {
            position: absolute;
            left: 0;
            background-color: #03a9f4;
            box-shadow: 0px -3px 6px #16161678;
            color: #fff;
            width: 100%;
            font-size: 19px;
            height: 32px;
            top: -9px;
            line-height: 32px;
            border-radius: 5px 5px 0 0;
            padding: 2px 0;
          }

          span {
            color: #253858;
            font-size: 11px;
            font-weight: 400;
            padding: 0;
            display: block;
            line-height: 13px;
            height: 44px;
          }

          p {
            line-height: 20px;
            padding: 0 0 5px 0;
            font-weight: 600;
            font-size: 22px;
          }

          &.new-ticket {
            p {
              color: #53d192;
            }

            span {
              color: #53d192;
            }

          }

          &.due-ticket {
            p {
              color: #e55957;
            }

            span {
              color: #e55957;
            }
          }

          &.duetoday-ticket {
            p {
              color: #ffd200;
            }

            span {
              color: #ffd200;
            }
          }

          &.open-ticket {
            p {
              color: #e05d6f;
            }

            span {
              color: #e05d6f;
            }
          }

          &.Unassigned-ticket {
            p {
              color: #6ccac9;
            }

            span {
              color: #53d192;
            }
          }

          &.revert-ticket {
            p {
              color: #fbb168;
            }

            span {
              color: #fbb168;
            }
          }

          &.cust-ticket {
            p {
              color: #616ca9;
            }

            span {
              color: #616ca9;
            }
          }

          &.reopen-ticket {
            p {
              color: #e17695;
            }

            span {
              color: #e17695;
            }
          }

          &.resolved-ticket {
            p {
              color: #056309;
            }

            span {
              color: #056309;
            }
          }

          &.escalation-ticket {
            p {
              color: #f32b28;
            }

            span {
              color: #f32b28;
            }
          }

          &.unassigned-customer-pendency-ticket{
            p {
              color: #7884b7;
            }

            span {
              color: #7884b7;
            }
          }

          &.green-ticket {
            p {
              color: #0065ff;
            }

            span {
              color: #0065ff;
            }
          }

          &.renewal-ticket {
            p {
              color: #ff52f1;
            }

            span {
              color: #ff52f1;
            }
          }

          &.jag-ticket {
            p {
              color: #975862;
            }

            span {
              color: #975862;
            }
          }

          &.nil-endorsement-ticket {
            p {
              color: #2196f3;
            }

            span {
              color: #2196f3;
            }
          }

          &.insurer-initiated-ticket {
            p {
              color: #828a96;
            }

            span {
              color: #828a96;
            }
          }

        }

        &:hover,
        &:focus,
        &.active {
          div {
            box-shadow: 0px 4px 0px 2px #2196f3;

            &.new-ticket {
              box-shadow: 2px 3px 3px 1px #53d192;
            }

            &.due-ticket {
              box-shadow: 2px 3px 3px 1px #e55957;
            }

            &.duetoday-ticket {
              box-shadow: 2px 3px 3px 1px #ffd200;
            }

            &.open-ticket {
              box-shadow: 2px 3px 3px 1px #e05d6f;
            }

            &.Unassigned-ticket {
              box-shadow: 2px 3px 3px 1px #53d192;
            }

            &.revert-ticket {
              box-shadow: 2px 3px 3px 1px #fbb168;
            }

            &.cust-ticket {
              box-shadow: 2px 3px 3px 1px #616ca9;
            }

            &.reopen-ticket {
              box-shadow: 2px 3px 3px 1px #e17695;
            }

            &.resolved-ticket {
              box-shadow: 2px 3px 3px 1px #056309;
            }

            &.escalation-ticket {
              box-shadow: 2px 3px 3px 1px #f32b28;
            }

            &.unassigned-customer-pendency-ticket{
              box-shadow: 2px 3px 3px 1px #7884b7;
            }

            &.green-ticket {
              box-shadow: 2px 3px 3px 1px #0065ff;
            }

            &.renewal-ticket {
              box-shadow: 2px 3px 3px 1px #ff52f1;
            }

            &.jag-ticket {
              box-shadow: 2px 3px 3px 1px #975862;
            }

            &.nil-endorsement-ticket {
              box-shadow: 2px 3px 3px 1px #2196f3;
            }

            &.insurer-initiated-ticket {
              box-shadow: 2px 3px 3px 1px #828a96;
            }
          }
        }

      }
    }
  }

  .search-box {
    padding: 0 25px;
    border-top: 1px solid #f3f3f3;
    box-shadow: 0px 6px 6px #3469cb29;
    border-bottom: 3px solid #cfcfcf;

    ul {
      padding: 0;

      li {
        display: inline-block;
        width: auto;
        padding: 5px 0;

        label {
          span {
            font-size: 14px;
          }

          .MuiRadio-root {
            padding: 5px 8px;

            svg {
              width: 18px;
            }
          }
        }
      }
    }

    .select-insurer-box {
      .MuiOutlinedInput-input {
        padding: 13.5px 14px;
      }

      .MuiInputLabel-outlined {
        top: -5px;
      }

      .MuiInputLabel-outlined.MuiInputLabel-shrink {
        top: 0px;
        padding: 1px 10px;
        left: -6px;
        background: #fff;
      }

      button {
        &.load-data-btn {
          background-color: #03a9f4;
          color: #fff;
          border: transparent;
          margin: 14px 5px 10px;
          height: 35px;
          line-height: 43px;
          font-size: 14px;
          font-weight: 500;
          min-width: 92px;
          text-transform: capitalize;

          &:hover {
            background-color: #03a9f4;
          }
        }
      }
    }
  }

  .load-data-list {
    padding: 5px 20px 10px;
    overflow-x: scroll;
    overflow-y: scroll;
    white-space: nowrap;
    width: auto;
    margin: 0;
    min-height: 120px;
    max-height: 350px;
    position: relative;
    background: #f1f1f1;

    ul {
      padding: 5px 5px;
      display: table;
      box-shadow: 2px 3px 3px 1px #00000029;
      margin-bottom: 5px;
      border-radius: 0 0 4px 4px;
      position: relative;

      li {
        margin-right: 11px;
        display: inline-block;
        word-break: break-all;
        width: 180px !important;
        padding: 8px 8px;
        vertical-align: middle;
        margin: 0;
        font-size: 12px;

        &:nth-child(15) {
          width: 200px !important;
        }

        strong {
          word-break: break-word;
          white-space: initial;
          font-size: 12px;
          font-weight: 399;
        }

        a {
          color: #0052cc;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .head-list {
      li {
        font-weight: 900;
        margin-bottom: 5px;
        font-size: 12px;
        // font-family: "robotobold";
      }
    }

    .body-list {
      ul {
        background-color: #fff;
        margin: 0 0 5px;
      }
    }

    ul {

      // &.head {
      //   li {
      //     font-weight: 900;
      //     margin-bottom: 5px;
      //     font-size: 12px;
      //     font-family: "robotobold";
      //   }
      // }
      // li {
      //   width: 12.5%;
      //   display: inline-block;
      //   padding: 8px;
      //   word-break: break-all;
      //   white-space: initial;
      //   vertical-align: middle;
      //   font-family: "robotoregular";
      //   &:nth-child(2),&:nth-child(3) {
      //     width: 8%;
      //   }
      //   a {
      //     color: #0052cc;
      //     text-decoration: underline;
      //     cursor: pointer;
      //   }
      // }
      // &.body-list {
      //   background-color: #fff;
      //   box-shadow: 0px 3px 11px 2px #6e6e6e29;
      //   margin: 0 0 5px;
      //   border-radius: 0 0 4px 4px;
      //   li{
      //     padding: 4px 8px;
      //     strong{
      //       font-weight: 400;
      //       font-size: 12px;
      //       color:#253858;
      //       line-height: 12px;
      //     }
      //   }
      // }
    }

    .load-data-head {
      background-color: #f5f6f9eb;
      padding: 0px 15px 15px;
    }

    .no-data-found {
      text-align: center;
      padding: 15px;
      background-color: #fff;

      p {
        color: #253858;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .export-data-list {
      max-height: 380px;
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 5px 0 10px;
    }
  }

  .search-loader {
    left: 50%;
    right: 50%;
    margin: auto;
    top: 10%;
    z-index: 9999;
    position: relative;

    svg {
      color: #2196f3;
    }
  }

  button {
    &.export-btn {
      // background: #03a9f4;
      background-color: #5392FF;
      color: #fff;
      font-size: 14px;
      text-transform: capitalize;
      min-width: 120px;
      border-radius: 22px;
      height: 40px;
    }
    &.clear-btn{
      background-color: #a6a5a587;
      color: #808080;
      font-size: 14px;
      text-transform: capitalize;
      min-width: 120px;
      border-radius: 22px;
      height: 40px;
    }
  }
}

// advance search
.advance-search {
  padding: 30px 24px;

  .advance-search-box {
    background: #e6f2fb;
    border-radius: 22px;
    padding: 4px 58px 4px 12px;
    border: 1px solid #ccc;
    color: #253858;

    .MuiInputLabel-shrink {
      left: 8px;
      top: -8px;
      background: #fff;
      color: #253858;
      font-size: 16px;
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: transparent;
    }

    label+.MuiInput-formControl {
      margin-top: 7px;
      padding: 0 0 6px;
      color: #253858;
      font-size: 12px;
    }

    .MuiInput-root {
      position: relative;
      font-size: 14px;
      color: #253858;
    }
  }

  button {
    margin-right: 10px;

    &.-searchbtn {
      background-color: #5392FF;
      color: #fff;
      text-transform: capitalize;
      min-width: 90px;
      right: 0;
      border-radius: 5px;
    }

    &.adv-search-btn {
      background-color: #5392FF;
      color: #fff;
      text-transform: capitalize;
      min-width: 54px;
      border-radius: 0 22px 22px 0;
      position: absolute;
      right: -9px;
      height: 40px;
      cursor: pointer;
      margin-right: 10px;
    }

    &.export-btn {
      // background-color: #2196f3;
      background-color: #5392FF;
      color: #fff;
      text-transform: capitalize;
      min-width: 90px;
      border-radius: 5px;
      margin: 2px 10px;
    }
    
  }

  .form-control {
    width: 100%;

    .MuiOutlinedInput-input {
      padding: 13.5px 14px;
      font-size: 12px;
    }

    .MuiInputLabel-shrink {
      left: 0;
      top: 2px;
      font-size: 13px;
      color: #2196f3;
      background: transparent;
    }

    .MuiInputLabel-outlined {
      font-size: 14px;
    }

    .date-picker {
      border-radius: 5px;
      border: 1px solid #95AFFC;
      padding: 0 8px;

      .MuiInputLabel-shrink {
        left: 9px;
        top: -6px;
        font-size: 13px;
        background: #fff;
        color: #2196f3;
      }

      .MuiOutlinedInput-input {
        padding: 15.5px ​14px;
      }

      .MuiInput-root {
        top: -4px;
      }

      &:hover {
        border: 1px solid #414141;
      }

      &:focus {
        border: 1px solid #95AFFC;
      }

      label+.MuiInput-formControl {
        color: #253858;
        font-size: 12px;
      }
    }

    .MuiOutlinedInput-input {
      padding: 15.5px ​14px;
    }

    label+.MuiInput-formControl {
      color: #808080;
      font-size: 12px;
    }

    .MuiInput-underline:before,
    .MuiInput-underline:after {
      border-bottom: none;
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: transparent;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: #95AFFC;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #95AFFC;
      border-width: 1px;
    }
  }
}

// pbdashboard-simple-seach
.pbdashboard-simple-seach {
  margin: 10px 0 0;
  box-shadow: 0px 3px 6px #00000029;
  background: #fcfcfc;
  padding: 5px 10px;
  @media (max-width: 768px){
   margin:0 5px;
   padding: 5px;
  }
  h4 {
    font-size: 16px;
    font-weight: 600;
    color: #0065ff;
    position: relative;
    box-shadow: 0px 3px 6px #00000029;
    padding: 10px 15px 11px;
    margin-bottom: 10px;

    span {
      &.info-btn {
        position: relative;
        display: inline-block;
        width: 22px;
        text-align: center;
        border-radius: 16px;
        height: 22px;
        left: 5px;

        svg {
          font-size: 20px;
          color: #2979ff;
          cursor: pointer;
          position: relative;
          top: 5px;
        }

        em {
          &.info-msg {
            position: absolute;
            background-color: #deebff;
            color: #253858;
            font-size: 11px;
            width: 388px;
            font-style: normal;
            padding: 5px 7px;
            border-radius: 7px;
            left: 30px;
            display: none;
            top: 2px;
            word-break: break-word;

            span {
              position: absolute;
              width: 0;
              height: 0;
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-top: 6px solid #deebff;
              left: -9px;
              bottom: 10px;
              transform: rotate(90deg);
            }
          }
        }

        &:hover {
          em {
            &.info-msg {
              display: block;
            }
          }
        }
      }
    }
  }

  ul {
    padding: 0;
    margin:0;
    .access-info{
      color: #ff0000;
      padding: 0 14px;
    } 
    li {
      width: auto;
      display: inline-block;
      vertical-align: top;
      margin: 5px 10px 10px;
      @media (max-width: 768px){
        margin:5px;
      }
      &:nth-child(1) {
        &.search-box {
          width: 20%;
          @media (max-width: 768px){
            width: 96%;
          }
          p{
            &.error-msg {
              color: #f44336;
              position: absolute;
              bottom: -22px;
              left: 1px;
              font-size: 11px;
            }
          }
        }
      }
      .MuiButtonBase-root.Mui-disabled {
        cursor: default;
        pointer-events: none;
        background-color: #8080806b;
      }
      .form-control {
        width: 100%;
        border-radius: 4px;
        background: transparent;
        position: relative;

        .MuiInput-underline:before,
        .MuiInput-underline:after {
          border: transparent;
        }
        .MuiOutlinedInput-input {
          padding: 14.5px 14px;
        }

        .MuiInput-underline:hover:not(.Mui-disabled):before {
          border: transparent;
        }

        label+.MuiInput-formControl {
          margin-top: 0;
          padding: 4px;
        }

        .MuiInputLabel-formControl {
          top: -14px;
          left: 12px;
          font-size: 14px;
          color: #000;
        }

        .MuiInputLabel-shrink {
          top: -7px;
          left: 10px;
          background: #fff;
          padding: 0 4px;
          z-index: 99;
          font-size: 15px;
        }

        .MuiInputBase-input {
          padding: 8px 6px;
          font-size: 15px;
          font-weight: 500;
        }

        .MuiOutlinedInput-input {
          padding: 12.5px 14px;
          font-size: 12px;
          color: #253858;
        }

        .MuiInputLabel-outlined {
          top: -5px;
          font-size: 12px;
          color: #253858;
          opacity: 1;
          min-width: auto;
          text-transform: capitalize;
        }

        .MuiSelect-select.MuiSelect-select,
        .MuiSelect-select:focus {
          background-color: #fbfbfb5c;
        }

        // .MuiInputLabel-outlined.MuiInputLabel-shrink {
        //   top: 1px;
        //   font-size: 14px;
        //   background-color: transparent;
        // }

        .MuiSelect-select:focus {
          background-color: transparent;
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
          border-color: #0065ff;
          border-width: 1px;
        }

        .MuiSelect-selectMenu {
          padding: 8px;
          padding-right: 24px;
          font-size: 14px;
          color: #253858;
        }

        button {
          &.search-btn {
            background: #5392FF;
            position: absolute;
            right: 0;
            border-radius: 0 22px 22px 0;
            color: #fff;
            top: 0px;
            height: 39px;
          }
        }

        &.calander-control {
          width: 100%;
          border: 1px solid #d5d5d5;
          border-radius: 4px;
          height: 38px;
          position: relative;
          margin: 0;

          .MuiInput-underline:before,
          .MuiInput-underline:after {
            display: none;
          }
          p{
            .error-msg {
              color: #f44336;
              position: absolute;
              bottom: -22px;
              left: 1px;
              font-size: 11px;
            }
          }
          .MuiButtonBase-root.Mui-disabled {
            cursor: default;
            pointer-events: none;
            background-color: transparent;
          }
        }
      }
      button {
        &.search-btn {
          background: #5392FF;
          position: relative;
          right: 0;
          border-radius: 22px;
          color: #fff;
          top: 0px;
          height: 39px;
          min-width: 85px;
          text-transform: capitalize;
        }
      }

      &.calander-list {
        width: 12.9%;
        position: relative;
        display: inline-block;
        padding: 0;
        margin: 5px 10px 15px;
        @media (max-width: 1300px){
         width:14.8%;
        }
        @media (max-width: 1160px){
          width:14.5%;
        }
        @media (max-width: 768px){
          width: 46.5%;
          margin: 8px 6px;
         }
        .form-control {
          .MuiInputLabel-shrink {
            font-size: 14px;
            top:-7px;
            left:7px;
          }

          .MuiFormControl-marginNormal {
            margin: 0;
          }
        }

        .MuiFormHelperText-root.Mui-error{
          font-size: 10px;
          position: relative;
          top:-5px;
          left:5px;
        }

        .error-msg {
          color: #f44336;
          position: absolute;
          bottom: -18px;
          left: 2px;
          font-size: 10px;
        }
        .select-product-error.MuiFormHelperText-root.Mui-error{
          top:-7px !important;
        }
      }

      button {
        &.export-btn {
          background-color: #5392FF;
          color: #fff;
          font-size: 12px;
          text-transform: capitalize;
          height: 38px;
          min-width: 78px;
          border-radius: 22px;
          padding: 0;
          margin:0 5px 0 12px;
        }
        &.clear-btn {
          background-color: #79808d;
          color: #fff;
          font-size: 12px;
          text-transform: capitalize;
          min-width:85px;
          border-radius: 22px;
          height: 38px;
          margin-left: 10px;
        }
      }
      
    }
  }


}

.load-data-list {
  padding: 5px 8px 10px;
  overflow-x: scroll;
  overflow-y: scroll;
  white-space: nowrap;
  width: auto;
  margin: 0;
  // min-height: 120px;
  // max-height: 250px;
  // height: calc(48vh - 20px);
  position: relative;
  background: #f1f1f1;

  ul {
    padding: 5px 5px;
    display: table;
    box-shadow: 0 2px 4px -1px #00000033, 0 4px 5px 0 #00000024, 0 1px 10px 0 #0000001f;
    margin-bottom: 5px;
    border-radius: 0 0 4px 4px;
    position: relative;

    li {
      margin-right: 11px;
      display: inline-block;
      word-break: break-all;
      width: 100px !important;
      padding: 8px 8px;
      vertical-align: middle;
      margin: 0;
      &:nth-child(1),
      &:nth-child(9),
      &:nth-child(12),
      &:nth-child(14),
      &:nth-child(20),
      &:nth-child(22),
      &:nth-child(23) {
        width: 120px !important;
      }

      &:nth-child(2),
      &:nth-child(3), &:nth-child(4),
      &:nth-child(8),&:nth-child(11){
        width: 260px !important;
      }

      strong {
        word-break: break-word;
        white-space: initial;
        font-size: 12px;
        font-weight: 399;
      }

      span {
        word-break: break-word;
        white-space: initial;
        font-size: 12px;
        font-weight: 900;
        display: block;
        color: #253858;
      }

      a {
        color: #0052cc;
        text-decoration: underline;
        cursor: pointer;
        margin-right: 5px;
        min-width: 80px;
        display: inline-block;
      }

      button {
        color: #fff;
        min-width: 38px;
        height: 19px;
        margin-left: 3px;
        font-size: 10px;

        &.add-btn {
          background-color: #03a9f4;
          min-width: 20px;
        }

        &.cgo-btn {
          background-color: #f44336;
        }

        &.hcs-btn {
          background-color: #056309;
        }

        span {
          font-weight: 400;
          color: #fff;
          font-size: 10px;
        }
      }
    }

    .sub-table {
      .body-list {
        ul {
          background-color: #f2f2f2;
          padding: 0;
        }
      }
    }
  }

  .head-list {
    li {
      font-weight: 900;
      margin-bottom: 5px;
      font-size: 12px;
      font-family: "robotobold";
    }
  }

  .body-list {
    ul {
      background-color: #fff;
      margin: 0 0 5px;
    }
  }

  .load-data-head {
    background-color: #f5f6f9eb;
    padding: 0px 15px 15px;
  }

  .no-data-found {
    text-align: center;
    padding: 15px;
    background-color: #fff;

    p {
      color: #253858;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .export-data-list {
    max-height: 380px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 5px 0 10px;
  }
}
.admin-data-list{
  ul{
    li{
      font-size: 12px;
      width:100px !important;
      &:nth-child(1),&:nth-child(19),&:nth-child(21){
        width:150px !important;
      }
      &:nth-child(3){
        width:100px !important;
      }
      &:nth-child(2),&:nth-child(6),&:nth-child(7),&:nth-child(15) {
        width: 200px !important;
      }
    }
  }
}

.search-box {
  margin: 0;
  ul {
    padding: 0 8px;
    li{
      justify-content: center;
    }
  }

  .MuiRadio-root {
    padding: 0px 6px;

    svg {
      width: 16px;
    }

  }

  .MuiTypography-root {
    font-size: 14px;
    font-weight: 600;
  }

  .form-control {
    .MuiOutlinedInput-root {
      border-radius: 22px;
    }
  }
}

.create-on-update-on {
  padding: 0px 10px 0;
  margin: 0 0 10px;
  .MuiRadio-root {
    padding: 0px 6px;

    svg {
      width: 16px;

    }
  }

  .MuiTypography-root {
    font-size: 14px;
    font-weight: 600;
  }
}

.tat-summary-box {
  margin:15px;
  @media (max-width: 768px){
    margin:5px;
  }
  ul {
    li {
      width: 14%;
      display: inline-block;
      margin: 0px 10px 20px;
      vertical-align: middle;
      position: relative;
      @media (max-width: 768px){
        width:45%;
        margin: 10px;
      }

      .error-msg {
        color: #f44336;
        position: absolute;
        bottom: -20px;
        left: 1px;
        font-size: 11px;
      }

      .form-control {
        width: 100%;
        border: 1px solid #cfcfcf;
        border-radius: 5px;
        height: 40px;

        .MuiOutlinedInput-input {
          padding: 13.5px 14px;
          font-size: 12px;
        }

        .MuiSelect-select.MuiSelect-select {
          padding: 7px 24px 0 12px;
          color: #253858;
        }

        .MuiInputLabel-outlined {
          font-size: 14px;
        }

        .MuiOutlinedInput-input {
          padding: 15.5px ​14px;
        }

        label+.MuiInput-formControl {
          color: #808080;
          font-size: 12px;
          margin-top: 8px;
        }

        .MuiInputLabel-formControl {
          top: -11px;
          left: 8px;
          font-size: 14px;
        }

        .MuiInputLabel-shrink {
          left: 9px;
          top: -7px;
          font-size: 14px;
          background: #fff;
          // color: #2196f3;
        }

        .MuiInput-underline:before,
        .MuiInput-underline:after {
          border-bottom: none;
        }

        .MuiInput-underline:hover:not(.Mui-disabled):before {
          border-bottom: transparent;
        }

        .MuiOutlinedInput-notchedOutline {
          border-color: #95AFFC;
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
          border-color: #95AFFC;
          border-width: 1px;
        }
      }
    }
  }
}

// agent-summary-list"
.agent-summary-list {
  ul {
    li {
      width: 17%;
      @media (max-width: 1120px){
        width:19%
      }
      position: relative;
      // &:nth-child(5){
      //   width:6%;
      // }

      .form-control {
        .MuiOutlinedInput-root {
          border-radius: 5px;
        }
      }

      .error-msg {
        color: #f44336;
        position: absolute;
        bottom: -22px;
        left: 1px;
        font-size: 11px;
      }
    }
  }
}

// agent-dashboard-box
.agent-dashboard-box {
  background: #f0f5fc;
  margin: 15px 0;

  .agent-dashboard-box-inner {
    padding: 0 5px;

    h4 {
      font-size: 18px;
      color: #253858;
    }
  }

  button {
    &.export-btn {
      background-color: #5392FF;
      color: #fff;
      font-size: 12px;
      text-transform: capitalize;
      min-width: 120px;
      border-radius: 22px;
      height: 33px;
      cursor: pointer;
    }
  }
}

.MuiGrid-root {
  position: relative;

  button {
    &.fetch-details-btn {
      height: 39px;
    }
    &.submit-btn {
      min-width: 120px;
    }
  }
}
.create-new-ticket{
  .MuiButtonBase-root.Mui-disabled{
    color:#808080 !important;
    border:1px solid #808080 !important;
    opacity: 0.4;
  }
}


.inline-eroro-msg {
  color: #f44336 !important;
  position: absolute;
  bottom: -4px;
  left: 14px;
  font-size: 11px !important;
}
.MuiMenu-list{
  li{
    padding: 5px;
    button{
      padding:0;
      .MuiTypography-displayBlock{
        font-size: 12px;
      }
    }
    .MuiListItemText-root{
      span{
        font-size: 12px;
      }
    }
  }
}

.select-product-error{
  font-size: 10px;
  position: relative;
  top: -5px;
  left: 5px;
}

