.stepper-wrap {
    max-width: 400px;
    margin: auto;
  .stepper-item {
    padding-bottom: 0px;
    position: relative;
    display: flex;
    align-items: center;

    &:not(:first-child):after {
      height: 100%;
      width: 2px;
      position: absolute;
      left: 2.8%;
      content: "";
      bottom: 60%;
      border: 1px solid #2FC38A;
      box-sizing: border-box;
      z-index: 2;
    }


    .icon-wrap {
      .icon {
        height: 18px;
        width: 18px;
        background-image: url('/images/Unticked.svg');
        display: inline-block;
        border-radius: 50px;
        background-repeat: no-repeat;
        background-position-x: 50%;
        background-position-y: 50%;
        z-index: 3;
        position: relative;
      }
    }
    .card-wrap {
      border-radius: 6px;
      padding: 6px 0px;

      .content {
        display: flex;
        justify-content: space-between;
        position: relative;
        p{
          font-size: 12px;
          font-weight: 500;
          color: #253858;
          position: relative;
          padding: 10px 100px 10px 5px;
          }
          span{
            position: absolute;
            right:0px;
            top:10px;
            font-size: 10px;
            font-weight: 400;
            color:#253858;
            opacity: 0.6;
            button{
              background-color: #DEEBFF;
              border-radius: 4px;
              padding: 0 10px;
              min-width: 64px;
              height: 24px;
              color: #0065FF;
              font-size: 10px;
              line-height: 16px;
            }
        }
      }

      .title {
        font-size: 16px;
        margin: 0;
        color: #000;
        font-weight: 500;
        text-transform: capitalize;
      }

      .description {
        color: #000;
        font-size: 14px;
        margin-top: 7.5px;
        line-height: 1.5rem;
      }

    }
    &.success{
      .icon-wrap{
        .icon{
          background-image: url('/images/status_tick.svg');
        }
      }
    }
    &.current{
      .icon-wrap {
        .icon {
          height: 18px;
          width: 18px;
          display: inline-block;
          border-radius: 50px;
          background-repeat: no-repeat;
          background-position-x: 50%;
          background-position-y: 50%;
          z-index: 3;
          position: relative;
          &::before {
            content: '';
            display: block;
            height: 18px;
            width: 18px;
            background-image: url('/images/Current.svg');
            background-image: url('/images/status_tick.svg');
            background-image: url('/images/Unticked.svg');
            z-index: 99;
          }
        }
      }
    }
    &.current{
      .icon-wrap {
        .icon {
          height: 18px;
          width: 18px;
          display: inline-block;
          border-radius: 50px;
          background-repeat: no-repeat;
          background-position-x: 50%;
          background-position-y: 50%;
          z-index: 3;
          position: relative;
          &::before {
            content: '';
            display: block;
            height: 18px;
            width: 18px;
            background-image: url('/images/Current.svg');
            z-index: 99;
          }
        }
      }
    }
  }

  .stepper-item.pending {
    .card-wrap {
      border-color: red;
    }

    &:after {
      border: none;
      z-index: 1;
    }

    .icon {
      background-color: white;
    }

    .title {
      font-weight: 700;
    }
  }

  .stepper-item.failed {
    .icon-wrap {
      .icon {
        background-image: url('https://d30gqtvesfc1d5.cloudfront.net/static/img/failed-icon.svg');
      }
    }

    .extra-info {}

    .loan-status-upload-btn {
      font-weight: 800;
      color: green;
    }

    .loan-status-cancel-btn {
      font-weight: 800;
      color: blue;
    }
  }

  .stepper-item.ongoing {
    .icon {
      background: white;
      position: relative;
      box-shadow: 0 0 3px #dedbdb;
      z-index: 3;

      &:after {
        border-radius: 50px;
        content: "";
        background: green;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 6px;
        width: 6px;
      }
    }
  }

}


.w-10 {
    width:8%;
}

.w-90 {
    width: 92%;
}