.comment-history{
    padding:10px 15px;
    font-family: 'Roboto', sans-serif;
    h2{
        font-size: 18px;
        color: #253858;
        position: relative;
        min-width: 100px;
        display: inline-block;
        margin: 0;
        font-weight: 500;
        padding: 0 5px;
        margin: 0 0 20px;
        &::before{
            content: "";
            border-bottom: 2px solid #253858;
            position: absolute;
            left: 8px;
            bottom: -2px;
            height: 10px;
            padding: 5px;
            color: transparent;
            width: 20%;
        }
    }
    ul{
        padding:0;
        height: 300px;
        li{
            width:48%;
            background-color: #f5f5f5;
            padding: 10px 8px;
            display:inline-block;
            box-shadow: 0px 1px 6px 5px #67676726;
            border-radius: 5px;
            &:nth-child(1){
                width:48%;
                margin-right:30px;
            }
            h3{
                font-size: 14px;
                color:#253858;
                margin:0;
                line-height: 20px;
                padding:0 0 10px;
                margin:0 10px 0 10px;
                &.comment-header{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    button{
                        &.download-all-btn{
                            padding: 8px 19px;
                            background-color: #0075FF;
                            color: #fff;
                            border-radius: 22px;
                            min-width: 90px;
                            height: 38px;
                            text-transform: capitalize;
                            margin: 7px 10px 0 0;
                            svg{
                                color: #fff;
                                transform: rotate(180deg);
                                margin-left: 4px;
                                width: 18px;
                            }
                        }
                    }
                }
            }
            .comment-pannel{
                padding:10px 8px 10px;
                .form-control{
                    width:100%;
                    background-color: #fff;
                    margin:5px auto 10px;
                    height: 40px;
                    position: relative;
                    label + .MuiInput-formControl{
                        margin-top: 0;
                    }
                    .MuiSelect-selectMenu{
                        height: 26px;
                        padding: 13px 8px 5px;
                    }
                    .MuiSelect-select.MuiSelect-select{
                        background-color: #fff;
                        font-size: 12px;
                    }
                    .MuiInput-underline:after,.MuiInput-underline::before{
                        border: transparent;
                    }
                    .MuiSelect-icon{
                        top: calc(50% - 13px);
                    }
                    .MuiInputLabel-shrink{
                        top: -6px !important;
                    }
                    .MuiInputLabel-formControl {
                        top: -12px;
                        left: 8px;
                        z-index: 99;
                        font-size: 12px;
                        font-family: 'Roboto', sans-serif;
                    }
                    textarea{
                        min-height: 10px;
                        resize: none;
                        border: transparent;
                        padding: 10px 35px 10px 10px;
                        &:focus{
                            outline: none;
                        }
                    }    
                }
                span{
                    &.upload-file{
                        display: block;
                        padding:5px;
                        .upload-file-name{
                            display: inline-block;
                            margin-right: 5px;
                            position: relative;
                            background-color: #C9E5F8;
                            border-radius: 22px;
                            color: #253858;
                            padding: 5px 7px;
                            height: 15px;
                        }
                    }
                }
                .file-detail{
                    background-color: #cbcbcb;
                    padding:4px 8px;
                    border-radius: 22px;
                    margin: 6px 5px 0 0;
                    display: inline-block;
                    font-size: 11px;
                  }
                button{
                    &.submit-btn{
                        padding: 8px 19px;
                        background-color: #0075FF;
                        color: #fff;
                        border-radius: 22px;
                        min-width: 90px;
                        height: 38px;
                        text-transform: capitalize;
                        margin: 7px 10px 0 0;
                    }
                    &.attach-btn{
                        position: absolute;
                        margin: 0;
                        background-color: transparent;
                        right: -10px;
                        top: 5px;
                        width: 25px;
                        text-align: center;
                        padding: 5px 0;
                        i{
                            padding: 0;
                            font-size: 20px;
                            height: 20px;
                            border: transparent;
                        }
                    }
                }
                p{
                    &.attach-btn{
                        span{
                            &.attach-box{
                                right:10px;
                            }
                        }
                    }
                }
            }
            ul{
                padding:5px;
                &.comment-trail{
                    min-height: 100px;
                    max-height: 187px;
                    overflow-y: scroll;
                    li{
                        position: relative;
                        list-style: none;
                        width: 100%;
                        display: block;
                        min-height: 62px;
                        vertical-align: top;
                        padding: 0 5px;
                        background-color: transparent;
                        box-shadow: none;
                        .short-name{
                            height: 10px;
                            width: 10px;
                            border-radius: 50%;
                            position: absolute;
                            left: 0px;
                            top: 5px;
                            width: 32px;
                            height: 32px;
                            line-height: 32px;
                            text-align: center;
                            font-size: 12px;
                            text-transform: uppercase;
                            color: #253858;
                            z-index: 1;
                            background-color: rgba(47,142,253,.30196078431372547);
                            &:nth-child(n+1){
                                background-color: #ffe9cb;
                            }
                        }
                        .timeline{
                            border-left: 2px solid grey;
                            position: absolute;
                            top: 37px;
                            left: 15px;
                            height: 51%;
                            z-index: 0;
                            bottom: 0;
                        }
                        span{
                            &.comment-time{
                                position: absolute;
                                right: 4px;
                                top: 8px;
                                font-size: 10px;
                                color: #253858;
                            }
                        }
                        .commnets-txt{
                            font-size: 12px;
                            font-weight: 400;
                            color: #253858;
                            line-height: 19px;
                            padding:7px 101px 0 33px;
                            word-break: break-word;
                            white-space: break-spaces;
                            strong{
                                font-size: 11px;
                                font-weight: 400;
                                color: #253858;
                                line-height: 14px;
                                word-break: break-all;
                                white-space: normal;
                            }
                        }
                        span{
                            &.attachemt-link{
                               .upload-item{
                                    margin: 5px 20px;
                                    background: #ffffff;
                                    height: 22px;
                                    display: inline-block;
                                    padding: 6px 8px;
                                    border-radius: 7px;
                                   .file-extn{
                                       img{
                                           width:14px;
                                           margin:0 4px 0 0;
                                       }
                                   }
                                   .download-info{
                                       font-size: 10px;
                                   }
                               }
                            }
                        }
                        
                    }
                }
            }
        }
    }
}