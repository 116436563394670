// applogs
.app-logs{
    padding:20px;
    background: #fbfbfb5c;
    height: 100vh;
    h2{
        color: #0563f6;
        font-size: 20px;
        font-weight: 500;
        border-bottom: 2px solid #25385833;
        margin: 5px 0 30px;
        padding-bottom: 10px;
        width: 100%;
        
    }
    .form-control{
        width:100%;
        border:2px solid #03a9f454;
        border-radius: 4px;
        background: transparent;
        .MuiInput-underline:before,.MuiInput-underline:after{
            border: transparent;
        }
        .MuiOutlinedInput-input {
            padding: 14.5px 14px;
        }
        .MuiInput-underline:hover:not(.Mui-disabled):before{
            border: transparent;
        }
        label + .MuiInput-formControl {
            margin-top: 0;
            padding: 4px;
        }
        .MuiInputLabel-shrink {
            top: -8px;
            background: #fbfbfb5c;
            left: 13px;
            z-index: 99;
            font-size: 15px;
        }
        .MuiInputLabel-formControl {
            top: -8px;
            left: 7px;
            font-size: 14px;
            color: #253858;
        }
        .MuiInputBase-input{
            padding: 8px 6px;
            font-size: 15px;
            font-weight: 500;
        }
        .MuiOutlinedInput-input {
            padding: 12.5px 14px;
            font-size: 12px;
            color: #253858;
            text-transform: capitalize;
          }
          .MuiInputLabel-outlined {
            top: -5px;
            font-size: 12px;
            color: #253858;
            opacity: 1;
            min-width: auto;
            text-transform: capitalize;
          }
          .MuiSelect-select.MuiSelect-select,
          .MuiSelect-select:focus {
            background-color: #fbfbfb5c;
          }
          .MuiInputLabel-outlined.MuiInputLabel-shrink {
            top: 1px;
            font-size: 14px;
            background-color: transparent;
          }
          .MuiSelect-select:focus {
            background-color: transparent;
          }
          .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: #0065ff;
            border-width: 1px;
          }
          .MuiSelect-selectMenu{
            padding: 8px;
            padding-right: 24px;
            font-size: 14px;
            color: #253858;
          }
    }
    button{
        &.go-btn{
            background-color: #2196f3;
            color: #fff;
            height: 44px;
            line-height: 38px;
            font-size: 16px;
            border-radius: 4px;
            min-width: 100px;
            font-weight: 500;
        }
    }
    .app-log-list{
        background-color: #deebff8f;
        padding: 10px;
        margin: 30px 0 10px;
        border-radius: 10px;
        // .app-log-list-head{
        //     ul{
        //         padding: 5px 5px 3px;
        //         background: #5abce936;
        //         margin-bottom: 10px;
        //         li{
        //            display: inline-block; 
        //            vertical-align: middle;
        //            padding:5px 8px;
        //            width:60%;
        //            text-transform: capitalize;
        //            &:nth-child(1),&:nth-child(2),&:nth-child(3)
        //            {
        //                width: 10%;
        //            }
        //            span{
        //             color: #253858;
        //             font-size: 12px;
        //             font-weight: 600;
        //            }
        //         }
        //     }
        // }
        .app-log-listing{
            height: 450px;
            overflow: scroll;
            padding: 0 15px 0 0;
            ul{
                background-color: #fff;
                padding: 5px;
                margin: 10px 0;
                vertical-align: middle;
                box-shadow: 3px 0px 6px #0000000f;
                text-transform: capitalize;
                border-radius: 15px;
                li{
                   display: inline-block; 
                   vertical-align: top;
                   padding:5px 0;
                   width:25%;
                //    &:nth-child(7),&:nth-child(8){
                //        width: 50%;
                //    }
                   span{
                    color: #253858;
                    font-size: 12px;
                    font-weight: 600;
                    margin-bottom: 5px;
                    padding: 2px 5px;
                    display: block;
                    padding: 10px 8px 7px;
                    border-bottom: 1px solid #ccc;
                   }
                   strong{
                    font-weight: 500;
                    font-size: 12px;
                    word-break: break-word;
                    display: block;
                    padding: 3px 8px 5px;
                   }
                }
                div{
                    li{
                        width:100%;
                        strong{
                            text-transform: initial;
                        }
                    }
                }
            }
        }
    }
}

@media all and (max-width: 768px){
    .app-logs {
        padding:10px;
        .app-log-list {
            // height: 356px;
            // overflow: scroll;
            padding:10px 10px 20px;
            .app-log-listing{
                padding: 0;
                ul{
                    margin:10px 0 20px;
                    li{
                        width:50%;
                    }
                }
            }
        }
    }
}
