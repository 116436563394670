// comm-mail-popup
.comm-mail-popup {
  .MuiDialogContent-root {
    padding: 0px 30px;
    width: 600px;
    padding: 0px 30px 20px;
    width: 700px;
    max-width: 100%;
    min-height: 150px;
    min-height: 180px;
    max-height: 410px;
  }

  .subject-box {
    background-color: #efefef59;
    padding: 10px 5px;
    border-bottom: 1px solid #ccc;
    position: relative;

    strong {
      font-size: 12px;
      font-weight: 500;
    }

    span {
      &.date-time {
        position: absolute;
        right: 6px;
        font-size: 10px;
        font-weight: 500;
        color: #253858;
        top: -14px;
      }
    }
  }

  .MuiDialogTitle-root {
    padding: 9px 18px;
    background-color: #f1f9ff;

    h6 {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .MuiDialogActions-root {
    padding: 9px 18px;
    background-color: #fbfbfb;
  }

  .send-to-insurer {
    text-align: left;
    font-size: 10px;
    margin: 0 0 3px;
    position: relative;
    top: 27px;
    left: -13px;

    span {
      font-size: 12px;
      padding: 0 4px;

      svg {
        width: 15px;
        height: 15px;
      }
    }

    .MuiRadio-colorSecondary.Mui-checked {
      color: #0065ff;
    }
  }

  #Mail-body {
    padding: 10px 5px;

    .gmail_attr {
      margin: 0 0 10px;
    }

    p {
      padding: 0 8px;
      margin-bottom: 10px;
    }

    tr {
      td {
        padding: 5px 8px;

        &:empty {
          display: none;
        }
      }
    }
  }

  button {
    cursor: pointer;

    &.submit-btn {
      color: #0065ff;
      background-color: transparent;
      border: 1px solid #0065ff;
      height: 30px;
      margin: 0 5px;
      line-height: 30px;
      font-size: 14px;
      padding: 5px 8px;
      font-weight: 500;
      text-transform: capitalize;
      border-radius: 4px;

      &:hover {
        background-color: transparent;
      }

      &.cancel {
        border: 1px solid #808080;
        color: #808080;
      }
    }

    &.submit-btn-confirm {
      color: #fff;
      border: 1px solid #0065ff;
      height: 30px;
      margin: 10px 0;
      line-height: 30px;
      font-size: 12px;
      padding: 5px 8px;
      font-weight: 500;
      text-transform: capitalize;
      border-radius: 4px;
      background: #0065ff;
      width: 147px;
    }
  }

  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .no-data-found {
    padding: 15px;
    margin: 0 auto;
    text-align: center;
  }

  .title-heading {
    position: relative;

    button {
      &.cancel-button {
        position: absolute;
        right: 0px;
        top: 50%;
        bottom: 50%;
        margin: auto;
      }

      &:focus {
        background-color: transparent;
      }

      &:hover {
        background-color: transparent;
      }
    }
  }
}

.generate-pdf-popup {
  .MuiDialog-paperWidthSm {
    position: relative;
    width: 700px;
    padding: 0px 30px;
    width: 600px;
    padding: 0px 1px 20px;
    width: 700px;
    max-width: 100%;
    height: 500px;

    .MuiDialogTitle-root {
      padding: 16px 8px;
      position: relative;
      background-color: #f1f9ff;

      .MuiTypography-h6 {
        font-size: 14px;
      }
    }

    button {
      &.cancel-button {
        color: #233859;
        position: absolute;
        margin: auto;
        padding: 0;
        right: 17px;
        top: 14px;
        min-width: 0;
        z-index: 99;
      }

      &:hover {
        background-color: transparent;
      }
    }
  }
  .mail-content{
	  background-color: #c5c5c5;
  }
  .MuiGrid-container{
	padding: 4px 9px;
    position: relative;
		.subject-box{
			position: relative;
			strong{
				line-height: 18px;
        font-size: 12px;
        font-weight: 400;
        width: 74%;
        display: block;
        position: relative;
        top: 0;
			}
			.date-time{
				position: absolute;
				right: -8px;
				font-size: 10px;
				top: 2px;
				font-weight: 400;
			}
		}
		.MuiFormControlLabel-root{
			margin: 0;
			padding: 0;
			// position: absolute;
			// left: -16px;
			// top: 3px;
		
			svg{
				font-size: 15px;
				margin:1px 0 0;
			}
		}
		.MuiRadio-colorSecondary.Mui-checked{
			color:#0065ff;
		}
		.MuiExpansionPanelSummary-root{
			padding:0;
			background-color: #f1f9ff;
			svg{
				&.arrow{
					color: #0065ff;
				}
			}
			.MuiExpansionPanelSummary-expandIcon{
				margin-right:2px;
			}
			.MuiExpansionPanelSummary-content.Mui-expanded{
				margin:10px 0;
			}
			
		}
		.MuiExpansionPanelSummary-root.Mui-expanded{
			min-height: 40px;
    }
    .MuiExpansionPanelSummary-root{
      min-height: 40px;
      &.error-msg {
        border: 1px solid #ff0000c7;
        border-radius: 5px;
        box-shadow: 3px 3px 3px #ff00009e;
      }
      &.remove-error-msg {
        border: none;
        border-radius: 0px;
        box-shadow: none;
      }
    }
	}
  #Mail-body {
    padding: 10px 5px;

    .gmail_attr {
      margin: 0 0 10px;
    }

    p {
      padding: 0 8px;
      margin-bottom: 10px;
    }

    tr {
      td {
        padding: 5px 8px;
        // &:empty{
        // 	display: none;
        // }
      }
	}
  }

  button {
    cursor: pointer;

    &.submit-btn {
      color: #0065ff;
      background-color: transparent;
      border: 1px solid #0065ff;
      height: 30px;
      margin: 0 5px;
      line-height: 30px;
      font-size: 14px;
      padding: 5px 8px;
      font-weight: 500;
      text-transform: capitalize;
      border-radius: 4px;

      &:hover {
        background-color: transparent;
      }

      &.cancel {
        border: 1px solid #808080;
        color: #808080;
      }
    }

    &.submit-btn-confirm {
      color: #fff;
      border: 1px solid #0065ff;
      height: 30px;
      margin: 10px 0;
      line-height: 30px;
      font-size: 12px;
      padding: 5px 8px;
      font-weight: 500;
      text-transform: capitalize;
      border-radius: 4px;
      background: #0065ff;
      width: 147px;
    }
  }

  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .no-data-found {
    padding: 15px;
    margin: 0 auto;
    text-align: center;
  }
  .MuiFormControlLabel-root{
    padding: 0 9px 0 3px;
      .MuiTypography-body1{
         font-size: 14px;
    }
  }
  button{
	  &.download-pdf{
		  border:1px solid #0065ff;
		  color:#0065ff;
		  font-size: 14px;
		  text-transform: capitalize;
		  margin: 14px 17px 0;
		  height: 30px;
	  }
  }
  .mail-inner-body{
    min-height: 350px;
    .timeline-data{
      text-align: left;
      padding:0;
      position: relative;
      min-height: 20px;
      width: 97%;
      margin: 0 auto;
      .select-checkbox{
        position: absolute;
        left: 0;
        top: 20px;
        display: inline-block;
        padding: 0 0 0 19px;
        em{
          position: relative;
          left:-25px;
        }
        .MuiCheckbox-root{
          width: 15px;
          position: relative;
          left: 0;
          top: 0;
          height: 0;
          .MuiSvgIcon-root{
            color:#0065ff;
            width:18px;
          }
        }
      }
      span{
        font-size: 12px !important;
        &.soucres{
          font-style: normal;
          display: block;
          left: 2px;
          top: 5px;
          padding-top: 10px;
          line-height: 20px;
          text-transform: capitalize;
          display: block;
          font-size: 14px !important;
          color: #253858;
        }
      }
    }
  }
  .include-pdf{
    text-align: left;
    padding:15px 0px 5px 0;
    position: relative;
    min-height: 20px;
    border-bottom: 1px solid #0065ff;
    width: 95%;
    margin: 0 auto;
    .checkbox-data{
      position: absolute;
      right: -18px;
      .MuiCheckbox-root{
        width: 15px;
        position: relative;
        left: 0;
        top: 0;
        height: 0;
        .MuiSvgIcon-root{
          color:#0065ff;
          width:18px;
        }
      }
    }
    span{
      font-size: 12px !important;
    }
  }
}

// pdf-generate-view
.pdf-generate-view{
  margin:10px 0 0;
	button{
		&.attach-btn{
			// background-color: #eaeaea;
			padding: 0;
      display: inline-block;
      min-width: 1px;
      margin: 0 5px;
			
			i{
				color: #0065ff;
        position: relative;
        top: 2px;
			}
			&:hover{
				background-color: transparent;
    		}
		}
  }
  .MuiExpansionPanel-rounded,.MuiExpansionPanel-rounded:last-child{
    margin-left: 12px;
    .subject-box{
      position: relative;
      .attach-btn{
        color:#0065ff;
        position: absolute;
        right: 117px;
        top:0;
        i{
          font-size: 16px;
        }
      }
    }
  }
	.MuiExpansionPanelDetails-root{
		padding:0;
	}
	.send-to-insurer{
		margin: 10px 0 0;
    border: 1px solid #ece9e9;
    display: block;
    border-radius: 0 0 4px 4px;
    position: relative;
    top: -14px;
    border-top: none;
    padding: 2px 0 4px;
    box-shadow: 0px 2px 1px -1px #ececec;
  }
  
  .check-icon{
    padding: 0px 5px 0 0;
    position: relative;
    top: -1px;
    .MuiIconButton-root{
      padding: 0;
    }
  }
}
