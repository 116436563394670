// create-new-ticket
.create-new-ticket {
  padding: 20px 15px;
  h6 {
    color: 253858;
  }
  .source-box {
    border: 1px solid #bdb9b9;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
    margin: 0 0 5px;
    &:hover {
      border: 1px solid #000;
    }
  }
  .select-box {
    margin: 0 0 10px;
  }
  .MuiFormControl-marginNormal {
    margin-top: 10px;
  }
  .MuiOutlinedInput-input {
    padding: 12.5px 14px;
    font-size: 12px;
    color: #253858;
    // text-transform: capitalize;
  }
  .MuiInputLabel-outlined {
    top: -5px;
    font-size: 12px;
    color: #253858;
    opacity: 1;
    min-width: auto;
    text-transform: capitalize;
  }
  .MuiSelect-select.MuiSelect-select,
  .MuiSelect-select:focus {
    background-color: #fff;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    top: 1px;
    font-size: 14px;
    background-color: #fff;
  }
  .MuiSelect-select:focus {
    background-color: transparent;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $blueM;
    border-width: 1px;
  }
  button {
    // border-radius: 8px;
    // min-width: 120px;
    // text-align: center;
    // font-size: 12px;
    // height: 37px;
    // margin: 10px 8px;
    // text-transform: capitalize;
    &.fetch-details {
      color: #fff;
      background: #408bff;
      min-width: 40px;
      padding: 0 5px;
      height: 30px;
      text-align: center;
      font-size: 12px;
      svg {
        font-size: 22px;
        color: #fff;
      }
    }
    &.remove-doc-btn {
      color: #eb3b32;
      border: 1px solid #eb3b32;
      min-width: 90px;
      margin: 10px 3px;
      padding: 5px 0;
    }
    &.submit-btn {
      background-color: #408bff;
      color: #fff;
      font-size: 14px;
      text-align: center;
      min-width: 95%;
      margin: 0 auto;
      display: block;
      line-height: 20px;
      height: 45px;
      .MuiCircularProgress-colorPrimary {
        color: #fff;
        margin: auto;
      }
    }
  }
  .attach-box {
    position: relative !important;
    margin: 10px 0;
    .upload-btn {
      position: relative;
      z-index: 0;
      display: inline-block;
      cursor: pointer;
      padding: 4px 13px;
      border: 2px solid #408bff;
      min-width: auto;
      svg {
        color: #408bff;
      }
    }
    .upload {
      display: inline-block;
      position: absolute;
      z-index: 1;
      width: 69px;
      height: 37px;
      top: -21px;
      left: 7px;
      opacity: 0;
      cursor: pointer;
    }
  }
  textarea {
    min-width: 94%;
    margin: 0;
    display: block;
    resize: none;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    color: #808080;
    min-height: 60px;
    padding: 15px 10px;
  }
  .uplaod-doc-view {
    position: relative;
    padding: 10px 10px;
    .MuiLinearProgress-barColorPrimary {
      background-color: #4ace45;
      border-radius: 8px;
    }
    span {
      font-size: 12px;
      color: #253858;
      line-height: 20px;
    }
    .file-detail {
      display: inline-block;
      position: relative;
      font-size: 11px;
      height: 30px;
      padding: 0 5px 0 0;
      vertical-align: middle;
      width: auto;
      button {
        font-size: 11px;
        min-width: 15px;
        background-color: #808080;
        color: #fff;
        position: relative;
        right: 0px;
        top: 0;
        text-align: center;
        display: inline-block;
        padding: 0 !important;
        height: 15px;
        line-height: 0;
        svg {
          vertical-align: middle;
          font-size: 11px;
        }
      }
    }
  }
  .spinner-loader {
    position: relative;
    left: 48%;
    right: 0;
    margin: 27px auto 0;
    width: 100%;
    top: 0;
    svg {
      color: $blueM;
    }
  }
  .spinner-btn {
    svg {
      color: #fff;
    }
  }
}
.comment-submit-data {
  display: table;
  margin: 10px 8px 15px !important;
  width: 95%;
  border: 1px solid #99d6e8;
  border-radius: 5px;
  li {
    display: table-cell;
    vertical-align: middle;
    width: 34%;
    padding: 0;
    &:nth-child(1) {
      width: 64%;
    }
    textarea {
      min-height: 10px;
      resize: none;
      border: transparent;
      padding: 10px;
    }
    &.attach-submit {
      text-align: right;
      i {
        font-size: 16px;
        margin: 0px;
        transform: rotate(-90deg);
        vertical-align: middle;
      }
      button {
        color: #0065ff;
        min-width: 60px;
        font-size: 10px;
        padding: 0 5px 0 0;
        background-color: transparent;
      }
    }
  }
}

// confimation-box
.confimation-box {
  .title-heading {
    padding: 0 10px;
    h6 {
      font-size: 18px;
    }
  }
  .MuiDialogContent-root {
    padding: 20px 10px;
  }
  .Component-closeButton-70 {
    color: #253858;
    top: 0;
    right: 0;
    &:hover {
      background-color: transparent;
    }
  }
  button {
    &.continue-btn {
      color: #fff;
      border: 1px solid #0065ff;
      background-color: #0065ff;
      font-size: 12px;
      min-width: 50px;
      margin: 10px 0 10px 10px;
      height: 30px;
      border-radius: 5px;
    }
    &.cancel-btn {
      color: #808080;
      border: 1px solid #808080;
      font-size: 12px;
      min-width: 50px;
      margin: 10px 0 10px 10px;
      height: 30px;
      border-radius: 5px;
    }
  }
}

.scrool-toll::-webkit-scrollbar-track {
  box-shadow: transparent;
  background-color: #fff;
}

.scrool-toll::-webkit-scrollbar {
  width: 4px;
  background-color: #d8d8d8;
  height: 1px;
  border: 3px;
}

.scrool-toll::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
}

// name-file
.name-file {
  position: relative;
  left: 10px;
  font-size: 12px;
  line-height: 16px;
  top: 0px;
  padding: 0;
  button {
    position: relative;
    top: 0px;
    background: #898787;
    color: #fff;
    border-radius: 20px;
    height: 14px;
    width: 14px;
    line-height: 0;
    vertical-align: sub;
    font-size: 11px;
    margin: 0 3px;
    &:hover {
      background: #ccc !important;
    }
    svg {
      font-size: 12px;
    }
  }
}
// spinner-block
.spinner-block {
  text-align: center;
  width: 100%;
  > div {
    display: block;
    svg {
      color: #0065ff;
    }
  }
}

// read-more-button
.read-more-button {
  text-align: center;
  width: 99%;
  margin: 10px auto;
  button {
    color: #408bff;
    font-weight: 500;
    min-width: 107px;
    span {
      font-size: 12px;
      font-weight: 500;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}

.downlaod-item {
  border: 1px solid #eaeaea;
  position: relative;
  padding: 10px 0;
  min-width: 100px;
  display: inline-block;
  border-radius: 8px;
  height: auto;
  vertical-align: middle;
  margin: 10px 8px 5px 0;
  width: 140px;
  // box-shadow: 0px 0px 3px 1px #3469cb29;
  .file-extn {
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: block;
    height: 20px;
    img {
      width: 25px;
      margin: 0px 0 0;
      height: auto;
    }
  }
  p {
    padding: 13px 0 0 45px;
    color: #303030;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    span {
      &.doc-type {
        font-size: 12px;
        color: $lightG;
        display: block;
        font-weight: 400;
      }
    }
  }
}

.view-attachements {
  font-size: 12px;
  padding: 5px 0 0;
  > strong {
    font-weight: 700;
    color: #808080;
  }
  span {
    font-weight: 400;
    color: #0065ff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 2px 4px;
    margin-right: 4px;
    display: inline-block;
    margin-bottom: 4px;
  }
}
.attach-box {
  h5 {
    position: relative;
    font-size: 12px;
    font-weight: 700;
    color: #253858;
    padding: 5px 0 0;
    margin: 0;
    button {
      cursor: pointer;
      min-width: auto;
      &.download-btn {
        color: #253858;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
  .downlaod-item {
    border: 1px solid #eaeaea;
    position: relative;
    padding: 10px 0;
    width: 148px;
    display: inline-block;
    border-radius: 8px;
    height: auto;
    vertical-align: middle;
    margin: 10px 8px 5px 0;
    .file-extn {
      position: absolute;
      left: 10px;
      top: 0px;
    }
    p {
      padding: 5px 0 0 40px;
      color: #303030;
      font-size: 11px;
      font-weight: 400;
      line-height: 14px;
      white-space: break-spaces;
      word-break: break-all;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100px;
    }
  }
}

button {
  &.pdf-btn {
    color: #fff;
    font-size: 12px;
    min-width: auto;
    padding: 2px 0;
    img {
      width: 28px;
      height: 100%;
    }
    &:hover {
      background-color: transparent;
    }
  }
}
.no-data-found {
  text-align: center;
  margin: 20px 0;
}
// call-widget
.call-widget {
  background-color: #fff;
  border-radius: 0 32px 32px 0;
  padding: 5px 3px;
  position: relative;
  top: 0;
  left: -20px;
  box-shadow: 0px 0px 16px #00000014;
  width: 260px;
  border: 1px solid #aec7ed;
  margin:10px 0;
  .MuiPaper-rounded {
    border-radius: 100px;
  }
  .MuiPaper-root {
    // background-color: #DEEBFF;
    background-color: #fff;
    color: #fff;
    width: 365px;
    max-width: 90%;
    padding: 0 0 10px;
  }
  .MuiDialogContent-root {
    padding: 8px 0 8px 26px;
  }
  ul {
    li {
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      width: 18%;
      border-radius: 0;
      background-color: transparent;
      margin: 0;
      box-shadow: none;
      label + .MuiInput-formControl{
        margin: 0;
      }
      &:nth-child(2) {
        button {
          background-image: none;
        }
      }
      &.select-phone-number {
        width: 78%;
        text-align: left;
        
      }
      .form-control {
        width: 100%;
        border: 1px solid #0052cc;
        border-radius: 4px;
        height: 35px;
        .MuiSelect-icon {
          top: calc(50% - 12px);
        }
        .MuiFormControl-marginNormal {
          margin: 0;
          padding: 8px;
        }
        label  .MuiInput-formControl {
          margin-top: 0;
          padding: 0 6px;
        }

        .MuiInputLabel-animated {
          top: -13px;
          left: 9px;
          background: #fff;
          color: #7a869a;
          font-size: 13px;
        }
        .MuiInputLabel-shrink {
          top: -8px;
          left: 11px;
          background: #fff;
          color: #0052cc;
          font-size: 14px;
        }
        .MuiInput-underline:before,
        .MuiInput-underline:after {
          opacity: 0;
        }
        input:-internal-autofill-selected {
          background-color: #fff !important;
          -webkit-box-shadow: 0 0 0px 1000px white inset;
        }
        .MuiMenuItem-root {
          font-size: 12px;
        }
        .MuiOutlinedInput-root {
          font-size: 12px;
        }
        .MuiSelect-select.MuiSelect-select {
          padding: 8px 6px;
          background-color: transparent;
          border-radius: 4px;
          font-size: 12px;
          color: #253858;
        }
      }
      button {
        &.calling-btn {
          padding: 0;
          min-width: auto;
          img {
            max-width: 26px;
            margin: -1px 0 0;
          }
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}

.MuiPopover-root {
  ul {
    li {
      font-size: 11px;
      font-family: "robotoregular";
      font-weight: 300;
      color: #716b6b;
    }
  }
}

// version-name
.version-name {
  background-color: #3f85bc63;
  border-radius: 0 0 15px 15px;
  padding: 10px;
  color: #253858;
  text-align: center;
  margin: 0;
  font-size: 12px;
}
// chat-history
.chat-history {
  position: relative;
  .chat-view {
    padding: 0 0 20px;
    // background-color: #dcf6e2;
    background-color: #fff;
    h5 {
      position: relative;
      font-size: 14px;
      text-align: center;
      font-weight: 500;
      color: #408bff;
      padding: 15px 0 10px;
      margin-bottom: 0;
      background-color: #fff;
      button {
        &.close-btn {
          position: absolute;
          left: 4px;
          top: 5px;
          min-width: 0;
          svg {
            width: 24px;
            height: auto;
            padding: 0px;
            color: #3f51b5;
            border-radius: 5px;
            border: 1px solid #3f51b5;
          }
        }
        &:hover {
          background-color: transparent;
        }
      }
    }
    .chating-list {
      max-height: 600px;
      min-width: 320px;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 10px 20px 10px;
      ul {
        position: relative;
        .chat-timing {
          border-bottom: 1px solid #cdcdcd3d;
          height: 10px;
          position: relative;
          margin: 10px 0;
          span {
            &.date-time {
              position: absolute;
              left: 0;
              right: 0;
              display: block;
              width: 76px;
              text-align: center;
              margin: auto;
              background: #deebff;
              top: -5px;
              text-transform: capitalize;
              padding: 8px 0;
              border-radius: 5px;
              color: #253558;
              font-size: 10px;
            }
          }
        }
        li {
          min-width: 30px;
          list-style: none;
          margin-bottom: 15px;
          position: relative;
          max-width: 80%;
          clear: both;
          div {
            &.chat {
              position: relative;
            }
            &.chat-txt {
              box-shadow: 3px 4px 5px #3d3e3e66;
              h2 {
                font-size: 12px;
                font-weight: 700;
                margin: 5px 0;
                color: #253858;
                position: relative;
                span {
                  &.msg-time {
                    right: 0;
                    position: absolute;
                    width: auto;
                    top: -28px;
                    font-size: 10px;
                    background: #ececec;
                    padding: 3px 5px;
                    border-radius: 5px 6px 0px 3px;
                  }
                }
              }
            }
          }
          &.left-chat {
            float: left;
            margin-top: 20px;
            .chat {
              span {
                &.user-name {
                  position: absolute;
                  left: 4px;
                  background: #ffcccc;
                  padding: 9px 8px 7px;
                  border-radius: 26px;
                  width: 16px;
                  top: 25%;
                  height: 16px;
                  margin: auto;
                  bottom: 25%;
                  color: #fff;
                  text-align: center;
                }
              }
              .chat-txt {
                padding: 6px 15px;
                background-color: #f4f4f4;
                margin: 0 0 0 42px;
                border-radius: 10px;
                position: relative;
              }
              span {
                &.msg-time {
                  position: absolute;
                  bottom: -17px;
                  left: 6px;
                  font-size: 10px;
                }
              }
            }
          }
          &.right-chat {
            float: right;
            margin-top: 20px;
            .chat {
              position: relative;
              span {
                &.user-name {
                  position: absolute;
                  right: 0px;
                  background: #85c1e9;
                  padding: 10px 8px 7px;
                  border-radius: 26px;
                  width: 16px;
                  top: 25%;
                  height: 16px;
                  margin: auto;
                  bottom: 25%;
                  color: #fff;
                  text-align: center;
                }
              }
              .chat-txt {
                background: #e4f3fd;
                padding: 6px 15px;
                margin: 0 42px 0 0;
                border-radius: 10px;
                position: relative;
                span {
                  &.msg-time {
                    position: absolute;
                    bottom: -17px;
                    right: 6px;
                    font-size: 10px;
                  }
                }
              }
              // span{
              //   &.msg-time{
              //     right:0;
              //   }
              // }
            }
          }
        }
        .clr {
          clear: both;
        }
      }
    }
    .txtarea-box {
      position: absolute;
      bottom: -3px;
      width: 95%;
      margin: auto;
      left: 0;
      right: 0;
      background: #eaeaea;
      padding: 12px 15px;
      textarea {
        background-color: #fff;
        color: #253858;
        font-size: 12px;
        padding: 8px;
        resize: none;
        width: 96%;
        border: 1px solid #ccc;
        border-radius: 3px;
        height: 59px !important;
        box-shadow: 3px 4px 5px #3d3e3e66;
      }
    }
  }
  .MuiDialog-container {
    padding-left: 25%;
  }
}
button {
  &.chat-btn-history {
    background: #b0cffa;
    box-shadow: 3px 4px 5px -1px #3d3e3e66;
    color: #253858;
    border-radius: 14px;
    min-width: 20px;
    height: 20px;
    font-size: 16px;
    padding: 8px;
    border: none;
    margin: 0 5px;
    &:hover {
      background-color: #b0cffa !important;
    }
  }
}
.view-comments {
  text-align: left;
  padding: 0 10px;
  position: absolute;
  right: 0;
  button {
    cursor: pointer;
    padding: 5px;
    &.comment-btn {
      color: $whiteM;
      font-size: 12px;
      cursor: pointer;
      padding: 0 5px 0 10px;
      border: transparent;
      text-transform: capitalize;
      top: 34%;
      height: 45px;
      line-height: 40px;
      background: #0065ffb5;
      border-radius: 18px 0 0 18px;
      box-shadow: 0px 0px 16px #00000014;
      width: 163px;
      transition: left 0.8s ease-in-out;
      -o-transition: left 0.8s ease-in-out;
      -ms-transition: left 0.8s ease-in-out;
      -moz-transition: left 0.8s ease-in-out;
      -webkit-transition: left 0.8s ease-in-out;
      position: fixed;
      right: -120px;
      z-index: 88;
      .comment-icon {
        margin: 0 15px 0 0;
      }
      &:hover {
        background: #0065ff;
        right: 0;
        .comment-icon {
          margin: 0 5px 0 0;
        }
      }
    }
    &:hover {
      background-color: transparent;
    }
    i {
      color: #0065ff;
      font-size: 16px;
      padding: 0 0 0 5px;
    }
  }
  button.remove-file-icon {
    padding: 0;
  }
}
.ticket-timeline {
  .ticket-details {
    padding: 0px 5px 0;
  }
}
.error-boundary-msg {
  text-align: center;
  background-color: #fff;
  padding: 13% 20px 10%;
  font-size: 22px;
  svg {
    vertical-align: middle;
  }
}

.child-error-msg {
  text-align: left;
  padding: 7% 17%;
  font-size: 22px;
  border: 1px solid #eee;
  width: auto;
  margin: 59px 14px 0;
  font-size: 16px;
  background: #ebf3fe;
  p {
    line-height: 30px;
  }
  svg {
    vertical-align: middle;
  }
}

// resolvedQA-dailog
.resolved-dailog {
  text-align: center;
  .MuiPaper-root {
    background-color: #fff;
    h2 {
      font-size: 28px;
      color: #303030;
      margin: 10px 0;
    }
    .MuiTypography-colorTextSecondary {
      color: #253858;
      font-size: 18px;
    }
    svg {
      font-size: 32px;
      color: #ff9800;
      vertical-align: middle;
    }
  }
}
// scroll-btn
.scroll-btn::-webkit-scrollbar-track {
  box-shadow: transparent;
  background-color: #fff;
}

.scroll-btn::-webkit-scrollbar {
  width: 4px;
  background-color: #808080;
  height: 10px;
  border: 1px;
}

.scroll-btn::-webkit-scrollbar-thumb {
  background-color: #808080;
}

// scroll-btn
.scroll-btn::-webkit-scrollbar-track {
  box-shadow: transparent;
  background-color: #fff;
}

.scroll-btn::-webkit-scrollbar {
  width: 4px;
  background-color: #808080;
  height: 10px;
  border: 1px;
}

.scroll-btn::-webkit-scrollbar-thumb {
  background-color: #808080;
}
.dialog-zero{
  .MuiDialog-container {
    padding-left: 0;
  }
}
.dialog-20{
  .MuiDialog-container {
    padding-left: 80%;
  }
}
.dialog-30{
  .MuiDialog-container {
    padding-left: 70%;
  }
}
.dialog-40{
  .MuiDialog-container {
    padding-left: 60%;
  }
}
.dialog-50{
  .MuiDialog-container {
    padding-left: 50%;
  }
}
.dialog-60{
  .MuiDialog-container {
    padding-left: 40%;
  }
}
.dialog-70{
  .MuiDialog-container {
    padding-left: 30%;
  }
}
.dialog-80{
  .MuiDialog-container {
    padding-left: 20%;
  }
}
.dialog-zero,.dialog-20,.dialog-30,.dialog-40,.dialog-50,.dialog-60,.dialog-70,.dialog-80{
  .MuiDialog-container {
    iframe:empty{
      width: 100% !important;
    }
    @media (max-width: 768px){
      padding-left: 0%;
    }
  }
}
.logout-box{
  .MuiDialog-container {
    .MuiDialog-paperScrollPaper{
      width:450px;
      h2{
        font-size: 18px;
        font-weight: 600;
        color: #253858;
      }
      button{
        text-transform: capitalize;
        border-radius: 5px;
        &.cancel-btn{
          color:#808080;
          background-color: #e8e5e5;
          height: 32px;
          min-width: 80px;
        }
        &.submit-btn{
          color:#fff;
          background-color: #0065ff;
          height: 32px;
          min-width: 80px;
        }
      }
    }
  }
}
// buttons-rendering
.buttons-rendering{
  text-align: right;
  margin: 10px 0 0;
  button{
    color:#0065ff;
    font-size: 14px;
    font-weight: 400;
    height: 32px;
    text-transform: capitalize;
   
    &.clear-all{
      color:#253858;
      border:1px solid #253858;
      margin:0 0 0 8px;
    }
    &.cancel-btn{
      color:#808080;
      border:1px solid #808080;
      margin:0 0 0 8px;
    }
    &.save-btn{
      color:#0065ff;
      border:1px solid #0065ff;
      margin:0 0 0 8px;
    }
    &.remove-file-icon{
      z-index: 9999;
      position: relative;
      top: -1px;
      right: 1px;
      svg{
        font-size: 20px;
      }
    }
  }
  .action-btn-box{
    .file-upload{
      text-align: left;
        padding: 9px 24px 10px 23px;
    }
  }
}

// customer-pendecy-editor
.customer-pendency-editor{
  width: 420px;
  textarea{
    width: 96%;
    resize: none;
    font-family: "robotoregular";
    min-height: 100px;
    padding: 8px;
    border:1px solid #ccc; 
    border-radius: 5px;
  }
  .action-btn-box{
    position: relative;
    .attach-box {
      top: 1px;
      left: -1px;
      .upload-btn{
        padding: 10px 0;
        margin: 0 0 0 5px;
      }
    }
    .file-upload{
      text-align: left;
      // padding: 0 0px 10px 23px;
      // margin: 0 0 15px;
    }
    .name-file{
      display: inline-block;
      font-size: 10px;
      line-height: 15px;
      width: auto;
      height: 18px;
      padding: 4px 10px 3px;
      vertical-align: super;
      background: #cfcfcf7d;
      border-radius: 20px;
      margin: 5px 3px 0;
      text-transform: lowercase;
      position: relative;
      top: -4px;
      z-index: 0;
      button{
        background-color: transparent;
        &:hover{
          background-color: transparent !important;
        }
      }
    }
  }
}

// customer-pendency-logs
.customer-pendency-logs{
  padding: 15px 10px 10px;
  width: 97%;
  margin: 15px auto 0;
  // border-radius: 10px;
  // background-color: #ddebff;
  ul{
    &.pendency-log-list{
      display: flex;
      flex-direction: column;
      width: 100%;
    li{
      width: auto;
      border:1px solid #ccc;
      background-color: #a0b0c633;
      padding: 10px 10px 10px 15px;
      border-radius: 10px;
      margin-bottom: 10px;
      position: relative;
      // box-shadow: 0px 4px 3px #00000024;
      // &:nth-child(even){
      //   background-color: #ceb9b954;
      // }
      // &::before{
      //   content: "";
      //   background-color: #2196f3;
      //   top: 0;
      //   left: 0;
      //   width: 5px;
      //   position: absolute;
      //   height: 98%;
      //   border-radius: 24px 0 0 24px;
      //   margin:auto;
      //   bottom: 0;
      // }
      &:hover,&:focus{
         border:1px solid #0065ff;
         cursor: pointer;
        // position: relative;
        // &::before{
        //   content: "";
        //   background-color: #0065ff;
        //   top: 10%;
        //   left: -19px;
        //   width: 10px;
        //   position: absolute;
        //   height: 10px;
        //   border-radius: 50%;
        // }
      }
      h5{
        position: relative;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 7px;
        span{
          position: absolute;
          right: 0;
          font-size: 11px;
          font-weight: 400;
          // background-color: #2877ef;
          padding: 0 5px;
          border-radius: 4px;
          color: #253858;
        }
      }
      p{
        font-size: 12px;
        color:#253558;
        line-height: 18px;
        &:empty{
          display: none;
        }
      }
      .attachment-outer-box{
        margin: 10px 0;
        span{
          border:1px solid #6fa4cf;
          padding: 5px;
          font-size: 12px;
          border-radius: 5px;
          margin:0 5px 0 0;
        }
      }
      ul,ol{
        li{
          display: block;
          background-color: transparent;
          border:none;
          line-height: 18px;
          padding: 0;
          margin:0 0 4px;
          &::before{
            display: none;
          }
          &:hover,&:focus{
            border:none;
          }
        }
      }
      ul{
        li{
          list-style-type: square;
        }
      }
      ol{
        li{
          list-style-type:decimal;
          display: list-item;
          list-style-position: inside;
        }
      }

    }
    .loader-spinner{
      margin: auto;
      > div{
        display: block;
      }
    }
    }
  }
  button{
    &.view-logs-btn{
      color:#0065ff;
      font-size: 14px;
      margin-bottom: 15px;
      text-transform: capitalize;
      &:hover{
        background-color: transparent;
      }
    }
  }
}

.wecare-endosrement-dialog{
  padding:15px;
  .MuiDialog-container {
    padding-left: 20%;
    @media (max-width: 768px){
      padding-left: 0%;
    }
  }
  button{
    padding:0;
    margin: 0 8px 0 0;
    span{
      font-size: 12px;
    }
  }
  h6{
    font-size: 16px;
  }
}

// insert-ticket-issue
.insert-ticket-issue{
    min-height: 400px;
    padding: 20px 20px;
    .MuiBox-root{
      min-height: 70vh;
      padding: 15px;
      background-color: #ddebff26;
    }
    h3{
      font-size: 18px;
      padding-bottom: 10px;
      font-weight:600;
      margin:0;
    }
    button{
      &.download-sample-btn{
        background-color: #0165ff;
        color: #fff;
        margin:0;
        text-transform: capitalize;
        border: 1px solid #0165ff;
        height: 41px;
        margin:2px 0;
        min-width: 100px;
      }
      &.selected-btn{
        background-color: #2eb529;
        border:1px solid #2eb529;
      }
    }
    a{
      &.download-sample-btn{
        background-color: #0165ff;
        color: #fff;
        margin:0;
        text-transform: capitalize;
        border: 1px solid #0165ff;
        height: 41px;
        margin:2px 0;
        min-width: 188px;
        display: inline-block;
        line-height: 41px;
        text-align: center;
        border-radius: 6px;
        &:hover{
          text-decoration: none;
        }
      }
    }
    .MuiTab-textColorInherit{
      font-size: 16px;
      text-transform: capitalize;
    }
    .MuiTab-textColorInherit.Mui-selected{
      font-weight: 500;
      font-size: 16px;
    }
    .form-control {
      width: 100%;
      border-radius: 4px;
      height: 40px;
      .MuiSelect-icon {
        top: calc(50% - 12px);
      }
      .MuiFormControl-marginNormal {
        margin: 0;
        padding: 6px;
      }
     label + .MuiInput-formControl {
       margin-top: 0;
       padding: 0 6px;
     }
  
     .MuiInputLabel-animated {
       top: -5px;
       left: -2px;
       background: #f4f6f9;
       color: #7a869a;
       font-size: 13px;
     }
     .MuiInputLabel-shrink {
       top: 2px;
       left: -2px;
       background: #f4f6f9;
       color: #0052cc;
       font-size: 14px;
     }
     .MuiInput-underline:before,
     .MuiInput-underline:after {
       opacity: 0;
     }
     input:-internal-autofill-selected {
       background-color: #fff !important;
       -webkit-box-shadow: 0 0 0px 1000px white inset;
     }
     .MuiMenuItem-root {
       font-size: 12px;
     }
     .MuiOutlinedInput-root {
       font-size: 12px;
     }
     .MuiSelect-select.MuiSelect-select {
       padding: 8px 10px;
       background-color: transparent;
       border-radius: 4px;
       font-size: 12px;
       color: #253858;
       height: 27px;
     }
     &.autocomplete{
       .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
         padding:4px;
       }
       .MuiFormControl-marginNormal{
         padding:2px;
       }
       .MuiInputLabel-shrink{
         top: -22px;
         font-size: 11px;
         color: #0052cc;
         left: 1px;
       }
       .MuiInputLabel-animated{
         top: -22px;
         font-size: 11px;
         color: #0052cc;
         left: 1px;
       }
     }
   }
   .upload-btn{
     background-color: #0065ff;
     min-width: 100px;
     border-radius: 5px;
     text-align: right;
     color: #fff;
     position: relative;
     margin: 10px 0;
     height: 36px;
     line-height: 22px;
     min-width: 110px;
     &::before{
       position: absolute;
       content: "Upload file";
       color: #fff;
       left: 10px;
       font-size: 12px;
       margin: auto;
       top: 0;
       bottom: 0;
       height: 20px;
     }
     svg{
       font-size: 20px;
     }
   }
   .uplaod-doc-view{
     vertical-align: middle;
     display: block;
     position: absolute;
     left: 116px;
     top:17px;
     .file-detail{
       font-size: 12px;
       background: #ddebff;
       border-radius: 22px;
       height: 30px;
       display: inline-block;
       line-height: 28px;
       position: relative;
       top: -5px;
       padding: 0 0 0 10px;
       margin: 0 0 0 7px;
       svg{
         font-size: 12px;
         background: #808080;
         border-radius: 50%;
         padding: 2px;
         color: #fff;
         margin: -6px 3px 0px 0;
         height: 10px;
         width: 10px;
         top: 4px;
         position: relative;
         right: -1px;
       }
     }
    }
   }
// customer-pendency-reason-editor
.customer-pendency-reason-editor{
  font-family: "robotoregular";
  .MuiDialogTitle-root{
    padding: 10px 20px 0;
  }
  .MuiDialogContent-root{
    padding: 0 20px 0;
  }
  .MuiFormControlLabel-root{
    padding: 0;
    margin: 0 0 8px;
    position: relative;
    left: -9px;
  }
  .cke_editable{
    font-family: "robotoregular";
  }
  h2{
    font-size: 22px;
  }
}

.customer-pendency-editor{
  padding-bottom: 20px;
  .cke_editable{
    padding: 0 !important;
  }
}

// one-lead-data
.one-lead-data{
  padding: 20px;
  .search-box{
    text-align: center;
  }
  h4{
    padding: 10px 18px;
    font-size: 16px;
    background: #ddebff;
  }
  .skip-data{
    border: 1px solid #ccc;
    margin: 30px 0 0;
    background: #fff;
    ul{
      padding: 10px;
      li{
        display: inline-block;
        margin: 0 10px 0 0;
        width:15%;
        @media (max-width: 768px){
          width: 46%;
        }
        .MuiOutlinedInput-input{
          padding:14px;
          font-size: 12px;
        }
        input:-internal-autofill-selected{
          background-color: #fff !important;
        }
        .MuiInputLabel-outlined.MuiInputLabel-shrink{
          transform:translate(14px, 0px) scale(0.95);
        }
        .MuiInputLabel-formControl{
          top: -4px;
          font-size: 12px;
        }
       }
      > div {
        button{
          background-color: #0065ff;
          color:#fff;
          text-transform: capitalize;
          margin:10px 0 0;
          min-width: 80px;
        }
      }
    }
  }
  .form-control{
    width:100%;
    &.select-box{
      .MuiInputLabel-outlined.MuiInputLabel-shrink{
        transform: translate(12px, 0px) scale(0.95);
        background: #f8f8f8;
        color: #808080;
        input{
          color:#253558;
        }
      }
      .MuiInputLabel-formControl{
        top: 11px;
        font-size: 12px;
      }
      .MuiSelect-outlined.MuiSelect-outlined{
        color:#253558;
      }
    }
    &.calander-control{
      width: 100%;
      border: 1px solid #d5d5d5;
      border-radius: 4px;
      height: 41px;
      position: relative;
      margin-top: 15px;
  
      .MuiInput-underline:before,
      .MuiInput-underline:after {
        display: none;
      }
      p{
        .error-msg {
          color: #f44336;
          position: absolute;
          bottom: -22px;
          left: 1px;
          font-size: 11px;
        }
      }
      label + .MuiInput-formControl {
        margin-top: 4px;
      }
      .MuiInputLabel-formControl{
        left: 10px;
        top: -6px;
        font-size: 14px;
        background: #f8f8f8;
      }
      .MuiInputBase-input{
        padding:8px;
        font-size: 14px;
        color: #253858;
        font-weight: 500;
      }
      .MuiFormControl-marginNormal{
        margin:0;
      }
    }
  }
}


// pendency-logs-view
.pendency-logs-view{
  margin: 0 auto 2%;
  width: 98.5%;
  border-radius: 10px;
  .head-list{
    ul{
      background-color: #deebff;
      border-radius: 10px 10px 0 0;
      box-shadow: 1px 0px 5px 1px #00000029;
      padding: 0 5px;
    }
  }
  
  ul{
    display: block;
    li{
      border:none;
      vertical-align: middle;
      background-color:transparent;
      padding: 0;
      border-radius: 0;
      margin-bottom: 10px;
      position: relative;
      span{
        color: #4a5a74;
        font-weight: 700;
        font-size: 12px;
        line-height: 12px;
      }
      strong{
        color: #4a5a74;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        word-break: break-word;
      }
    }
    
  }
  .body-list{
    padding: 10px;
    ul{
      background-color:#a0b0c633;
      border-radius:10px;
      margin: 10px 0;
      padding: 10px 10px 10px 15px;
      border:1px solid #ccc;
      &:hover,&:focus{
        border:1px solid #0065ff;
        cursor: pointer;
      }
      h5{
        position: relative;
        font-size: 14px;
        font-weight: 600;
        span{
          &.raised-on{
            position: absolute;
            right: 0;
            font-size: 11px;
            font-weight: 400;
            padding: 0 5px;
            border-radius: 4px;
            color: #253858;
          }
        }
      }
      p{
        font-size: 12px;
        color: #253558;
        font-weight: 500;
        margin-bottom: 2px;
        line-height: 18px;
        strong{
          font-weight: 600;
        }
      }
      ul{
        box-shadow: none;
        padding: 0;
        background-color: transparent;
        box-shadow: none;
        border: none;
        margin: 4px 0;
        &::before{
          display: none;
        }
        &:hover,&:focus{
          border:none;
          cursor: pointer;
        }
        li{
          display:block;
          width: 100%;
          padding:0;
          margin-bottom: 0;
        }
      }
    }
  }
}

// compose-btn
.compose-btn{
  background-color: #0065ff;
  color:#fff;
}
