$whiteM: #ffffff;
$blackB: #253858;
$blackM: #303030;
$blueM: #0065ff;

.all-ticket-list {
  .ticket-details {
    padding: 60px 5px 0;
    background: $whiteM;

    h5 {
      font-size: 18px;
      color: $blackB;
      position: relative;
      min-width: 100px;
      display: inline-block;
      margin: 0;
      font-weight: 500;
      padding: 0 5px;

      &::before {
        content: "";
        border-bottom: 2px solid $blackB;
        position: absolute;
        left: 8px;
        bottom: -2px;
        height: 10px;
        padding: 5px;
        color: transparent;
        width: 20%;
      }

      span {
        color: #0065ff;
        font-weight: 600;

        &::before {
          content: "-";
        }
      }
    }

    ul {
      margin: 10px 5px 0;
      position: relative;
      li {
        display: inline-block;
        width: 33.3%;
        vertical-align: top;
        margin: 5px 0;
        padding: 0 5px;
        position: relative;

        .MuiFormControl-marginNormal {
          margin: 0;
        }

        .MuiOutlinedInput-input {
          padding: 12.5px 14px;
          font-size: 12px;
          color: #253858;
          text-transform: capitalize;
        }

        .MuiInputLabel-outlined {
          top: -5px;
          font-size: 12px;
          color: #253858;
          opacity: 1;
          min-width: auto;
          text-transform: capitalize;
          background-color: #fff;
        }

        .error-msg {
          color: #f44336;
        }

        .classification-box {
          position: relative;
          margin: 2px 0;
          width: 93%;
          border: 1px solid #ddd;
          height: 33px;
          padding: 4px 8px;
          border-radius: 4px;

          label {
            position: absolute;
            top: -7px;
            background-color: #fff;
            height: 20px;
            padding: 0 2px;
            font-size: 11px;
            height: auto;
          }

          p {
            line-height: 31px;
            padding: 0 2px;
            position: relative;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 12px;

            &.calander-date {
              overflow: inherit;
              cursor: pointer;
            }

            svg {
              position: absolute;
              right: 0;
              top: 6px;
              height: 22px;
              width: 22px;
              cursor: pointer;
            }

            &.rca-value-box {
              line-height: 31px;
              height: 31px;
              overflow-y: auto;
              overflow-x: hidden;
            }
          }
          button {
            &.view-btn {
              position: absolute;
              right: 0;
              background: #deebfd;
              color: #0563f6;
              font-size: 10px;
              min-width: 70px;
              margin: 0 5px 0 4px;
              padding: 0 8px;
              top: 6px;
              height: 30px;
              text-transform: capitalize;
              cursor: pointer;
              font-weight: 500;
            }
          }
        }

        .error-msg-followup {
          color: #f44336;
          margin-left: 15px;
        }

        .rca-box {
          height: auto;
          color: #808080;
          textarea {
            resize: none;
            width: 100%;
            border: transparent;
            font-size: 11px;
            color: #253858;
            min-height: 25px;
            padding: 4px 0px 0;
          }
        }

        .select-box {
          position: relative;
          margin: 2px 0;
          width: 98%;

          .MuiOutlinedInput-root {
            height: 42px;
            font-size: 14px;
          }

          .MuiInputLabel-formControl {
            top: -5px;
            font-size: 12px;
          }

          .MuiInputLabel-outlined.MuiInputLabel-shrink {
            top: 1px;
            color: $blueM;
            font-size: 14px;
            left: 0;
          }

          .MuiSelect-outlined.MuiSelect-outlined {
            color: $blueM;
            font-size: 12px;
            font-weight: 400;
          }

          .MuiOutlinedInput-input.MuiOutlinedInput-input {
            color: $blueM;
          }

          .MuiSelect-select:focus {
            background-color: transparent;
          }

          .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: $blueM;
            border-width: 1px;
          }

          button {
            &.refresh-btn {
              position: absolute;
              right: 0;
              top: 3px;
              min-width: 10px;
              &:hover {
                background-color: transparent;

                svg {
                  color: #414141;
                }
              }
            }
            // &.endorse-btn{
            //   background-color: #0563f6;
            //   color:#fff;
            //   min-width: 70px;
            //   margin: 0 5px 0 4px;
            //   padding: 0 8px;
            // }
          }

          .error-msg {
            color: #f44336;
          }
          .MuiInputBase-root.Mui-disabled {
            .MuiInputLabel-outlined.MuiInputLabel-shrink {
              color: #808080;
            }
          }
          span{
            &.visibility-icon{
              position: absolute;
              right: 27px;
              top: 10px;
              svg{
                font-size: 18px;
              }
            }
          }
        }

        &.activity-box {
          padding: 0 13px;
          span {
            padding: 0;
            margin:0 5px 0 5px;
            .MuiFormControlLabel-root.Mui-disabled{
              opacity: 0.3;
            }
            svg{
              width: 20px;
              z-index: 9;
            }
            
            .MuiIconButton-colorPrimary {
              border: 1px solid #0065ff;
             
            }
            .MuiCheckbox-colorPrimary.Mui-checked{
              border: none;
            }
          }
          .MuiFormControlLabel-root.Mui-disabled {
            .MuiIconButton-colorPrimary {
              border: none;
              margin: 0 6px 0 0px;
              span{
                svg{
                  color:#808080;
                }
              }
            }
          }
          .MuiFormControlLabel-label.Mui-disabled{
            color:#253858 !important;
          }
        }

        &.radio-box {
          width: 100%;

          .radio-block {
            display: inline-block;
            padding: 0;
            margin-right: 10px;

            svg {
              color: #0065ff;
              width: 14px;
              height: 14px;
              padding: 0;
            }

            .PrivateSwitchBase-root-14 {
              padding: 4px;
            }
          }

          button {
            &.upload-btn {
              color: #0065ff;
              min-width: auto;

              &:hover {
                background-color: transparent;
              }
            }
          }

          .attach-box {
            .upload {
              top: -10px;
              left: 0;
              width: 34px;
              height: 34px;
              border-radius: 5px;
            }

            button {
              &.upload-btn {
                padding: 0 3px;
                height: 32px;
                min-width: auto;
                line-height: 30px;
                margin-right: 15px;
                vertical-align: middle;
                border-radius: 6px;

                svg {
                  margin: 3px 0;
                }
              }
            }
          }

          .name-file {
            button {
              margin-right: 15px;
              vertical-align: text-top;
            }
          }
        }

        &.textarae-box {
          textarea {
            border: 1px solid #ddd;
            width: 93%;
            height: 24px !important;
            resize: none;
            border-radius: 6px;
            padding: 8px;
            margin: 0;
            display: block;
            font-size: 12px;
            line-height: 24px;
            color: #253858;

            &:hover {
              border: 1px solid #303030;
            }

            &:focus {
              border: 1px solid #0065ff;
            }
          }
        }

        .follow-update-cal {
          position: relative;
          margin: 2px 0;
          height: 39px;
          padding: 4px 0;
          border-radius: 4px;
          width: 98%;

          .MuiInputLabel-formControl {
            top: -9px;
            left: 10px;
            font-size: 13px;
          }

          label + .MuiInput-formControl {
            margin: 5px 0 0;
            padding: 0 0px 0 4px;
            font-size: 12px;
            color: #253858;
          }

          .MuiInput-underline:before,
          .MuiInput-underline:after {
            border: transparent;
          }

          .MuiInputLabel-shrink {
            top: 6px;
            color: #253858;
            background-color: #fff;
            padding: 0 2px;
            font-size: 14px;
            left: -1px;
          }

          .MuiOutlinedInput-input {
            padding: 14.5px 14px;
          }

          .MuiOutlinedInput-notchedOutline {
            border-color: #ddd;
          }
        }
        .endorse-btn {
          color: #fff;
          background-color: #0563f6;
          font-size: 11px;
          text-transform: capitalize;
          font-weight: 400;
          margin: 8px 0 0;
          cursor: pointer;
        }
        .checkbox-view {
          span {
            font-size: 12px;
            &.MuiIconButton-colorPrimary {
              padding: 0 0 0 10px;
            }

            svg {
              widows: 15px;
              height: 15px;
            }
          }
        }
        .multiple-agent-box {
          min-width: 100%;
          width: 330px;
          max-width: 100%;
          .multi-agent-view {
            border: 1px solid #ccc;
            padding: 7px 5px 2px;
            border-radius: 4px;
            height: 33px;
            // overflow-y: auto;
            label {
              position: absolute;
              font-size: 11px;
              top: -7px;
              left: 17px;
              background: #fff;
              z-index: 99;
            }
            .agents-names {
              margin: 2px 0;
              overflow-y: auto;
              height: 30px;
              span {
                &.tag-chip {
                  background-color: #deebfd;
                  border-radius: 22px;
                  font-size: 10px;
                  margin: 3px 5px 3px 0;
                  height: auto;
                  display: inline-block;
                  padding: 0 2px 0;
                  text-align: center;
                  position: relative;
                  &::after {
                    position: relative;
                    right: -6px;
                    top: 0;
                    content: ",";
                    font-size: 12px;
                    color: #253858;
                  }
                  &:empty {
                    display: none;
                  }
                }
              }
            }
          }
          .MuiAutocomplete-tag {
            font-size: 10px;
            margin: 4px 5px 0px;
            padding: 0;
            line-height: 16px;
            min-width: 35px;
            display: inline-flex;
            height: 22px;
            position: relative;
            background-color: #deebfd;
            &::after {
              position: relative;
              right: -4px;
              top: 0;
              content: ",";
              font-size: 12px;
              color: #253858;
            }
            &.MuiChip-label {
              padding: 0;
            }
          }
          .MuiOutlinedInput-input.MuiOutlinedInput-input {
            padding: 7.5px 5px;
          }
          .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: #e2e2e2;
            border-width: 1px;
          }
          .MuiInputLabel-outlined.MuiInputLabel-shrink {
            top: 1px !important;
          }
          .MuiAutocomplete-root {
            &.MuiInputBase-root {
              background-color: #0563f6;
            }
          }

          .MuiChip-deleteIcon {
            width: 12px;
            margin: 0;
          }
          .MuiAutocomplete-clearIndicator {
            padding: 0px;
            visibility: hidden;
            margin-right: -8px;
            svg {
              width: 30px;
            }
          }
          .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
            padding: 2px 0;
          }
          .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
            .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
            padding-right: 40px;
            min-height: 40px;
          }
          .MuiOutlinedInput-notchedOutline {
            border-color: 1px solid #cdcdcd;
          }
          span.MuiChip-label {
            padding: 0 1px 0 5px;
            color: #0065ff;
          }
        }
      }
      .rca-box-comment {
        li {
          width: 100%;
          .error-msg {
            color: #f44336;
          }
          .classification-box {
            min-height: 90px;
            width: 97.3%;
            padding: 10px 8px;
            p {
              &.rca-value-box {
                overflow: unset;
                white-space: inherit;
                line-height: 19px;
                word-break: break-word;
              }
            }
          }
          .select-box {
            width: 99%;
            .rca-txt {
              position: relative;
              label {
                position: absolute;
                left: 5px;
                top: -5px;
                background-color: #fff;
                padding: 0 5px;
                font-size: 11px;
                text-transform: capitalize;
                color: #253858;
              }
              textarea {
                width: 98%;
                resize: none;
                min-height: 90px;
                border: 1px solid #ddd;
                border-radius: 4px;
                padding: 8px;
                line-height: 16px;
                font-family: "Roboto", sans-serif;
                font-size: 12px;
                color: #253858;
                font-weight: 500;
              }
            }
          }
        }
      }
    }

    .footer-ticket-data {
      text-align: right;

      button {
        height: 28px;
        min-width: auto;
        padding: 0 10px;
        text-align: center;
        margin: 0 5px;
        border-radius: 5px;
        text-transform: capitalize;
        font-size: 12px;

        i {
          padding: 0 5px;
        }

        &.submit-btn {
          background-color: $blueM;
          color: #253858;
          color: $whiteM;
          margin-right: 14px;
        }

        &.edit-btn {
          background-color: #c5c5c5;
          color: $whiteM;
        }

        &.edit {
          background-color: $blueM;
          color: $whiteM;
          padding: 0.8rem;
        }

        svg {
          color: #fff;
        }
      }
    }
  }
  .check-talktime-box {
    button {
      &.check-talktime-btn {
        color: #0563f6;
        background-color: transparent;
        font-size: 12px;
        text-transform: capitalize;
        border: 1px solid #fff;
        font-weight: 600;
        padding: 0 8px;
        margin: 6px 0 6px;
        cursor: pointer;
        span {
          span {
            padding: 0;
            font-size: 12px;
          }
          i {
            font-size: 18px;
            display: none;
          }
          // &::after {
          //   content: "\00bb";
          //   position: absolute;
          //   opacity: 0;
          //   top: -5px;
          //   right: 44px;
          //   transition: 0.5s;
          //   font-size: 22px;
          // }
        }
        // &:hover {
        //   border: 1px solid #deebff;
        //   span {
        //     &::after {
        //       right: -10px;
        //       top: -6px;
        //       opacity: 1;
        //     }
        //   }
        // }
      }
    }
    span {
      padding: 4px;
      font-size: 12px;
      &.talktime-fullfilled {
        color: #4daa51;
        font-size: 10px;
        border: 1px solid #4daa51;
        border-radius: 4px;
      }
      &.talktime-not-fullfilled {
        color: #f44336;
        font-size: 10px;
        border-radius: 4px;
        border: 1px solid #f44336;
      }
    }
  }
  .follow-update-view {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    right: 0px;
    margin: auto;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;

    .iframe-box {
      position: fixed;
      top: 14%;
      height: 450px;
      width: 800px !important;
      max-width: 100%;
      padding: 0 10px 5px;
      background: #fff;
      border: transparent;
      border-radius: 10px;
      display: block;
    }
  }
  button {
    &.endosrement-btn {
      color: #0563f6;
      text-transform: capitalize;
      border: 1px solid #0065ff;
      font-size: 12px;
      margin: 5px;
      &:hover {
        background-color: transparent;
      }
    }
  }
}
