// more action
.more-action-list {
  .MuiMenu-paper {
    top: 41px !important;
    width: 180px;
    margin: 0 0 0 0;
    right: 0;
  }

  ul {
    padding: 0;

    .MuiMenu-list {
      padding: 0;
    }

    li {
      font-size: 12px;
      border-bottom: 1px solid #ddd;
      padding: 10px 11px;
      font-weight: 300;

      // &:last-child{
      //     border-bottom: none;
      // }
      a {
        color: #253858;
        font-size: 12px;
        text-decoration: none;
      }

      &:hover {
        background-color: transparent;
        cursor: pointer;
      }
    }

    a {
      color: #253858;
      font-size: 12px;
      text-decoration: none;
    }
  }
}

// escalation-popup-box
.escalation-popup {
  .MuiDialogTitle-root {
    padding: 5px 24px 5px;

    h6 {
      font-size: 16px;
      color: #253858;
      position: relative;

      &::before {
        content: "";
        border-bottom: 3px solid #0065ff;
        position: absolute;
        left: 0;
        bottom: -2px;
        height: 10px;
        padding: 5px;
        color: transparent;
        width: 3%;
      }

      span {
        &.escalation-timing {
          color: #0065ff;
          font-size: 14px;
        }
      }
    }
  }

  .escalation-inner-data {
    background-color: #ffffff;
    padding: 15px 0;
    position: relative;

    &::after {
      border-bottom: 1px solid #ddd;
      content: "";
      width: 95%;
      margin: auto;
      left: 0;
      right: 0;
      position: absolute;
    }

    &:last-child {
      &::after {
        content: none;
      }
    }
  }

  .escalation-popup-box {
    margin: 12px 0 0;

    hr {
      border-color: #ddd;
    }

    .form-control {
      width: 100%;
      margin-bottom: 5px;

      .MuiInputLabel-shrink {
        top: 2px;
        z-index: 999;
        background: #fff;
        left: 0px;
        color: #253858;
        font-size: 14px;
      }

      .MuiOutlinedInput-root {
        position: relative;
        border-radius: 4px;
        height: 44px;
      }

      label + .MuiInput-formControl {
        margin-top: 16px;
      }

      .MuiFormControl-root {
        border-radius: 4px;
        width: 100%;
      }

      .MuiInput-underline:after,
      .MuiInput-underline:before {
        border-bottom: transparent;
      }

      .MuiInputBase-input {
        padding: 8px 12px 10px;
        font-size: 14px;
        color: #253858;
      }

      input:-internal-autofill-selected {
        background-color: #fff;
      }

      .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: transparent;
      }

      .comment-box {
        position: relative;

        label {
          position: absolute;
          color: #253858;
          font-size: 11px;
          background-color: #fff;
          font-weight: 500;
          left: 6px;
          top: -5px;
          padding: 0 5px;
        }

        textarea {
          resize: none;
          width: 96%;
          min-height: 30px;
          border: 1px solid #ccc;
          border-radius: 4px;
          padding: 10px;
          color: #253858;
        }
      }
    }

    .Component-closeButton-62 {
      top: 7px;
      color: #253858;
      right: 8px;
      position: absolute;
      font-size: 12px;

      svg {
        width: 18px;
      }
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }

    button {
      &.save-btn {
        color: #0065ff;
        border: 1px solid #0065ff;
        min-width: 80px;
        font-size: 12px;
        height: 35px;
        text-transform: capitalize;
        cursor: pointer;
      }
    }
  }

  .text-right {
    text-align: right;
  }

  .MuiDialog-paperWidthMd {
    min-height: 200px;
  }

  .page-loader {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 40%;
    width: 39px;

    svg {
      color: #0065ff;
    }
  }

  .no-data {
    width: 100%;
    text-align: center;

    p {
      font-size: 16px;
      line-height: 24px;
      padding: 10px 0;
    }
  }
}

// endorsement-popup
.endorsement-popup {
  .MuiDialog-paperWidthMd {
    max-width: 650px;
  }

  .endorsement-popup-inner {
      max-height: 450px;
      min-height: 350px;
    .form-control {
      width: 100%;
      margin-bottom: 5px;

      .MuiInputLabel-shrink {
        top: 2px;
        z-index: 999;
        background: #fff;
        left: 0px;
        color: #253858;
        font-size: 14px;
      }

      .MuiOutlinedInput-root {
        position: relative;
        border-radius: 4px;
        height: 44px;
      }

      label + .MuiInput-formControl {
        margin-top: 16px;
      }

      .MuiFormControl-root {
        border-radius: 4px;
        width: 100%;
      }

      .MuiInput-underline:after,
      .MuiInput-underline:before {
        border-bottom: transparent;
      }

      .MuiInputBase-input {
        padding: 8px 12px 10px;
        font-size: 14px;
        color: #253858;
      }

      input:-internal-autofill-selected {
        background-color: #fff;
      }

      .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: transparent;
      }

      .comment-box {
        position: relative;

        label {
          position: absolute;
          color: #253858;
          font-size: 11px;
          background-color: #fff;
          font-weight: 500;
          left: 6px;
          top: -5px;
          padding: 0 5px;
        }

        textarea {
          resize: none;
          width: 96%;
          min-height: 30px;
          border: 1px solid #ccc;
          border-radius: 4px;
          padding: 10px;
          color: #253858;
        }
      }
    }

    button {
      cursor: pointer;
      font-size: 14px;
      text-transform: capitalize;
      margin-right: 10px;
      min-height: 30px;
      padding: 4px 0;
      min-width: 80px;
      border-radius: 4px;

      &.save-btn {
        color: #0052cc;
        border: 1px solid #0065ff;
      }

      &.cancel-btn {
        color: #808080;
        border: 1px solid #808080;
      }
      &.link-btn{
        min-width: 50px;
        color: #0052cc;
        font-size: 12px;
        font-weight: 600;
        min-height: 24px;
        padding: 4px;
        border-radius: 4px;
        // border: 1px solid #0065ff;
      }
    }
  }
}
