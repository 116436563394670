@import "./components/ticketUi";
@import "./components/esclation";
@import "./components/ticketDetail";
@import "./components/ticketTimeline";
@import "./components/responsive";
@import "./components/buttonBox";
@import "./components/allComments";
@import "./components/stepper.scss";
@import "./components/topBar";
@import "./components/communicationBox";
@import "./components//moreAction";
@import "./components/replyPopup";
@import "./components/followUp";
@import "./components/commen";
@import "./components/createDialog";
@import "./components/attachBox";
@import "./components/commMailPopup";
@import "./components/dashboard";
@import "./components/loginPage";
@import "./components/smeEmplyee";
@import "./components/appLogs";
@import "./components/insurerDashboard";
@import "./components/commentsHistory";
@import "./components/apiRm.scss";
@import "./components/refund";
@import "./components/pbDashboardNew";
@import "./components/endorsement";
@import "./components/pbAgentdashboard";
@import "./components/oneTicketTeamLead";
@import "./components/ticketCacheManagement";
@import "./components/pendencyDescription";


// @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");

@import "../../fonts/RobotoFonts.css";
@import "/node_modules/font-awesome/css/font-awesome.min.css";

* {
  padding: 0;
  margin: 0;
  list-style: none;
}

body {
  background-color: #f8f8f8;
  font-family: "robotoregular";
  font-size: 12px;
  color: #253858;
  // padding-top: 46px;
}

img {
  vertical-align: middle;
  border-style: none;
}

button {
  min-width: auto;

  span {
    padding: 0;
  }
}

iframe:empty {
  // display: none;
  position: relative !important;
  width: 10px !important;
  &.ifamre-box{
    width:100% !important;
  }
}

.reply-box,
.endorsement-dialog-popup {
  iframe:empty {
    display: block;
    width:100% !important;
  }
}

.whatsapp-dialog-popup {
  iframe:empty {
    display: block;
    width:100% !important;
  }
}
.cke_reset{
  iframe:empty {
    position: relative !important;
    width: 100% !important;
  }
}
.iframe-box{
  iframe:empty {
    position: relative !important;
    width: 100% !important;
    height: 100vh;
  }
}
h2 {
  color: #808080;
  text-align: left;
  display: inline-block;
  font-size: 14px;
  position: relative;
  font-weight: 500;
  margin: 0 0 20px;

  // &::before{
  //     content: "__";
  //     position: absolute;
  //     right: -15px;
  //     top: -4px;
  // }
  &.card-heading {
    margin: 30px 0 0;
  }
}

.text-right {
  text-align: right;
}

.spinner-loader {
  position: relative;
  left: 48%;
  right: 0;
  margin: 27px auto 0;
  width: 100%;
  top: 0;

  svg {
    color: $blueM;
  }
}
// landing-page-style
.landing-page-style{
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(#20bf5545, #01baef5e);
  >div{
    display: block;
    color: #253858;
    font-size: 14px;
    padding: 15px 0 0;
  }
}

.broken-page{
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(#3EADCF, #ABE9CD);
  &.error{
    font-size: 100px;
  }
  &.text{
    display: block;
    font-size: 100px;
  }
}

.layout-version{
  position: fixed;
  font-size: 12px;
  display: block;
  bottom: 0;
  left: 0;
  font-weight: 600
}

.content-body {
  padding: 70px 0 0;
  background-color: #fff;

  @media (max-width: 1000px) {
    padding: 100px 0 0;
  }

  @media (max-width: 500px) {
    padding: 130px 0 0;
  }
}

.MuiAutocomplete-popper{
  
  ul{
    li{
          font-size: 11px;
          padding: 0;
          button{
            padding: 0;
            min-width:auto;
            span{
              padding: 0;
            }
          }
          .MuiAutocomplete-input{
            padding: 0;
          }
      }
  }
}

// confirmation-dialog-box
.confirmation-dialog-box{
  .MuiDialog-paperWidthSm{
    padding: 20px 10px 0;
    min-width:450px;
    min-height: 120px;
    h4{
      text-align: center;
      font-size: 14px;
      svg{
        font-size: 19px;
        vertical-align: text-bottom;
      }
    }
    button{
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      border-radius: 6px;
      margin: 11px 8px;
      &.confirm-yes{
        background: #1370fc;
      }
      &.confirm-no{
        background: #dedede;
        color:#888888;
      }
    }
  }
  .MuiDialogActions-root{
    justify-content: center;
  }
}

.no-data-found-box{
  margin: 0 auto;
  text-align: center;
  img{
    max-width: 40%;
  }
  span{
    display: block;
    font-size: 14px;
    line-height: 12px;
    padding-bottom: 10px;
    font-weight: 500;
  }

}