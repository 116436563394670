// dashboard
.dashboard-summary {
  background-color: #ffffff;
  padding-bottom: 20px;
  .dashboard-topbar {
    background-color: #e6f2fb;
    padding: 10px 25px;
    h2 {
      font-size: 16px;
      color: #253858;
      margin: 0;
      padding: 8px 0;
      vertical-align: middle;
    }
    ul {
      padding: 5px 0;
      li {
        padding: 0;
        vertical-align: middle;
        list-style: none;
        display: inline-block;
        width: auto;
        margin: 0px 5px;
        a {
          background-color: #d0e0f9;
          padding: 0 8px;
          border-radius: 30px;
          color: #253858eb;
          width: 14px;
          display: block;
          text-align: center;
          height: 28px;
          line-height: 28px;
          cursor: pointer;
          i {
            color: #03a9f4;
            font-size: 14px;
          }
        }
      }
    }
    button {
      &.create-ticket {
        height: 30px;
        min-width: 117px;
        padding: 0 10px;
        text-align: center;
        margin: 0 5px;
        border-radius: 30px;
        text-transform: capitalize;
        background-color: #bdd7ff;
        font-size: 10px;
        color: #0065ff;
      }
    }
  }
  .ticket-summary-list {
    ul {
      padding: 5px 20px;
      margin: 20px 0 10px!important;
      li {
        padding: 0;
        vertical-align: middle;
        list-style: none;
        display: inline-block;
        width: auto;
        margin: 0px 5px 30px;
        cursor: pointer;
        div {
          background-color: #fff;
          position: relative;
          min-height: 28px;
          min-width: 95px;
          box-shadow: 0px 6px 6px #3469cb29;
          text-align: center;
          line-height: 18px;
          border-radius: 5px;
          padding-top: 32px;
          border: 2px solid #fff;
          em {
            position: absolute;
            left: 0;
            background-color: #03a9f4;
            box-shadow: 0px -3px 6px #16161678;
            color: #fff;
            width: 100%;
            font-size: 19px;
            height: 32px;
            top: -9px;
            line-height: 32px;
            border-radius: 5px 5px 0 0;
            padding: 2px 0;
          }
          span {
            color: #253858;
            font-size: 12px;
            font-weight: 600;
            padding: 10px 0;
          }
          p {
            line-height: 20px;
            padding: 3px 0;
            font-weight: 600;
          }
          &.new-ticket {
            em {
              background-color: #53d192;
            }
            span {
              color: #53d192;
            }
          }
          &.due-ticket {
            em {
              background-color: #e55957;
            }
            span {
              color: #e55957;
            }
          }
          &.duetoday-ticket {
            em {
              background-color: #ffd200;
            }
            span {
              color: #ffd200;
            }
          }
          &.open-ticket {
            em {
              background-color: #e05d6f;
            }
            span {
              color: #e05d6f;
            }
          }
          &.Unassigned-ticket {
            em {
              background-color: #6ccac9;
            }
            span {
              color: #53d192;
            }
          }
          &.revert-ticket {
            em {
              background-color: #fbb168;
            }
            span {
              color: #fbb168;
            }
          }
          &.cust-ticket {
            em {
              background-color: #616ca9;
            }
            span {
              color: #616ca9;
            }
          }
          &.reopen-ticket {
            em {
              background-color: #e17695;
            }
            span {
              color: #e17695;
            }
          }
          &.resolved-ticket {
            em {
              background-color: #056309;
            }
            span {
              color: #056309;
            }
          }
          &.escalation-ticket {
            em {
              background-color: #f32b28;
            }
            span {
              color: #f32b28;
            }
          }
        }
        &:hover,
        &:focus,
        &.active {
          div {
            border: 2px solid #03a9f4;
          }
        }
      }
    }
  }
  .search-box {
    padding: 0 25px;
    border-top: 1px solid #f3f3f3;
    box-shadow: 0px 6px 6px #3469cb29;
    ul {
      li {
        display: inline-block;
        width: auto;
        padding: 0;
      }
    }
    .select-insurer-box {
      .MuiOutlinedInput-input {
        padding: 13.5px 14px;
      }
      .MuiInputLabel-outlined {
        top: -5px;
      }
      .MuiInputLabel-outlined.MuiInputLabel-shrink {
        top: 0px;
        padding: 1px 10px;
        left: -6px;
        background: #fff;
      }
      button {
        &.load-data-btn {
          background-color: #03a9f4;
          color: #fff;
          border: transparent;
          margin: 14px 5px 10px;
          height: 35px;
          line-height: 43px;
          font-size: 14px;
          font-weight: 500;
          min-width: 92px;
          text-transform: capitalize;
          &:hover {
            background-color: #03a9f4;
          }
        }
      }
    }
  }
  .export-bar{
    text-align: right;
    padding-bottom: 10px;
    button{
      &.export-btn{
        text-transform: capitalize;
        background-color: #0065ff;
        font-size: 12px;
        color: #fff;
        border-radius: 4px;
        height: 30px;
        margin: 0 16px 0 0;
        padding: 0 15px;
        font-weight: 500;
        box-shadow: 1px 3px 3px 1px #00000029;
        img{
          width:15px;
        }
        &:hover{
          background-color: #0065ff;
        }
      }
    }

  }
  .load-data-list {
    padding: 20px 20px 20px;
    background-color: #f5f6f9eb;
    ul {
      margin: 0;
      padding: 5px 0 0;
      display: flex;
      &.head {
        li {
          font-weight: 600;
          margin-bottom: 10px;
        }
      }
      li {
        width: 12.5%;
        display: inline-block;
        padding: 8px;
        word-break: break-all;
        white-space: initial;
        vertical-align: middle;
        font-size: 12px;
        // &:nth-child(2),&:nth-child(3) {
        //   width: 8%;
        // }
        a {
          color: #0052cc;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      &.body-list {
        background-color: #fff;
        box-shadow: 0px 3px 11px 2px #6e6e6e29;
        margin: 0 0 5px;
        border-radius: 0 0 4px 4px;
      }
    }
    // .load-data-head {
    //   background-color: #f5f6f9eb;
    //   padding:10px 15px 15px;
    //   overflow-y: scroll;
    //   overflow-x: hidden;
    //   height: 280px;
    // }
    
    .no-data-found{
      text-align: center;
      padding:15px;
      background-color: #fff;
      p{
        color:#253858;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .export-data-list{
      padding:5px 0 10px;
      overflow-y: scroll;
      overflow-x: hidden;
      height: 280px;
      @media all and (max-width: 1366px) {
        height: 200px;
      }
    }
  }
  .search-loader{
    left: 50%;
    right: 50%;
    margin: auto;
    top: 10%;
    z-index: 9999;
    position: relative;
    svg{
      color: #2196f3;
    }
  }
}
// advance search
.advance-search{
  padding: 30px 24px;
  .advance-search-box{
    background: #e6f2fb;
    border-radius: 22px;
    padding: 4px 58px 4px 12px;
    border: 1px solid #ccc;
    color: #253858;
    .MuiInputLabel-shrink {
      left: 8px;
      top: -8px;
      background: #fff;
      color: #253858;
      font-size: 16px;
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before{
      border-bottom: transparent;
    }
    label + .MuiInput-formControl{
      margin-top: 7px;
      padding: 0 0 6px;
      color:#253858;
      font-size: 12px;
    }
    .MuiInput-root {
      position: relative;
      font-size: 14px;
      color: #253858;
    }
  }
  button{
    margin-right: 10px;
    &.btn-search{
      background-color: #2196f3;
      color:#fff;
      text-transform: capitalize;
      min-width: 90px;
      right:0;
      border-radius: 5px;
    }
    &.adv-search-btn{
      background-color: #2196f3;
      color: #fff;
      text-transform: capitalize;
      min-width: 54px;
      border-radius: 0 22px 22px 0;
      position: absolute;
      right: -9px;
      height: 40px;
      cursor: pointer;
    }
    &.export-btn{
      background-color: #2196f3;
      color:#fff;
      text-transform: capitalize;
      min-width: 90px;
      border-radius: 5px;
      margin:2px 10px;
    }
  }
  .form-control{
    width:100%;
    .MuiOutlinedInput-input {
      padding: 13.5px 14px;
      font-size: 12px;
    }
    .MuiInputLabel-shrink{
      left: 0;
      top: 2px;
      font-size: 13px;
      color: #2196f3;
      background: transparent;
    }
    .MuiInputLabel-outlined{
      font-size: 14px;
    }
    .date-picker{
      border-radius: 5px;
      border: 1px solid #95AFFC;
      padding: 0 8px;
      .MuiInputLabel-shrink{
        left: 9px;
        top: -6px;
        font-size: 13px;
        background: #fff;
        color:#2196f3;
      }
      .MuiOutlinedInput-input{
        padding:15.5px ​14px;
      }
      .MuiInput-root{
        top: -4px;
      }
      &:hover{
        border: 1px solid  #414141;
      }
      &:focus{
        border: 1px solid  #95AFFC;
      }
      label + .MuiInput-formControl{
        color:#253858;
        font-size: 12px;
      }
    }
    .MuiOutlinedInput-input{
      padding:15.5px ​14px;
    }
    label + .MuiInput-formControl{
      color:#808080;
      font-size: 12px;
    }
    .MuiInput-underline:before,.MuiInput-underline:after{
      border-bottom: none;
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before{
      border-bottom: transparent;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color:  #95AFFC;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
      border-color:  #95AFFC;
      border-width: 1px;
    }
  }
}
