// Api-log-table
.api-log-table{
    height: 400px;
    overflow-y: scroll;
    width:1410px;
    margin:20px auto;
    box-shadow: 0px 0px 6px 1px #0e0e0e14;
    .MuiTableRow-root{
        &:nth-child(2n+1){
            background-color: #a1a1a124;
        }
        .MuiTableCell-head{
            background-color: #eef3f5;
            color: #253858;
            padding:8px 10px;
            min-width:130px;
            font-size: 12px;
            font-weight: 600;
            word-break: break-all;
        }
        .MuiTableCell-root{
            color: #253858;
            padding:8px 10px;
            font-size: 12px;
        }
        button{
            &.edit-delete-btn{
                color: #2196f3;
                height: 26px;
                line-height: 26px;
                font-size: 12px;
                border-radius: 4px;
                text-transform: capitalize;
                min-width: 51px;
                font-weight: 500;
                cursor: pointer;
                padding: 0 5px;
                &:hover{
                    background-color: transparent;
                    color: #2196f3;
                    cursor: pointer;
                }
            }
        }
    }
    .api-log-table-body{
        height: 400px;
        overflow-y: scroll;
    }
}
.api-logs{
    padding:10px 15px;
    background-color: #fff;
    h2 {
    color: #0563f6;
    font-size: 20px;
    font-weight: 500;
    border-bottom: 2px solid #25385833;
    margin: 5px 0 10px;
    padding-bottom: 10px;
    width: 100%;
    }
    .listing{
        box-shadow: 0px 0px 6px 1px #0e0e0e14;
        padding: 20px 13px;
        border-radius: 5px;
        h2{
            font-size: 16px;
            color: #253858;
            margin: 0 0 20px;
        }
        li{
            width: 15%;
            display: inline-block;
            vertical-align: middle;
            margin-right: 8px;
            margin-bottom: 10px;
            .form-control{
                width: 100%;
                border-radius: 5px;
                border:1px solid #cfcfcf;
                label + .MuiInput-formControl{
                    margin-top: 0;;
                }
                .MuiFormControl-marginNormal{
                    margin-top: 0;
                    margin-bottom: 0;
                }
                .MuiInput-underline:before,.MuiInput-underline:after{
                    border-bottom:none;
                }
                .MuiInputBase-input{
                    padding:10px 8px;
                }
                .MuiInputLabel-formControl {
                    top: -12px;
                    left: 9px;
                    font-size: 12px;
                    font-weight: 600;
                }
                .MuiInputLabel-shrink {
                    top: -6px;
                    left: 6px;
                    font-size: 12px;
                    background: #fff;
                    font-weight: 500;
                }
                
            }
            button{
                &.api-btn{
                    background-color: #2196f3;
                    color: #fff;
                    height: 37px;
                    line-height: 37px;
                    font-size: 14px;
                    border-radius: 20px;
                    text-transform: capitalize;
                    min-width: 80px;
                    font-weight: 500;
                    padding: 5px;
                    margin-right: 10px;
                    cursor: pointer;
                }
                &.cancel-btn{
                    background-color: #253858a1;
                }
            }
        }
    }
}
button{
    &.api-btn{
        background-color: #2196f3;
        color: #fff;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        border-radius: 4px;
        text-transform: capitalize;
        min-width: 60px;
        font-weight: 500;
        padding:5px;
        margin-right: 10px;
        &:hover{
            background-color: #2196f3;
        }
    }
}



// responsive css
@media all and (max-width: 768px){
    .api-log-table{
        max-width: 100%;
    }
}