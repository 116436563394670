// Endorsement
.endorsement-box {
  width: 97%;
  margin: 13px 10px 10px;
  border: 2px solid #f2f7ff;
  border-radius: 12px;
  padding-bottom: 20px;
  h2 {
    font-size: 18px;
    color: #253858;
    position: relative;
    min-width: 100px;
    display: inline-block;
    margin: 0;
    font-weight: 500;
    padding: 0 5px;
    margin: 0 0 20px;
    &::before {
      content: "";
      border-bottom: 2px solid #253858;
      position: absolute;
      left: 8px;
      bottom: -2px;
      height: 10px;
      padding: 5px;
      color: transparent;
      width: 20%;
    }
  }
  h4 {
    line-height: 16px;
    font-size: 12px;
    color: #253858;
    background-color: #f2f7ff;
    border-radius: 12px 12px 0px 0px;
    min-height: 48px;
    padding: 0 16px;
    line-height: 48px;
  }

  .inner-box {
    padding: 10px 16px;
    .endorsement-list {
      margin-bottom: 8px;
      ul {
        width: 100%;
        padding: 0;
        li {
          display: inline-block;
          &.left-side {
            width: 100%;
            ul {
              li {
                display: inline-block;
                width: 20%;
                margin-bottom: 10px;
                padding:0;
                span {
                  font-size: 12px;
                  color: #253858;
                  line-height: 16px;
                  display: block;
                  padding-bottom: 8px;
                }
                strong {
                  font-size: 12px;
                  line-height: 19px;
                  font-weight: 500;
                  display: block;
                  button{
                      &.id-link-btn{
                          background: transparent;
                          cursor: pointer;
                         span{
                             color:#0065ff;
                             text-decoration: underline;
                             cursor: pointer;
                         }
                      }
                  }
                }
                button {
                  &.current-btn {
                    background-color: #ffe7c7;
                    font-size: 10px;
                    width: 114px;
                    line-height: 26px;
                    min-height: 26px;
                    text-transform: capitalize;
                    border-radius: 4px;
                    padding: 5px 0;
                    display: block;
                  }
                  &.green {
                    background-color: #deffe4;
                    span {
                      color: #00a238;
                      font-size: 10px;
                      line-height: 13px;
                      padding-bottom: 0;
                    }
                  }
                  &.yellow {
                    background-color: #ffe7c7;
                    span {
                      color: #db8c0e;
                      font-size: 10px;
                      line-height: 13px;
                      padding-bottom: 0;
                    }
                  }
                  &.red {
                    background-color: #FC8D8D;
                    span {
                      color: #ff0000;
                      font-size: 10px;
                      line-height: 13px;
                      padding-bottom: 0;
                    }
                  }
                  &.complet-btn {
                    background-color: #deffe4;
                    color: #00a238;
                    font-size: 10px;
                    width: 114px;
                    line-height: 26px;
                    height: 26px;
                    text-transform: capitalize;
                    border-radius: 4px;
                    padding: 5px 0;
                    display: block;
                    span {
                      color: #00a238;
                      font-size: 10px;
                      // opacity: 100%;
                    }
                  }
                }
              }
            }
          }
          &.right-side {
            width: 100%;
            vertical-align: bottom;
            text-align: right;
            padding:20px 10px 0;
            button {
              &.verifiy-btn {
                background-color: #deebff;
                color: #0065ff;
                font-size: 10px;
                width: 100px;
                line-height: 13px;
                height: 24px;
                text-transform: capitalize;
                border-radius: 4px;
              }
            }
          }
        }
        div {
          &.history-view {
            background: #a0b0c633;
            border-radius: 8px;
            padding: 10px;
            position: relative;
            margin: 0px 0 0 10px;
            width: 97.6%;
            border: 1px solid #ddd;
            opacity: 0.9;
            /* display: none; */
            &::before {
              position: absolute;
              background: #a0b0c6;
              width: 8px;
              height: 8px;
              content: "";
              left: -16px;
              top: 46%;
              border-radius: 50%;
            }
            &:hover {
              &::before {
                background: #0065ff;
              }
              border: 1px solid #0065ff;
              opacity: 1;
            }
          }
          h5 {
            font-size: 14px;
            color: #253858;
            line-height: 20px;
            font-weight: 300;
            margin: 0;
            width: 84%;
            strong {
              font-weight: 600;
            }
          }
          span {
            &.date {
              position: absolute;
              right: 8px;
              top: 34%;
              color: #253858;
              font-size: 10px;
              font-weight: 600;
              line-height: 16px;
              bottom: 50%;
            }
          }
        }
      }
    }
    ul {
      &.title-heading {
        width: 90%;
        padding: 5px 0 0;
        li {
          strong {
            font-size: 14px;
            line-height: 19px;
            font-weight: 500;
            display: block;
            span {
              font-size: 10px;
              color: #253858;
              line-height: 16px;
              display: inline-block;
              padding-bottom: 8px;
              // opacity: 70%;
              margin-right: 5px;
            }
          }
        }
      }
    }
    .nominee-detail {
      border-top: 1px solid #eaeaea;
      ul {
        padding: 0;
        // margin-bottom: 1px;
        &.head {
          li {
            display: inline-block;
            text-align: center;
            margin: 0 2px;
            vertical-align: middle;
            border-bottom: transparent;
            min-height: 50px;
            width: 14%;
            &:nth-child(3) {
              width: 16%;
              background-color: transparent;
            }
            &:nth-child(4) {
              width: 16%;
              background-color: transparent;
            }
          }
        }
        &.first-row {
          li {
            &:nth-child(3) {
              background-color: #fddddd;
              border-radius: 2px 2px 0 0;
              // border-bottom: transparent;
              width: 16%;
            }
            &:nth-child(4) {
              background-color: #deffe4;
              // border-bottom: transparent;
              width: 16%;
              border-radius: 2px 2px 0 0;
            }
            &:nth-child(5) {
              background-color: #deffe4;
              border-radius: 2px 2px 0 0;
              // border-bottom: transparent;
              // width: 18%;
            }
          }
        }
        &.last-row {
          li {
            border-bottom: 1px solid #eaeaea;
            &:nth-child(3) {
              border-radius: 0 0 8px 8px;
              border-bottom: transparent;
            }
            &:nth-child(4) {
              border-radius: 0 0 8px 8px;
              border-bottom: transparent;
            }
          }
        }
        li {
          display: inline-block;
          text-align: center;
          margin: 0 2px;
          padding: 12px 0 0;
          width: 14%;
          vertical-align: middle;
          border-bottom: 1px solid #eaeaea;
          min-height: 42px;
          line-height: normal;
          // &:nth-child(1){
          //     width:16%;
          // }
          &:nth-child(3) {
            background-color: #fddddd;
            border-radius: 0;
            width: 18%;
            border-bottom: 1px solid #ffffff;
          }
          &:nth-child(4) {
            background-color: #deffe4;
            border-radius: 0;
            width: 18%;
            border-bottom: 1px solid #ffffff;
          }
          h6 {
            font-size: 12px;
            color: #253858;
            display: block;
            padding: 8px 0;
            font-weight: 600;
          }
          strong {
            font-size: 12px;
            font-weight: 500;
            color: #253858;
            display: block;
            &.completed {
              color: #19b24d;
              font-size: 10px;
            }
            &.in-progress {
              color: #ff900b;
            }
            &.light-txt {
              // opacity: 60%;
            }
            &.rejected {
              color: #ff0000;
            }
          }
          button {
            &.add-copy {
              color: #0065ff;
              font-size: 12px;
              text-transform: capitalize;
              min-width: 154px;
              background: transparent;
              padding: 0 8px;
              i {
                color: #0065ff;
                font-size: 12px;
                margin-left: 6px;
              }
            }
            &.download-letter {
              background: #deebff;
              color: #0065ff;
              font-size: 12px;
              min-width: 160px;
              text-transform: capitalize;
            }
          }
        }
      }
      button {
        &.download-letter {
          margin: 15px 5px 0;
          background: #deebff;
          color: #0065ff;
          font-size: 10px;
          min-width: 160px;
          text-transform: capitalize;
        }
      }
    }
    .MuiExpansionPanelSummary-root {
      border: 1px solid #eaeaea;
      border-radius: 8px;
      // &:focus,&:visited{
      //     background-color: #F2F7FF;
      // }
    }
    .MuiExpansionPanelSummary-content.Mui-expanded {
      margin: 10px 0;
    }
    .MuiExpansionPanelSummary-content {
      margin: 0;
    }
    .MuiExpansionPanel-root:before {
      background-color: transparent;
    }
    .MuiExpansionPanelSummary-root.Mui-expanded {
      min-height: 24px;
    }
    .MuiExpansionPanelDetails-root {
      padding: 8px 4px;
      .MuiTableCell-root {
        border-bottom: 1px solid #f1f1f1;
      }
      .MuiTable-root {
        .MuiTableHead-root {
          .MuiTableRow-root {
            .MuiTableCell-stickyHeader {
              background-color: #fff;
              &:nth-child(2) {
                background-color: #fddddd;
                border-radius: 2px 2px 0 0;
              }
              &:nth-child(3) {
                background-color: #deffe4;
                border-radius: 2px 2px 0 0;
              }
            }
          }
        }
        .MuiTableBody-root {
          .MuiTableRow-root {
            .MuiTableCell-body {
              &:nth-child(2) {
                background-color: #fddddd;
                border-radius: 0;
              }
              &:nth-child(3) {
                background-color: #deffe4;
                border-radius: 0;
              }
            }
          }
        }
      }
    }
    .MuiPaper-elevation1 {
      box-shadow: none;
      margin-bottom: 10px;
    }
  }
  &.endorsement-box-status {
    border: transparent;
    h4 {
      background-color: transparent;
      border-bottom: 1px solid #e4e0e0;
      font-size: 14px;
      font-weight: 400;
    }   
  }
}
// raised-dialog
.raised-dialog {
  position: relative;
  .raise-ticket-pop {
    width: 448px;
    margin: 0 auto 20px;
    max-width: 100%;
    text-align: center;
    position: relative;
    h3 {
      color: #455570;
      font-size: 18px;
      line-height: 24px;
      margin: 0 10px;
      font-weight: 400;
      text-align: center;
      margin-bottom: 30px;
      border: none;
      img {
        display: block;
        margin: 5% 5px auto 1%;
      }
    }
  }
  h2{
    img{
      margin-right: 8px;
    }
  }
  .MuiBackdrop-root{
    background-color: #60606078;
    .MuiPaper-rounded{
      box-shadow: none !important;
    }
  }
  
  button {
    margin: 0 10px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    text-transform: capitalize;
    min-width: 120px;
    display: inline-block;
    padding: 0 10px;
    cursor: pointer;
    &.close-icon {
      position: absolute;
      right: 0;
      background-color: #b2b2b2;
      color: #fff;
      min-width: auto;
      top: 7px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      padding: 3px 0;
      line-height: 14px;
      svg {
        font-size: 14px;
      }
    }
    &.raise-btn {
      background: #0065ff;
      box-shadow: 0px 6px 16px #3469cb29;
      border-radius: 8px;
      color: #fff;
      height: 42px;
      line-height: 40px;
      .MuiCircularProgress-root{
        display: block;
        margin: 8px auto 0;
      }
    }
    &.raise-cencel {
      background: #b2b2b2;
      box-shadow: 0px 6px 16px #3469cb29;
      border-radius: 8px;
      color: #fff;
      height: 42px;
      line-height: 40px;
    }
  }
}
.endorement-dialog-box {
  .text-center {
    text-align: center;
  }
  position: relative;
  h3 {
    font-size: 18px;
    color: #253858;
    padding: 10px 0;
    border-bottom: 1px solid #607d8b29;
    margin: 0;
  }
  .endorement-inner-box {
    width: 500px;
    margin: 0 auto 20px;
    max-width: 100%;
    padding: 10px 12px;
    position: relative;
    .comment-box {
      margin: 15px 10px 10px;
      label {
        text-align: left;
        display: block;
        font-size: 12px;
        color: #253858;
        padding: 0 3px;
      }
      textarea {
        width: 96%;
        resize: none;
        min-height: 24px;
        padding: 5px 8px;
        font-size: 12px;
        margin: 5px 0;
        border-radius: 4px;
        border-radius: 4px;
      }
    }
    .upload-btn {
      position: relative;
      text-align: left;
      margin: 5px 0 0px;
      .attach-box {
        position: relative;
        text-align: left;
        svg {
          width: 30px;
          color: #607d8b;
          border: 1px solid #808080;
          border-radius: 5px;
        }
      }
      .file-detail.attach-box {
        display: inline-block;
        position: relative;
        font-size: 11px;
        width: 46%;
        height: 30px;
        padding: 0 5px 0 0;
        vertical-align: middle;
        button {
          font-size: 11px;
          min-width: 14px;
          background-color: #808080;
          height: 14px;
          color: #fff;
          position: relative;
          right: 0px;
          top: 0;
          padding: 0;
          text-align: center;
          display: inline-block;
          svg {
            vertical-align: middle;
            font-size: 11px;
          }
        }
      }
    }
    .error-msg {
      color: #ff0000;
      margin-left: 0;
    }
    button {
      &.action-btn {
        background-color: #0065ff;
        color: #fff;
        text-transform: capitalize;
        margin: 0 10px;
      }
    }
  }
}
