$whiteM: #ffffff;
$blackB: #253858;
$blackM: #303030;
$blueM: #0065ff;
$lightG: #808080;

.ticket-timeline {
  // height: 659px;
  // padding-top: 30px;
  .MuiAppBar-root {
    width: 99%;
    // background-color: #f3f3f3;
    background-color: #fff;
    color: #253858;
    box-shadow: none;
    margin: 32px 0 0 -3px;
  }
  .MuiBox-root {
    padding: 0;
  }
  .MuiTabs-root {
    min-height: auto;
    padding: 40px 10px 0;
    border-bottom: 1px solid #eaeaea;
  }
  .MuiTab-textColorPrimary {
    padding: 0 10px;
    min-width: 130px;
    color: #253858;
    text-transform: capitalize;
    border-radius: 10px 10px 0 0;
    min-height: 40px;
    position: relative;
    font-size: 14px;
    text-align: center;
    font-family: "robotomedium";
    // box-shadow: 0px 1px 6px 4px #67676714;
  }
  .MuiTab-textColorPrimary.Mui-selected {
    color: #0065ff;
    // box-shadow: 2px 1px 0px 0px #67676714;
    // background: #fff;
  }
  .PrivateTabIndicator-colorPrimary-12 {
    background-color: #0065ff;
    border-radius: 8px;
  }
  .MuiTab-wrapper {
    flex-direction: inherit;
    justify-content: center;
  }
  .comments-view {
    padding: 20px;
    h2 {
      margin-bottom: 5px;
    }
    h3 {
      margin: 0;
    }
    ul {
      li {
        box-shadow: 0px 0px 6px 1px #0e0e0e14;
        display: inline-block;
        width: 330px;
        padding: 10px 8px;
        margin-right: 10px;
        border-radius: 0 5px 5px 10px;
        position: relative;
        p {
          color: #253858;
          font-size: 12px;
          font-weight: 600;
          padding: 0 8px;
          line-height: 18px;
          span {
            position: absolute;
            right: 8px;
            top: 10px;
            font-weight: 500;
            font-size: 11px;
            color: #808080;
          }
        }
        .comment-txt {
          padding: 10px;
        }
        textarea {
          color: #808080;
          border: 1px solid #d1d1d1;
          width: 85%;
          display: block;
          margin: 10px 0 10px 10px;
          border-radius: 4px;
          font-size: 12px;
          font-weight: 400;
          color: #253858;
          line-height: 19px;
          padding: 10px;
          resize: none;
        }
      }
    }
  }
}

.activity-box {
  margin: 5px 15px;
  position: relative;
  color: $blackM;
  .activity-title {
    font-size: 18px;
    color: $blackB;
    position: relative;
    min-width: 95px;
    display: inline-block;
    &::before {
      content: "";
      border-bottom: 2px solid $blackB;
      position: absolute;
      left: -1px;
      bottom: -2px;
      height: 10px;
      padding: 5px;
      color: transparent;
      width: 20%;
    }
  }
  .activity-box {
    margin: 0 22px 0 2px;
  }
  span {
    font-size: 12px;
    line-height: 22px;
    .MuiIconButton-colorPrimary {
      background: transparent;
      color: transparent;
      border: 1px solid #0065ff;
      border-radius: 4px;
      margin: 0px 6px;
      padding: 2px;
      width: 10px;
      height: 10px;
    }
    .MuiCheckbox-colorPrimary.Mui-checked {
      background: #f9f9f9;
      color: #2196f3;
      width: 10px;
      height: 10px;
    }
    button {
      &.policy-copy-btn {
        font-size: 12px;
        text-transform: capitalize;
        color: #0052cc;
        padding: 0;
        margin-right: 16px;
        cursor: pointer;
        background: transparent;
        &:hover {
          background-color: transparent;
        }
      }
    }
    &.attach-box {
      .upload-btn {
        background-color: #cde5f8;
      }
    }
    .name-file {
      display: inline-block;
      top: 3px;
      left: 40px;
    }
  }
  .view-comments {
    text-align: left;
    padding: 0 10px;
    position: absolute;
    right: 0;
    button {
      cursor: pointer;
      padding: 5px;
      &.comment-btn {
        color: $whiteM;
        font-size: 12px;
        cursor: pointer;
        padding: 0 5px 0 10px;
        border: transparent;
        text-transform: capitalize;
        top: 44%;
        height: 45px;
        line-height: 40px;
        background: #0065ffb5;
        border-radius: 18px 0 0 18px;
        box-shadow: 0px 0px 16px #00000014;
        width: 163px;
        transition: left 0.8s ease-in-out;
        -o-transition: left 0.8s ease-in-out;
        -ms-transition: left 0.8s ease-in-out;
        -moz-transition: left 0.8s ease-in-out;
        -webkit-transition: left 0.8s ease-in-out;
        position: fixed;
        right: -120px;
        z-index: 88;
        .comment-icon {
          margin: 0 15px 0 0;
        }
        &:hover {
          background: #0065ff;
          right: 0;
          .comment-icon {
            margin: 0 5px 0 0;
          }
        }
      }
      &:hover {
        background-color: transparent;
      }
      i {
        color: #0065ff;
        font-size: 16px;
        padding: 0 0 0 5px;
      }
    }
    button.remove-file-icon {
      padding: 0;
    }
  }
  .activity-filter {
    .activity-comments {
      top: 5px;
      position: absolute;
      width: 178px;
      right: 0;
      text-align: right;
    }
    .activity-checkbox {
      position: relative;
      top: 0px;
      left: 8px;
    }
    margin: 5px 0;
    button {
      &.left-scroll-btn {
        position: absolute;
        left: -10px;
        top: 28px;
        z-index: 88;
        background-color: #ffffff;
        border: none;
        min-width: 20px;
        padding: 0px;
        border-radius: 0;
        box-shadow: none;
        height: 47px;
        svg {
          color: #253858;
        }
      }
      &.right-scroll-btn {
        position: absolute;
        right: 0;
        top: 28px;
        z-index: 9;
        background-color: #ffffff;
        border: none;
        min-width: 20px;
        padding: 0px;
        border-radius: 0;
        box-shadow: none;
        height: 47px;
        svg {
          color: #253858;
        }
      }
    }
    ul {
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      width: auto;
      margin: 0;
      padding: 10px 18px;
      li {
        margin-right: 11px;
        display: inline-block;
        width: auto;
        padding: 0;
        button {
          text-transform: capitalize;
          border: 1px solid #99d6e8;
          border-radius: 16px;
          min-width: 64px;
          color: rgb(128, 128, 128);
          background: rgb(255, 255, 255);
          font-size: 12px;
          text-align: center;
          padding: 0 15px;
          margin-right: 5px;
          height: 32px;
          position: relative;
          box-shadow: 0px 6px 6px #3469cb29;
          span {
            em {
              background: #94a1b93b;
              height: 18px;
              width: 18px;
              border-radius: 50%;
              color: #303030;
              font-size: 10px;
              padding: 2px 2px;
              position: relative;
              right: -6px;
              font-style: normal;
              text-align: center;
              line-height: 19px;
            }
          }
          &.active {
            background: $blueM;
            color: $whiteM;
            span {
              em {
                background: $whiteM;
                color: $blackB;
              }
            }
          }
        }
      }
    }
    &.logs-filter {
      ul {
        li {
          button {
            border: 1px solid #ccc;
            // opacity: 80%;
            &.active {
              background: #eef1f5;
              color: #253858;
              border: 1px solid #ccc;
              // opacity: 100%;
            }
          }
        }
      }
    }
  }
}

// email-box
.all-ticket-list {
  .email-chain {
    width: 94.6%;
    margin: 0 0 0 22px;
    // height: 470px;
    // overflow-y: scroll;
    padding: 4px 8px 10px 19px;
    .MuiExpansionPanelSummary-content.Mui-expanded {
      margin: 10px 0;
    }
    > ul {
      position: relative;
      padding: 0;
      // margin-bottom: 40px;
      &::before {
        content: ".";
        border: 2px solid #0052cc;
        position: absolute;
        left: -30px;
        top: 3px;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        padding: 0px;
        color: transparent;
        background: #c2d5f1;
      }
      > li {
        margin-bottom: 20px;
        position: relative;
        width: 100%;
        &:nth-child(2) {
          padding: 0;
        }
        &::before {
          // content: ".";
          // border: 2px solid #0052cc;
          // position: absolute;
          // left: -18px;
          // top: 0px;
          // border-radius: 50%;
          // width: 10px;
          // height: 10px;
          // padding: 0px;
          // color: transparent;
          // background: #c2d5f1;
          content: "";
          border-left: 2px solid #86ade7;
          padding: 0px 5px 4px;
          margin: 0 0 5px;
          position: absolute;
          left: -24px;
          top: 17px;
          height: 100%;
        }
        .MuiPaper-elevation1 {
          box-shadow: none;
          position: relative;
        }
        .MuiExpansionPanelSummary-root,
        .MuiExpansionPanelSummary-root.Mui-expanded,
        .MuiExpansionPanel-root:before {
          min-height: 40px;
          border-radius: 8px 8px 0 0;
          padding: 0px 12px;
          margin: 10px 0 0;
        }
        .MuiExpansionPanel-root.Mui-expanded {
          box-shadow: 0px 6px 16px #3469cb29;
          border-radius: 8px;
          .attach-box {
            position: relative;
          }
        }
        svg {
          &.arrow {
            color: $blueM;
          }
        }
        &.email-customer {
          .MuiExpansionPanelSummary-root,
          .MuiExpansionPanelSummary-root.Mui-expanded,
          .MuiExpansionPanel-root:before {
            background: #f2f7ff;
          }
          span{
            &.user-icon{
              position: absolute;
              top:42%;
              bottom:0;
              margin: auto;
            }
          }
          p{
            &.heading{
              display: inline-block;
              width: 70%;
              overflow: hidden;
              white-space: nowrap;
              line-height: 18px;
              text-overflow: ellipsis;
              padding: 3px 5px 0;
              position: absolute;
              top: 0;
              word-break: break-word;
              bottom: 0;
              @media (max-width: 768px){
                width: 50%;
              }
            }
            button{
              &.pdf-btn{
                img{
                  width: 20px;
                  height: 100%;
                }
              }
            }
          }
        }
        &.insurer {
          .MuiExpansionPanelSummary-root,
          .MuiExpansionPanelSummary-root.Mui-expanded,
          .MuiExpansionPanel-root:before {
            background: #ffdec9;
          }
        }
        &.email-sent-customer {
          .MuiExpansionPanelSummary-root,
          .MuiExpansionPanelSummary-root.Mui-expanded,
          .MuiExpansionPanel-root:before {
            background: #d9d9ff;
          }
        }
        &.chatboat {
          .MuiExpansionPanelSummary-root,
          .MuiExpansionPanelSummary-root.Mui-expanded,
          .MuiExpansionPanel-root:before {
            background-color: #a6eddd;
          }
        }
        &.outbound {
          .MuiExpansionPanelSummary-root,
          .MuiExpansionPanelSummary-root.Mui-expanded,
          .MuiExpansionPanel-root:before {
            background-color: #fffce8;
          }
        }
        strong {
          &.inbound-timer {
            color: #000000;
            font-size: 24px;
            font-weight: 300;
            line-height: 32px;
            display: inline-block;
            span {
              display: block;
              font-size: 12px;
              font-weight: 900;
              color: #253858;
              line-height: 32px;
            }
          }
        }
        span {
          &.user-bg {
            vertical-align: top;
            background-color: #d8ffeb;
            width: 56px;
            height: 56px;
            border: 1px solid #06a857;
            display: inline-block;
            text-align: center;
            line-height: 56px;
            border-radius: 50%;
            margin: 1px 15px 0 0px;
            i {
              color: #06a857;
              font-size: 26px;
              line-height: 51px;
            }
          }
        }
        .mail-timing {
          position: absolute;
          // right: 39px;
          // top: 13px;
          right: 0px;
          top: 25%;
          color: $blackB;
          font-size: 12px;
          font-weight: 700;
          line-height: 16px;
          bottom:0;
          a {
            &.recording-box {
              padding: 0 8px;
              position: relative;
              top: -1px;
            }
          }
        }
        .mail-heading {
          border-bottom: 1px solid #eaeaea;
          padding: 0 0 5px;
          margin: 0 0 0px;
          position: relative;
          width: 100%;
          p {
            font-size: 12px;
            font-weight: 700;
            color: $blackB;
            padding: 5px 0 0;
            margin: 0;
          }
          span {
            &.from-to {
              color: $blueM;
              font-size: 12px;
              font-weight: 400;
              padding: 5px 2px;
              white-space: break-spaces;
              word-break: break-all;
            }
          }
          .link-comments {
            text-align: right;
            padding: 5px 0;
            i {
              font-size: 22px;
              margin: 0px 7px;
              transform: rotate(-90deg);
              vertical-align: middle;
            }
            .comments-tag {
              border: 1px solid #eaeaea;
              border-radius: 21px;
              background: $whiteM;
              display: inline-block;
              width: 48px;
              height: 30px;
              text-align: center;
              padding: 0;
              cursor: pointer;
              line-height: 16px;
              position: relative;
              em {
                background: #e84444;
                border-radius: 50%;
                color: $whiteM;
                font-size: 10px;
                font-weight: 400;
                display: block;
                position: absolute;
                width: 16px;
                height: 16px;
                right: 0;
                top: -5px;
                font-style: normal;
              }
              img {
                margin: 6px 0 0;
              }
            }
          }
          .send-to-insurer {
            text-align: right;
            font-size: 10px;
            margin: -5px 0;
            span {
              font-size: 12px;
              svg {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
        .inbound-data-box {
          box-shadow: 0px 6px 16px #3469cb29;
          margin: 20px 0;
          border-radius: 0 0 8px 8px;
          .innboud-call-heading {
            background-color: #ebe3ff;
            padding: 10px 15px;
            border-radius: 8px 8px 0 0;
            ul {
              &::before {
                content: none;
              }
              &.inbound-call-option {
                text-align: right;
                margin: 0;
                padding: 5px 0;
                li {
                  display: inline-block;
                  width: auto;
                  margin: 0 15px;
                  vertical-align: middle;
                  padding: 0;
                  text-align: center;
                  &::before {
                    content: none;
                  }
                  button {
                    background-color: $whiteM;
                    box-shadow: 0px 6px 16px #3469cb29;
                    min-width: 32px;
                    height: 32px;
                    margin-bottom: 5px;
                    padding: 0;
                    cursor: pointer;
                    &.call-end-btn {
                      min-width: 48px;
                      height: 48px;
                    }
                    .mic-on {
                      display: block;
                      margin: 10px;
                      height: 13px;
                      font-size: 18px;
                      .calender-icon,
                      .call-end-btn {
                        font-size: 16px;
                        color: $lightG;
                      }
                    }
                    span {
                      .mute-btn {
                        display: none;
                        .mic-off {
                          color: #d31414;
                          font-size: 26px;
                          margin: 7px 0 0;
                        }
                        .call-spilt {
                          color: #2196f3;
                          font-size: 26px;
                          margin: 7px 0 0;
                        }
                        .calender-icon {
                          color: #ff9800;
                          font-size: 26px;
                          margin: 7px 0 0;
                        }
                        .call-end-btn {
                          color: #d31414;
                          font-size: 28px;
                          margin: 7px 0 0;
                        }
                      }
                    }
                    &.active {
                      min-width: 48px;
                      height: 48px;
                      span {
                        .mute-btn {
                          display: block;
                        }
                      }
                      .mic-on {
                        display: none;
                      }
                    }
                  }
                  span {
                    font-size: 10px;
                    display: block;
                    font-weight: 300;
                    color: $blackB;
                  }
                }
              }
            }
          }
          .inbound-body-data {
            padding: 15px;
          }
        }
        span {
          &.email-tag {
            font-size: 12px;
            border-radius: 4px;
            display: inline-block;
            margin: 0 10px 0 0;
            height: 20px;
            min-width: 60px;
            text-align: center;
            padding: 2px 10px;
            line-height: 21px;
            font-family: "robotobold";
            &.email {
              background: #dcf6e2;
              color: #4daa51;
            }
            &.inbound {
              color: #be8609;
              background-color: #fee4c0;
            }
            &.my-account {
              background-color: #ffc9c9;
              color: #a55151;
            }
            &.chatbot {
              background-color: #4086a7;
              color: #deebf1;
            }
            &.outbound {
              background-color: #ffffcc;
              color: #888800;
            }
            &.endorsement {
              background-color: #e8c4fc;
              color: #9f4fcb;
            }
            &.esc {
              color: $whiteM;
              background-color: #ff7474;
              padding: 2px 5px;
              em {
                color: $whiteM;
                font-size: 22px;
                font-style: normal;
                vertical-align: bottom;
                padding: 0 3px 0 2px;
              }
            }
          }
        }
        .mail-data {
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          color: $blackB;
          padding: 10px 5px;
          p {
            margin: 5px 0;
            font-size: 16px;
          }
          .downlaod-item {
            border: 1px solid #eaeaea;
            position: relative;
            padding: 10px 0px;
            width: 148px;
            display: inline-block;
            border-radius: 8px;
            height: 42px;
            vertical-align: middle;
            margin: 10px 8px 5px 0;
            // box-shadow: 0px 0px 3px 1px #3469cb29;
            .file-extn {
              position: absolute;
              left: 10px;
              top: 9px;
              img {
                width: 22px;
                margin: 1px 0 0;
              }
            }
            p {
              padding: 3px 0 0 45px;
              color: #303030;
              font-size: 12px;
              font-weight: 400;
              line-height: 14px;
              span {
                &.doc-type {
                  font-size: 12px;
                  color: $lightG;
                  display: block;
                  font-weight: 400;
                }
              }
            }
          }
          .audio-box {
            border: 1px solid #eaeaea;
            border-radius: 8px;
            width: 120px;
            padding: 10px 8px;
            margin: 10px 0;
            max-width: 100%;
            position: relative;
            a {
              text-decoration: underline;
              button {
                min-width: auto;
                color: #0065ff;
                font-size: 12px;
                line-height: 16px;
                padding: 0 5px 0 0;
                img {
                  margin-right: 3px;
                }
              }
            }
            button {
              min-width: 50px;
            }
            .player {
              position: relative;
              top: 0;
              width: 270px;
              max-width: 80%;
              display: inline-block;
              left: -5px;
              .MuiLinearProgress-colorPrimary {
                border-radius: 4px;
              }
              .MuiLinearProgress-barColorPrimary {
                background-color: $blueM;
              }
            }
            .audio-progress {
              max-width: 100%;
            }
            .time {
              display: flex;
              position: absolute;
              right: 13px;
              top: 34%;
              font-size: 12px;
              color: #4887f7;
            }
          }
          table {
            pre {
              white-space: pre-wrap; /* Since CSS 2.1 */
              white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
              white-space: -pre-wrap; /* Opera 4-6 */
              white-space: -o-pre-wrap; /* Opera 7 */
              word-wrap: break-word; /* Internet Explorer 5.5+ */
              table{
                tr{
                  td{
                    p{
                      font-size: 12px;
                      font-family: "robotoregular";
                    }
                  }
                }
              }
            }
          }
        }
        button {
          text-transform: capitalize;
          // box-shadow: 0px 6px 6px #3469cb29;
          &.response-chip {
            border: 1px solid #eaeaea;
            font-size: 12px;
            padding: 6px 10px;
            display: inline-block;
            border-radius: 38px;
            margin: 3px 3px 0;
            box-shadow: 0px 4px 7px 0px #5050502e;
          }
          &:hover {
            background-color:#f2f7ff;
            color:#253858;
          }
          &.active {
            box-shadow: 0px 6px 6px #3469cb29;
            border: 1px solid #4887f7b8;
            span {
              color: #0065ff;
            }
          }
        }
        .create-update {
          text-align: right;
          padding-right: 1px;
        }
        .ticket-status {
          border: 1px solid #eaeaea;
          width: 349px;
          border-radius: 16px 16px 16px 0px;
          position: relative;
          padding: 15px 0 0 70px;
          margin: 20px 0 10px;
          max-width: 75%;
          min-height: 77px;
          .ticket-time {
            position: absolute;
            right: 10px;
            top: 14px;
            font-size: 12px;
            color: $blackB;
          }
          h6 {
            margin: 0 0 5px;
            font-size: 12px;
            font-weight: 600;
            color: $blackB;
          }
          p {
            margin-bottom: 5px;
            font-size: 12px;
            color: $blackM;
            a {
              color: $blueM;
            }
          }
          button {
            min-width: auto;
            cursor: pointer;
            &.reply-btn {
              color: $blueM;
              margin-bottom: 10px;
              font-size: 12px;
              padding: 0;
            }
          }
          .employe-name {
            width: 43px;
            height: 26px;
            display: block;
            text-align: center;
            background: #fda62f4d;
            border-radius: 50%;
            padding: 10px 0;
            font-size: 14px;
            position: absolute;
            left: 10px;
            line-height: 24px;
          }
        }
        .inner-ticket-status {
          width: 339px;
          position: relative;
          padding: 15px 0 0 70px;
          margin: 10px 0 30px 10px;
          max-width: 74%;
          border: none;
          border-left: 1px solid #eaeaea;
          border-radius: 0;
          .employe-name {
            background: #2ffd7e4d;
          }
        }
        &.pb-reply {
          &::before {
            display: none;
          }
        }
      }
    }
    .logs-block {
      margin: 15px 0 0;
      &::before {
        display: none;
      }
      li {
        &::before {
          display: none;
        }
      }
    }
    .sub-status {
      background: #a0b0c633;
      border-radius: 8px;
      padding: 15px 10px;
      position: relative;
      margin: 0px 0 0 10px;
      width: 97.6%;
      border: 1px solid #ddd;
      opacity: 0.9;
      /* display: none; */
      &::before {
        position: absolute;
        background: #a0b0c6;
        width: 8px;
        height: 8px;
        content: "";
        left: -16px;
        top: 46%;
        border-radius: 50%;
      }
      &:hover {
        &::before {
          background: #0065ff;
        }
        border: 1px solid #0065ff;
        opacity: 1;
      }
      .status-date-time {
        position: absolute;
        right: 8px;
        top: 3px;
        color: $blackB;
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
      }
      .status-txt {
        position: relative;
        h5 {
          font-size: 14px;
          color: $blackB;
          line-height: 20px;
          font-weight: 300;
          margin: 0 0 10px;
          width: 84%;
          strong {
            font-weight: 700;
          }
        }
        p {
          font-size: 12px;
          color: $blackB;
          font-weight: 300;
          line-height: 16px;
          margin: 0 0 10px;
          strong {
            font-weight: 700;
          }
        }
      }
    }
    .inbound-call-data {
      .MuiAppBar-colorDefault {
        background-color: transparent;
        box-shadow: none;
        button {
          box-shadow: none;
        }
      }
      .tab-pannel {
        border-top: 2px solid #eaeaea;
        .MuiBox-root {
          padding: 0;
        }
        .enter-remarks {
          textarea {
            border: 1px solid #5e6c84;
            font-family: "Roboto", sans-serif;
            border-radius: 8px;
            width: 96%;
            padding: 10px;
            margin: 10px auto;
            display: block;
            resize: none;
          }
          .submit-box {
            padding: 10px;
            text-align: right;
            button {
              cursor: pointer;
              &.submit-btn {
                border: 1px solid $blueM;
                min-width: 120px;
                height: 36px;
                background-color: $blueM;
                text-align: center;
                span {
                  color: $whiteM;
                }
              }
            }
          }
        }
        .payment-detail {
          textarea {
            border: 1px solid #5e6c84;
            font-family: "Roboto", sans-serif;
            border-radius: 8px;
            width: 96%;
            padding: 10px;
            margin: 10px auto;
            display: block;
            resize: none;
          }
          .submit-box {
            padding: 10px;
            text-align: right;
            button {
              cursor: pointer;
              &.submit-btn {
                border: 1px solid $blueM;
                min-width: 120px;
                height: 36px;
                background-color: $blueM;
                text-align: center;
                span {
                  color: $whiteM;
                }
              }
            }
          }
        }
      }
    }
    .spinner-loader {
      position: relative;
      left: 50%;
      right: 50%;
      margin: auto;
      width: 100%;
      top: 0;
      svg {
        color: $blueM;
      }
    }
    button {
      &.pdf-generate-btn {
        padding: 5px 15px;
        min-width: 120px;
        border-radius: 22px;
        border: 1px solid #d4423d;
        position: relative;
        margin: 10px 0;
        left: -56px;
        top: -11px;
        img {
          max-width: 20px;
          margin-right: 5px;
        }
        span {
          font-size: 12px;
        }
        &:hover {
          border: 1px solid #ff0000;
          background-color: transparent;
        }
      }
      &.compose-btn{
        background-color: #0065ff;
        color: #fff;
        min-width: auto;
        padding: 5px 10px;
        font-size: 12px;
        text-transform: capitalize;
        margin: 8px 0;
        cursor: pointer;
      }
      &.compose-close-btn{
        background-color: #a3a1a1;
        color: #fff;
        min-width: 80px;
        padding: 5px 10px;
        font-size: 12px;
        text-transform: capitalize;
        margin: 8px 0 0;
        cursor: pointer;
      }
    }
  }
}

