// reply-popup
.reply-popup {
  margin: 25px 0 10px;
  padding: 20px 20px 15px;
  box-shadow: 0px 2px 8px 4px #6767675c;
  border-radius: 5px;
  .MuiDialog-paperWidthMd {
    width: 650px;
    max-width: 100%;
    padding: 10px 15px 0 5px;
    border-radius: 15px;
  }
  .MuiDialog-paperWidthXs {
    width: 400px;
    max-width: 100%;
    padding: 10px 15px 0 5px;
    border-radius: 5px;
    .Component-root-39 {
      padding: 10px 5px;
      h6 {
        font-size: 18px;
        line-height: 20px;
        font-weight: 500;
      }
      .Component-root-69 {
        margin: 0;
        padding: 7px;
      }
    }
    button {
      border-radius: 8px;
      text-transform: capitalize;
      &.discard-btn {
        border: 1px solid #0065ff;
        color: #0065ff;
        margin: 10px 5px;
        min-width: 120px;
        height: 36px;
        &:hover {
          color: #0065ff;
        }
      }
      &.send-btn {
        min-width: 120px;
        height: 36px;
        background-color: #0065ff;
        color: #fff;
        &:hover {
          background-color: #0065ff;
          color: #fff;
        }
      }
    }
  }
  .Component-root-49 {
    padding: 0 16px;
  }
  .Component-root-34 {
    padding: 0 15px;
    h6 {
      color: 253858;
      font-size: 16px;
    }
  }
  .MuiOutlinedInput-input {
    padding: 12.5px 14px;
    font-size: 12px;
    color: #253858;
    text-transform: capitalize;
  }
  .MuiInputLabel-outlined {
    top: -5px;
    font-size: 12px;
    color: #253858;
    opacity: 1;
    min-width: auto;
    text-transform: capitalize;
  }
  .MuiSelect-select.MuiSelect-select,
  .MuiSelect-select:focus {
    background-color: #fff;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    top: 1px;
    font-size: 14px;
    background-color: #fff;
  }
  .MuiSelect-select:focus {
    background-color: transparent;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $blueM;
    border-width: 1px;
  }
  .mail-to-info {
    margin-top: 5px;
    .border-bottom {
      border-bottom: 1px solid #eaeaea;
      padding: 5px 0;
      strong {
        font-size: 12px;
        color: #808080;
        line-height: 19px;
        font-weight: 700;
        span {
          font-size: 12px;
          font-weight: 400;
          color: #253858;
          padding-left: 1px;
          
        }
        input{
          font-size: 12px;
          font-weight: 400;
          color: #253858;
          display: block;
          width:500px;
        }
        input{
          font-size: 12px;
          font-weight: 400;
          color: #253858;
          display: block;
          width:500px;
        }
      }
      .mail-to-input {
        display: block;
        width: 100%;
        position: relative;
        padding: 0;
        .lable-to {
          position: absolute;
          top: 7px;
          left: 0px;
          color: #808080;
          font-weight: 700;
        }
        .MuiFormControl-root {
          width: 99%;
          .MuiInputBase-input {
            font-size: 12px;
            font-weight: 400;
            color: #253858;
            padding: 9px 2px 0 24px;
          }
        }
        .MuiInput-underline:before,
        .MuiInput-underline:after {
          border-bottom: none;
        }
        .MuiFormHelperText-root.Mui-error {
          margin: 0;
        }
        p{
          &.error-msg{
            color:#ff0000;
          }
        }
      }
    }	
    .reply-box {
      padding: 10px 0;
      font-size: 12px;
      color: #253858;
      line-height: 19px;
      position: relative;
      .enter-variable-btn{
        position: relative;
        top: 0;
        text-transform: capitalize;
        padding: 5px 0;
        margin: auto;
        width:100%;
        text-align: right;
        .MuiFormGroup-root{
          display: inline-block;
          .MuiCheckbox-root {
            color: #408bff;
            padding: 3px 5px;
          }
          svg{
            font-size: 18px;
          }
        }
        .MuiTypography-root{
          font-size: 12px;
        }
        button{
          background-color: #408bff;
          color: #fff;
          font-size: 11px;
          font-weight: 500;
          padding: 6px 9px;
        }
      }
      .update-field{
        text-align: right;
        margin: 10px 0;
        button{
          &.update-field-btn{
            background-color: #408bff;
            color: #fff;
            font-size: 11px;
            font-weight: 500;
            padding: 6px 9px;
          }
        }
      }
    }
    &.compose-mail-box{
      position: relative;
      width: 100%;
      .border-bottom {
        .mail-to-input{
          .MuiFormControl-root{
            width:100%;
            .MuiInputBase-input{
              width: 100%;
              display: block;
              padding:4px 0 0 25px;
            }
          }
          &.mail-to-subject-input{
            .MuiInputBase-input{
              width: 100%;
              display: block;
              padding:4px 0 0 54px;
            }
          }
        }
      }
      label{
        &.label-to{
          position: absolute;
          left: 0;
          font-weight: 600;
          color: #808080;
          top:2px;
        }
      }
      
    }
  }
  .action-bar {
    position: relative;
    width: 100%;
    text-align: right;
    .attach-btn {
      // position: absolute;
      display: block;
      left: 5px;
      top: 10px;
      // padding: 0 0 0 45px;
      padding: 0;
      border-radius: 4px;
      // width:70%;
      text-align: left;
      span {
        &.attach-box {
          position: absolute;
          left: 0;
					top: 6px;
					margin:5px 0;
					.upload{
						width: 37px;
						height: 32px;
						top: 0;
						left: 0;
					}
        }
      }
      button {
        min-width: auto;
        padding: 0 4px;
        color: #0065ff;
        // background-color:#EAEAEA;
        i {
          font-size: 18px;
          margin: 0px;
          transform: rotate(-90deg);
          vertical-align: middle;
          color: #253858;
        }
        &:hover {
          text-decoration: none;
          background-color: transparent;
        }
      }
      span {
        font-size: 12px;
        color: #253858;
        line-height: 16px;
        padding: 0 4px 0 0;
      }
      .file-detail {
        // display: inline-block;
        // position: relative;
        // font-size: 11px;
        // line-height: 18px;
        // width: auto;
        // height: 18px;
        // padding: 4px 5px 0 6px;
        // vertical-align: super;
        display: inline-block;
        position: relative;
        font-size: 11px;
        line-height: 23px;
        width: auto;
        height: 24px;
        padding: 2px 10px;
        vertical-align: super;
        background: #cfcfcf7d;
        border-radius: 20px;
        margin: 5px 3px 0;
        text-transform: lowercase;
        button {
          font-size: 10px;
          min-width: 14px;
          background-color: #808080;
          height: 14px;
          color: #fff;
          position: relative;
          right: 0px;
          top: 0;
          padding: 0;
          text-align: center;
					display: inline-block;
					line-height: 10px;
          svg {
            vertical-align: bottom;
            font-size: 10px;
          }
        }
      }
      button {
        &.reply-upload {
          color: #fff;
          background: #408bff;
          min-width: 30px;
          padding: 0;
          height: 26px;
          text-align: center;
          span {
            padding: 0;
          }
          svg {
            font-size: 22px;
            color: #fff;
          }
        }
      }
      
    }
    button {
      border-radius: 8px;
      min-width: 94px;
      height: 32px;
      &.discard-btn {
        border: 1px solid #0065ff;
        color: #0065ff;
        margin: 10px 12px;
        &:hover {
          color: #0065ff;
        }
      }
      &.send-btn {
        background-color: #0065ff;
        color: #fff;
        svg{
          color:#fff;
        }
        &:hover {
          background-color: #0065ff;
          color: #fff;
        }
      }
    }
    &.compose-box-action-bar{
     .attach-btn {
      span{
        &.attach-box{
          margin:0;
          top:2px;
          button{
            &.upload-btn{
              height: 24px;
            }
          }
        }
      }
      .file-detail{
        margin: 2px 0 0 32px;
      }
     }
    }
  }
  .cke_contents {
    height: 320px !important;
  }
}
