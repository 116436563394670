// topbar-data
.topbar-data {
  width: 100%;
  position: absolute;
  text-align: right;
  top: 0;
  right: 2px;
  z-index: 99;
  .ticket-id-flag {
    position: absolute;
    left: 17px;
    top: 0px;
    vertical-align: middle;
    padding: 3px 0 0;
    z-index: 999;
    width: 95%;
    text-align: left;
    h5 {
      font-size: 18px;
      color: $blackB;
      position: relative;
      min-width: 100px;
      display: block;
      margin: 0 0 12px;
      font-weight: 500;
      padding: 0 5px;
      text-align: left;
      font-family: "robotomedium";
      &::before {
        content: "";
        border-bottom: 2px solid $blackB;
        position: absolute;
        left: 8px;
        bottom: -2px;
        height: 10px;
        padding: 5px;
        color: transparent;
        width: 25px;
      }
      span {
        color: #0065ff;
        font-weight: 600;
        &::after {
          content: "";
        }
      }
    }
    button {
      border: 1px solid #deebff;
      font-size: 12px;
      min-width: 15px;
      background: #fff;
      color: #0065ff;
      font-weight: 400;
      &.reopen {
        border: 1px solid #ffab00;
        border: transparent;
        color: #ffab00;
        position: relative;
        // &:after{
        //     content: ">>";
        //     position: absolute;
        //     right: 6px;
        //     height: 40%;
        //     top:50%;
        //     width: 2px;
        // }
      }
      &.escalated {
        // border: 1px solid #DE350B;
        // border:transparent;
        color: #de350b;
        position: relative;
      }
      &.journey {
        color: #3498db;
      }
      &.tat-esc {
        // border:transparent;
        color: #d63560;
        position: relative;
      }
      &.topmgnt {
        color: #f1948a;
      }
      &.hcs {
        color: #ec7063;
      }
      &.social {
        color: #5dade2;
      }
      &.cgo {
        color: #2e86c1;
      }
      &.Irda {
        color: #3498db;
      }
      &.krg {
        color: #4daa51;
      }
      &:hover {
        background: transparent;
      }
      &:focus {
        background: transparent;
      }
      &.block-com {
        background: transparent;
        border: transparent;
        box-shadow: none;
        &:hover {
          background: transparent;
        }
      }
      &.action-btn {
        span {
          font-size: 12px;
        }
        strong {
          &.ticket-source {
            padding: 0 0 0 5px;
            color: #4daa51;
            font-weight: 500;
          }
        }
      }
      &.bucket-name-btn {
        color: #0065ff;
        border-radius: 22px;
        span {
          font-size: 12px;
          svg {
            color: #2196f3;
            font-size: 16px;
            margin: 0 0 0 3px;
          }
        }
        &:hover {
          span {
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }
    .block-communication {
      font-size: 11px;
      font-weight: 600;
      position: relative;
    }
  }
  button {
    height: 30px;
    min-width: 117px;
    padding: 0 10px;
    text-align: center;
    margin: 0 5px;
    border-radius: 5px;
    text-transform: capitalize;
    background-color: #deebff;
    font-size: 10px;
    color: #0065ff;
    img {
      margin: 0 5px 0 0;
    }
    i {
      padding: 0 5px;
    }
    &.action-btn {
      border-radius: 16px;
      cursor: pointer;
    }
    &:hover {
      background-color: #deebff;
    }
    &.read-only-user {
      background-color: #0065ff;
      border-radius: 22px;
      color: #fff;
      font-size: 12px;
      padding: 0 15px 0 10px;
      margin: 0px 5px 0;
      box-shadow: 4px 6px 6px -3px #0a183329;
      height: 33px;
    }
  }
  ul {
    padding: 0;
    z-index: 9999;
  }
}

.topbar-data-new {
  position: absolute;
  top: 4px;
  right: 10px;
  z-index: 99;
  width: 100%;
  .ticket-id-flag-new {
    // position: absolute;
    left: 17px;
    top: 0px;
    vertical-align: middle;
    padding: 3px 15px 0;
    h5 {
      font-size: 18px;
      color: $blackB;
      position: relative;
      min-width: 100px;
      display: block;
      margin: 0 0 12px;
      font-weight: 500;
      padding: 0 5px;
      font-family: "robotomedium";
      &::before {
        content: "";
        border-bottom: 2px solid #253858;
        position: absolute;
        left: 8px;
        bottom: -2px;
        height: 10px;
        padding: 5px;
        color: transparent;
        width: 27px;
      }
      span {
        color: #0065ff;
        font-weight: 600;
        &::before {
          content: "";
        }
      }
    }
    button {
      border: 1px solid #deebff;
      font-size: 12px;
      min-width: 15px;
      background: #fff;
      color: #0065ff;
      font-weight: 400;
      &.reopen {
        border: 1px solid #ffab00;
        border: transparent;
        color: #ffab00;
        position: relative;
        // &:after{
        //     content: ">>";
        //     position: absolute;
        //     right: 6px;
        //     height: 40%;
        //     top:50%;
        //     width: 2px;
        // }
      }
      &.escalated {
        // border: 1px solid #DE350B;
        // border:transparent;
        color: #de350b;
        position: relative;
      }
      &.tat-esc {
        // border:transparent;
        color: #d63560;
        position: relative;
      }
      &.topmgnt {
        color: #f1948a;
      }
      &.hcs {
        color: #ec7063;
      }
      &.social {
        color: #5dade2;
      }
      &.cgo {
        color: #2e86c1;
      }
      &.Irda {
        color: #3498db;
      }
      &.krg {
        color: #4daa51;
      }
      &:hover {
        background: transparent;
      }
      &:focus {
        background: transparent;
      }
      &.block-com {
        background: transparent;
        border: transparent;
        box-shadow: none;
        &:hover {
          background: transparent;
        }
      }
      &.action-btn {
        span {
          font-size: 12px;
        }
        strong {
          &.ticket-source {
            padding: 0 0 0 5px;
            color: #4daa51;
            font-weight: 500;
          }
        }
      }
    }
    .block-communication {
      font-size: 11px;
      font-weight: 600;
      position: relative;
    }
  }
  button {
    height: 30px;
    min-width: 117px;
    padding: 0 10px;
    text-align: center;
    margin: 0 5px;
    border-radius: 5px;
    text-transform: capitalize;
    background-color: #deebff;
    font-size: 10px;
    color: #0065ff;
    img {
      margin: 0 5px 0 0;
    }
    i {
      padding: 0 5px;
    }
    &.action-btn {
      border-radius: 16px;
      cursor: pointer;
    }
    &:hover {
      background-color: #deebff;
    }
    &.read-only-user {
      background-color: #0065ff;
      border-radius: 22px;
      color: #fff;
      font-size: 12px;
      padding: 0 15px 0 10px;
      margin: 10px 5px 0;
      box-shadow: 4px 6px 6px -3px #0a183329;
      height: 37px;
    }
  }
  ul {
    padding: 0;
    &.ticket-btn-box {
      position: absolute;
      top: 8px;
      right: 0px;
    }
  }
}
