//sme-emplyee
.sme-emplyee{
    background-color: #fff;
    h1{
        font-size: 18px;
        font-weight: 600;
        margin: 10px 5px 25px;
        padding: 15px;
        box-shadow: 1px 0px 9px -3px #00000029;
        border-radius: 5px 5px 0 0;
        a{
            color: #253858;
            
        }
        i{
            color:#0065ff;
        }
    }
    h3{
        color:#253858;
        font-size: 18px;
        font-weight: 600;
        margin: 10px 5px 25px;
        padding-bottom: 15px;
    }
    padding: 15px;
    margin: 0;
    height: 98vh;
    .MuiDialog-paperFullScreen {
      border-radius: 5px 0 0 5px;
    }
    .MuiDialogContent-root{
      padding:8px 15px;
    }
    .MuiAppBar-colorPrimary {
      background-color: #00458b;
    }
    .MuiFormControl-marginNormal {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .MuiToolbar-regular {
      min-height: 50px;
      padding: 0;
    }
    .MuiOutlinedInput-input {
      padding: 14.5px 14px;
      font-size: 12px;
      color: #253858;
      // text-transform: capitalize;
      background: #deebff;
      border-radius: 22px;
    }
    .MuiInputLabel-outlined {
      top: -5px;
      font-size: 12px;
      color: #253858a1;
      opacity: 1;
      min-width: auto;
      text-transform: capitalize;
    }
    .MuiSelect-select.MuiSelect-select,
    .MuiSelect-select:focus {
      background-color: #fff;
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      top: 1px;
      font-size: 14px;
      // background-color: #fff;
      color: #808080;
    }
    .MuiSelect-select:focus {
      background-color: transparent;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #ccc;
      border-width: 1px;
    }
    .form-control-box{
      width:100%;
      position: relative;
      margin-bottom: 15px;
      button {
        &.search-btn {
          background-color: #5392FF;
          border: transparent;
          color: #fff;
          border-radius: 4px;
          min-width: 90px;
          height:43px;
          line-height: 42px;
          padding: 0;
          text-align: center;
          text-transform: uppercase;
          margin: 0;
          font-size: 14px;
          font-weight: 500;
          border-radius: 22px;
          right: 0;
          position: absolute;
          em{
            font-style: normal;
          }
        }
        .MuiOutlinedInput-notchedOutline ,.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
          border-color: #ccc;
          border-radius: 22px;
        }
        img{
          margin:0 5px;
          width:16px;
        }
      }
      input:-internal-autofill-selected {
        background-color: transparent;
        border-radius: 22px;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: transparent;
        border-radius: 22px;
      }
    }
    
    .border-bottom {
      border-bottom: 1px solid #eaeaea;
      padding: 0 0 30px;
    }
    h2 {
      margin: 40px 0 0;
    }
    ul {
      .MuiFormGroup-root {
        display: block;
      }
      li {
        border: 1px solid #5e6c84;
        border-radius: 8px;
        height: 46px;
        line-height: 56px;
        padding: 0 15px;
        margin: 0px 8px 0 0;
        font-size: 12px;
        width: 46%;
        display: inline-block;
        line-height: 46px;

        .MuiTypography-body1 {
          font-size: 12px;
          line-height: 16px;
          color: #253858;
        }
        .MuiFormControlLabel-root {
          width: 100%;
          margin-right: 0;
        }
      }
    }
    .sme-form-data{
      margin-top: 25px;
      border-top:1px solid #ccc;
      padding-top: 10px;
    }
    .table-head{
        .MuiTableCell-head{
            background-color: #5392FF;
            padding:5px 10px;
        }
    }
    .t-first{
        width:150px;
    }
    .t-padding{
        padding: 10px;
    }
    .table-body{
        padding:0px;
        th{
            padding:5px 10px !important; 
        }
        .WithStyles\(ForwardRef\(TableCell\)\)-body-16{
            padding: 10px 10px;
        }
        &:nth-child(odd){
            background-color: #9e9e9e2b !important;
            
        }
        button{
            &.create-btn{
                background-color: #0065ff;
                min-width: 50px;
                height: 22px;
                text-transform: capitalize;
                cursor: pointer;
                span{
                    font-size: 12px;
                    color:#fff;
                }
            }
        }
    }
  .no-data-found{
    text-align: center;
    padding: 30px 20px;
    p{
      font-size: 16px;
      line-height: 20px;
      padding: 10px;
    }
  }
}
.sme-detail{
  span{
    &.loader-spinner{
      position: relative;
      left: 0;
      right: 0;
      margin: 27px auto 0;
      width: 100%;
      top: -12px;
      color: #fff;
      svg{
        color:#fff;
      }
    }
    &.mannual-loader{
      position: relative;
      left: -5px;
      right: 0;
      margin: 0px auto 0;
      width: 100%;
      top: 0px;
      color: #fff;
      svg{
        color:#0065ff;
      }
    }
  }
  
}


.sme-detail-data {
  .errror-msg{
    color: #ff0000;
    padding: 0 8px;
    font-size: 11px;
  }
}




@media all and (max-width: 768px){
  .sme-emplyee{
    .MuiInputLabel-outlined{
        font-size: 10.5px;
    }
    .form-control-box {
      button{
        &.search-btn{
          min-width:68px;
          em{
            display: none;
          }
        }
      }
    }
  }
}