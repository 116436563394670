// login page
.login-page {
  // padding: 20px;
  // margin: 0 auto;
  // height:100vh;
  // background-color: #f6fcff;
  // .login-box {
  //   margin: 10% auto;
  //   width: 350px;
  //   max-width: 100%;
  //   border-radius: 3px;
  //   padding: 10px;
  //   background-color: #fff;
  //   min-height: 300px;
  //   box-shadow: 1px 7px 6px 0px #00000063;
  //   h2 {
  //     text-align: center;
  //     background-color: #03a9f4;
  //     display: block;
  //     padding: 15px 0;
  //     font-size: 18px;
  //     color: #fff;
  //     border-radius: 3px 3px 0 0;

  //   }
  //   .form-control {
  //     width: 100%;
  //   }
  //   button{
  //       &.signin-btn{
  //           background-color: #03a9f4;
  //           color: #fff;
  //           font-size: 14px;
  //           font-weight: 600;
  //           border: none;
  //           margin:20px auto;
  //           display: block;
  //           height: 35px;
  //           text-transform: capitalize;
  //           cursor: pointer;
  //       }
  //   }
  //   // .text-center{
  //   //     text-align: center;
  //   // }
  // }
  .login-box {
    margin: auto;
    width: 392px;
    height: 500px;
    max-width: 100%;
    border-radius: 3px;
    padding: 10px;
    background-color: #fff;
    min-height: 300px;
    padding: 30px;
    position: absolute;
    top: -50%;
    bottom: -50%;
    box-shadow: 0px 6px 16px #3469cb29;

    h2 {
      text-align: center;
      display: block;
      padding: 15px 0;
      font-size: 22px;
      color: #195795;
      border-radius: 3px 3px 0 0;
      font-weight: 600;
    }

    .form-control {
      width: 100%;
    }

    button {
      &.signin-btn {
        background-color: #108df1;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        border: none;
        margin: 20px auto;
        display: block;
        height: 40px;
        text-transform: capitalize;
        cursor: pointer;
        min-width: 123px;
      }
    }

    // .text-center{
    //     text-align: center;
    // }
  }

  .error-msg {
    color: #ff0000;
    text-align: center;
  }
}

.Login-error-msg {
  color: #ff0000;
  text-align: center;
  animation-name: shake;
  animation-duration: 1s;
}

.Login-error-msg1 {
  color: #ff0000;
  text-align: center;
}

@keyframes shake {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-2deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(3deg);
  }

  30% {
    transform: translate(0px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(2px, 1px) rotate(-2deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(4deg);
  }

  90% {
    transform: translate(2px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

// minimum-layout
.minimum-layout {
  z-index: 99;
  background: #fff;
  width: 100%;
  text-align: right;
  min-height: 45px;
  top: 0;
  left: auto;
  right: 0;
  position: fixed;
  box-shadow: 0px 3px 6px #00000029;

  .logo {
    position: absolute;
    left: 23px;
    top: 5px;
    max-width: 94px;
  }
}

// profile-box
.profile-box {
  // position: fixed;
  border-radius: 0 0 0 5px;
  min-width: 80px;
  text-align: right;
  padding: 0;
  box-shadow: 2px 3px 3px 1px #00000029;
  z-index: 99;
  padding: 8px 10px;

  ul {
    li {
      margin: 0;
      color: #2196f3;
      width: auto !important;
      padding: 8px 5px !important;

      i {
        background: #2196f3;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        color: #fff;
        border-radius: 10px;
      }
    }
  }

  i {
    background: #2196f3;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    color: #fff;
    border-radius: 10px;
    margin-left: 8px;
  }

  button {
    &:hover {
      // background-color: #2196f3;
      background-color: #2196f3fa;
      border-radius: 22px;
      color: #fff;
    }
  }

  h3 {
    font-family: "robotobold";
    font-size: 14px;
  }

  button {
    &.create-ticket-btn {
      text-transform: capitalize;
      background-color: #d2e4ff;
      font-size: 12px;
      color: #0065ff;
      border-radius: 22px;
      height: 30px;
      margin: 0 16px 0 0;
      padding: 0 15px;
      font-weight: 500;
      box-shadow: 1px 3px 3px 1px #00000029;

      img {
        max-width: 16px;
      }
    }
  }
}

// // login-topbar
// .login-topbar{
//   z-index: 99;
//   background: #fff;
//   width: 100%;
//   text-align: right;
//   min-height: 45px;
//   top: 0;
//   left: auto;
//   right: 0;
//   position: fixed;
//   box-shadow:0px 3px 6px #00000029;
//   .login-info{
//     padding: 10px;
//     p{
//       color:#253858;
//       span{
//         color:#108df1
//       }
//     }
//   }
// }

// side-bar
.sidebar {
  background-color: #f2f7ff;
  padding: 32px 15px 0;
  position: fixed;
  top: 0;
  height: 100vh;

  ul {
    padding: 30px 0 0;

    li {
      padding: 4px 0;
      text-align: center;
      margin-bottom: 10px;

      a {
        color: #2196f3;
        font-size: 12px;
        text-decoration: none;
        cursor: pointer;

        i {
          font-size: 18px;
        }
      }
    }
  }
}

// top-headbar
.top-headbar {
  position: fixed;
  top: 0;
  background-color: #f2f7ff;
  width: 100%;
  z-index: 999;
}

// logout-panel
.logout-dialog {
  .MuiDialog-paperScrollPaper {
    button {
      color: #2196f3;
      background-color: #fff;
      padding: 5px 12px;
      font-size: 12px;
    }
  }
}



.we-care-ticket-dashboard {
  display: flex;
  flex-direction: column;
  margin: 4rem auto 10px;
  width: 95%;

  .dashboard-topbar {
    display: none;
  }

  .ticket-summary-list {
    ul {
      margin: 40px 0;
    }
  }
}