// pendency-description-box
.pendency-description-box{
    margin: 10px 5px;
    padding-bottom: 20px;
    width: calc(99% - 10px);
    border:1px solid #EAEAEA;
    border-radius: 10px;
    font-family: "robotoregular";
    .head-top{
        background-color: #F2F7FF;
        border-radius: 10px 10px 0 0;
        h4{
            padding: 15px 10px;
            font-size: 12px;
            font-weight: 600;
        }
    }
    .pendency-description-body{
        padding:10px 10px 0;
        ul{
            display: flex;
            justify-content: start;
            position: relative;
            border-bottom: 1px solid #EAEAEA;
            padding:10px 0 14px;
            margin:0 10px;
            align-items: center;
            // &:nth-child(even){
            //     background-color: #f8f7f7;
            // }
            &:last-child{
                border-bottom: none;
            }
            li{
                width:20%;
                text-align: left;
                padding: 0 5px;
                .form-control{
                    height: 40px;
                    .MuiInputLabel-outlined.MuiInputLabel-shrink{
                        top: 2px;
                        font-size: 14px;
                        color: #0065ff;
                        left: -6px;
                        padding:0 4px;
                    }
                    .MuiInputLabel-outlined{
                        top: -5px;
                        // text-transform: capitalize;
                        font-size: 12px;
                        background-color: #fff;
                    }
                    .MuiOutlinedInput-notchedOutline{
                        border: 1px solid #E2E2E2;
                    }
                   .MuiOutlinedInput-input{
                    background-color: #fff;
                    padding: 11px 30px 11px 10px;
                    color:#0065ff;
                   }
                }
                .text-field-col{
                    .MuiFormControl-root{
                        .MuiOutlinedInput-input {
                            padding:14px;
                            color: #0065ff;
                        }
                        .MuiInputLabel-outlined.MuiInputLabel-shrink{
                            top:2px;
                            font-size: 12px;
                            color: #0065ff;
                            background-color: #fff;
                            left: -5px;
                            padding: 0 0 0 4px;
                            min-width: auto;
                        }
                        .MuiInputLabel-outlined{
                            top: -5px;
                            text-transform: none;
                            font-size: 12px;
                        }
                    }
                    .MuiOutlinedInput-notchedOutline{
                        border: 1px solid #E2E2E2;
                    }
                }
                .copy-link ,.remarks{
                    position: relative;
                    border: 1px solid #E2E2E2;
                    height: 40px;
                    border-radius: 5px;
                    width: 100%;
                    label{
                        position: absolute;
                        left: 9px;
                        top: -7px;
                        font-size: 10px;
                        color: #0065ff;
                        text-transform: capitalize;
                        background-color: #fff;
                        padding: 0 3px;
                    }
                    a{
                        font-size: 12px;
                        text-decoration: none;
                        font-weight: 500;
                        color: #0266FF;
                        padding: 12px 10px;
                        display: inline-block;
                    }
                    button{
                        &.copy-btn{
                            padding:12px;
                            position: absolute;
                            right:0;
                            min-width: auto;
                            background:#FAFAFA;
                        }
                    }
                    textarea{
                        border: none;
                        resize: none;
                        width: -webkit-fill-available;
                        padding:10px 10px 0;
                        font-size: 12px;
                        border-radius: 5px;
                        font-family: "robotoregular";
                        height: 24px;
                        background-color: #fff;
                        &:hover,&:focus{
                            outline: none;
                        }
                    }
                }
                &.view-remove-box{
                    position: absolute;
                    right:0;
                    text-align: right;
                    button{
                        &.view-detail{
                            background-color: #DEEBFF;
                            border-radius: 8px;
                            color: #116bd9;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            text-transform: capitalize;
                            padding: 8px 0;
                            min-width: 100px
                        }
                        &.remove-btn{
                            min-width: auto;
                            margin-left: 8px;
                        }
                    }
                }
            }
        }
        .addnew-box{
            text-align: center;
            padding: 10px 0;
            img{
                margin-right: 10px;
            }
            button{
                &.add-new-btn{
                    svg{
                        font-size: 20px;
                    }
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: capitalize;
                    background-color: #0165ff;
                    color: #fff;
                    border: 1px solid #0165ff;
                }
            }
        }
        p{
            font-size: 12px;
            span{
                text-decoration: underline;
                font-weight: 500;
                cursor: pointer;
            }
        }
        
    }
    .MuiFormHelperText-root.Mui-error, .error-msg{ 
        position: absolute;
        bottom: -17px;
        left: 7px;
        font-size: 8px;
        color: #f44336;
    }
}

// document-pendency-box
.document-pendency-box{
    padding:20px 20px 0;
    .document-pendency{
        box-shadow: 0 4px 3px rgba(0,0,0,.1411764705882353);
        border:1px solid #EAEAEA;
        border-radius: 10px;
        margin-bottom: 25px;
        .pendency-head{
            background-color: #f2f6ff;
            border-radius: 10px 10px 0 0;
            padding: 15px;
            position: relative;
            h4{
                font-size: 12px;
                font-weight: 600;
                color:#253858;
            }
        }
    }
    .document-pendency-body{
        padding:0 10px;
        ul{
            &.list{
                display: flex;
                align-items: center;
                width: 100%;
                position: relative;
                // justify-content: space-around;
                flex-direction: row;
                border-bottom: 1px solid #EAEAEA;
                padding:10px 0 5px;
                &:last-child{
                    border: none;
                }
                li{
                    background-color: transparent;
                    border: none;
                    padding: 0 5px;
                    margin:0;
                    cursor: default;
                    position: relative;
                    width: 50%;
                    &:nth-child(1){
                        width: 50%;
                    }
                    &:nth-child(even){
                        text-align: right;
                    }
                    &::before,&::after{
                        display: none;
                    }
                    p{
                        text-transform: capitalize;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 20px;
                        span{
                            display: block;
                            font-size: 11px;
                            color:#808080;
                            opacity: 0.8;
                            font-weight: 500;
                            &.price{
                                color:#ab1b26;
                                font-weight: 600;
                                display: inline-block;
                                font-size: 12px;
                                i{
                                    font-size: 11px;
                                    margin: 0 1px;
                                    font-weight: 900;
                                }
                            }
                        }
                        button{
                            background-color: transparent;
                            min-width:auto;
                        }
                    }
                    a{
                        word-break: break-word;
                        overflow: hidden;
                        white-space: nowrap;
                        line-height: 18px;
                        text-overflow: ellipsis;
                        font-size: 14px;
                        display: block;
                        width: 260px;
                        float: right;
                        text-decoration: none;
                        margin-right: 15px;
                        @media (max-width:1366px) {
                            width: 217px;
                        }
                        &.view-details{
                            color:#116bd9;
                            font-size: 14px;
                            font-weight: 600;
                            i{
                                font-weight: 900;
                                font-size: 16px;
                            }
                        }
                    }
                    textarea{
                        resize: none;
                        width: -webkit-fill-available;
                        padding: 10px 8px 0;
                        border-radius: 8px;
                        border: 1px solid #EAEAEA;
                        font-family: "robotoregular";
                        height: 32px;  
                        margin: 5px 0 0;   
                        font-size: 12px;               
                    }
                    span{
                        font-size: 12px;
                        padding: 0 6px 0 0;
                        &.copy-btn{
                            position: absolute;
                            right: -10px;
                            display: block;
                            top: -7px;
                            button{
                               min-width: auto;
                               background-color: transparent;
                            }
                        }
                    }
                }
                a{
                    word-break: break-all;
                    overflow: hidden;
                    white-space: nowrap;
                    line-height: 18px;
                    text-overflow: ellipsis;
                    font-size: 14px;
                    &.view-details{
                        color:#116bd9;
                        font-size: 14px;
                        font-weight: 600;
                        i{
                            font-weight: 900;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
    .create-pendency-box{
        .create-pendency-view{
            box-shadow: 0 4px 3px rgba(0,0,0,.1411764705882353);
            border:1px solid #EAEAEA;
            border-radius: 10px;
            .create-pendency-head{
                background-color: #f2f6ff;
                border-radius: 10px 10px 0 0;
                padding: 15px;
                position: relative;
                h4{
                    font-size: 14px;
                    font-weight: 600;
                    color:#253858;
                }
            }
            .create-pendency-body{
                padding:25px 10px 15px;
                ul{
                    &.create-pendency-list{
                        width: 100%;
                        margin-top: 10px;
                        li{
                            width: 100%;
                            background-color: transparent;
                            padding: 5px 0;
                            margin-bottom: 10px;
                            border: none;
                            input:-internal-autofill-selected{
                                background-color: #ab1b26;
                            }
                            &::before,&::after{
                                display: none;
                            }
                            .MuiFormHelperText-root.Mui-error, .error-msg{ 
                                position: absolute;
                                bottom: -17px;
                                left: 0px;
                                font-size: 10px;
                                color: #f44336;
                                margin: 8px 8px 0;
                            }
                            &:last-child{
                                margin-bottom: 0;
                                width: 100%;
                            }
                            .remark-form-control{
                                height: 40px;
                                border:1px solid #ccc;
                                border-radius: 4px;
                                .MuiInputLabel-formControl {
                                    top: 0;
                                    left: 0;
                                    position: absolute;
                                    transform: translate(10px, 12px) scale(0.8);
                                }
                                .MuiInputLabel-shrink {
                                    transform: translate(8px, -5.5px) scale(0.65) !important;
                                    background: #fff;
                                }
                                label + .MuiInput-formControl {
                                    margin: 8px;
                                    font-size: 12px;
                                }
                                .MuiOutlinedInput-notchedOutline{
                                    border: 1px solid #E2E2E2;
                                }
                               .MuiOutlinedInput-input{
                                background-color: #fff;
                                padding: 12.5px;
                                font-size: 12px;
                               }
                               .MuiInput-underline:before,.MuiInput-underline:after{
                                display: none;
                               }
                               .MuiSelect-icon{
                                top: calc(42% - 12px);
                               }
                            }
                        }
                    }
                    
                }
                .form-control{
                    height: 40px;
                    .MuiInputLabel-outlined.MuiInputLabel-shrink{
                        top: 3px;
                        font-size: 14px;
                        color: #0065ff;
                        left: -1px;
                    }
                    .MuiInputLabel-outlined{
                        top: -5px;
                        text-transform: capitalize;
                        font-size: 11px;
                        background-color: #fff;
                        padding: 0 4px;
                    }
                    &.create-pendency-form{
                        .MuiInputLabel-outlined.MuiInputLabel-shrink{
                            top: 4px;
                            font-size: 11px;
                            color: #0065ff;
                            left: -5px;
                            display: block;
                        }
                        .MuiInputLabel-outlined{
                            top: 4px;
                            text-transform: capitalize;
                            font-size: 11px;
                            background-color: #fff;
                            padding: 0 4px;
                            left: -5px;
                            transform: translate(14px, -8px) scale(1);
                            color: #0065ff;
                        }
                    }
                    .MuiOutlinedInput-notchedOutline{
                        border: 1px solid #E2E2E2;
                    }
                   .MuiOutlinedInput-input{
                    background-color: #fff;
                    padding: 12.5px;
                    font-size: 12px;
                   }
                }
                .remarks{
                    position: relative;
                    border: 1px solid #E2E2E2;
                    height: 40px;
                    border-radius: 5px;
                    width: 100%;
                    margin: 0;
                    label{
                        position: absolute;
                        left: 10px;
                        top: -7px;
                        font-size: 10px;
                        color: #0065ff;
                        text-transform: capitalize;
                        // background: #fff;
                    }
                    a{
                        font-size: 12px;
                        text-decoration: none;
                        font-weight: 500;
                        color: #0266FF;
                        padding: 12px 10px;
                        display: inline-block;
                    }
                    button{
                        &.copy-btn{
                            padding:12px;
                            position: absolute;
                            right:0;
                            min-width: auto;
                            background:#FAFAFA;
                        }
                    }
                    textarea{
                        border: none;
                        resize: none;
                        width: -webkit-fill-available;
                        padding: 8px 12px 0;
                        font-size: 12px;
                        border-radius: 5px;
                        font-family: "robotoregular";
                        &:hover,&:focus{
                            outline: none;
                        }
                    }
                }
            }
        }
    }
    .pendency-resolve-btns{
        right: 15px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    button{
        background-color: #DEEBFF;
        border-radius: 8px;
        color:  #116bd9;
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        height: 32px;
        padding: 0 8px;
        min-width: 87px;
        &:hover{
            background-color: #DEEBFF;
        }
        &.docspoint-btn{
            background-color:#DEEBFF;
            height: 32px;
            margin: 7px 0 5px 12px;
            &:hover{
                box-shadow: 0 0 0 2px #80b2ff33;
            }
        }
        &.resolve-btn{
            background-color: #0065ff;
            color:#fff;
        }
        &.create-new-btn{
            width:100%;
            text-align: center;
            color:#116bd9;
            border: 1px solid #116bd9;
            background-color: #fff;
            font-size: 14px;
            margin-bottom: 20px;
            height: 44px;
            i{
                margin:8px;
            }
        }
        &.save-btn{
            width: 100%;
            height: 40px;
        }
        &.delete-btn{
            background-color: #febcbd;
            color:#ab1b26;
            width: 100%;
            height: 40px;
        }
    }
    div:empty{
        display: none;
    }
}