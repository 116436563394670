// login page
.login-box {
  margin: auto;
  width: 392px;
  height: 570px;
  max-width: 100%;
  border-radius: 3px;
  padding: 10px;
  background-color: #fff;
  min-height: 300px;
  padding:20px 30px;
  position: absolute;
  top: -50%;
  bottom: -50%;
  box-shadow: 0px 6px 16px #3469cb29;
  border-radius: 16px;
  text-align: center;
  

  h2 {
    // text-align: center;
    // display: block;
    // padding: 35px 0 5px;
    // font-size: 22px;
    // color: #253858;
    // border-radius: 3px 3px 0 0;
    // font-weight: 600;
    // margin: 20px 0 35px;
    text-align: center;
    display: block;
    padding: 9px 0;
    font-size: 20px;
    color: #253858;
    border-radius: 76px 10px 63px 14px;
    font-weight: 600;
    margin:4px 0 21px;
    background: #e3effe;
  }

  p {
    margin: 19px 0 0px;
    font-size: 12px;
    &.success{
      color: #06a857;
    }
    &.error{
      color: #ff0000;
    }
    &.Mui-error{
      color: #ff0000;
      margin: 8px 4px 0 !important;
      font-size: 10px;
      bottom: -19px;
      position: absolute;
    }
    &.MuiFormHelperText-root.Mui-error {
      margin: 11px 4px 0;
    }
    &.sub-heading{
      margin: 5rem 0 20px 0;
      font-size: 14px;
      font-weight: 500;
    }
  }
  span{
    &.mobile-number-confirm{
      color: #06a857;
      font-size: 10px;
      display: block;
      text-align: left;
      padding: 2px 4px;
    }
    &.otp-sent-message{
      color: #06a857;
      font-size: 10px;
      display: block;
      text-align: left;
      padding: 2px 4px;
      left: 0;
      position: absolute;
      bottom: -32px;
    }
  }

    .form-control {
      width: 100%;
      border: 1px solid #5e6c84;
      border-radius: 8px;
      height: 48px;
      margin: 0 0 30px;
  
      .MuiFormControl-marginNormal {
        margin: 0;
        padding: 8px;
      }
  
      input {
        padding: 3px 5px 10px;
      }
  
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
  
      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }
  
      label+.MuiInput-formControl {
        margin-top: 0;
        padding: 9px 0;
      }
  
      .MuiInputLabel-animated {
        top: -8px;
        left: 10px;
        background: #fff;
        color: #7a869a;
        font-size: 14px;
      }
  
      .MuiInputLabel-shrink {
        top: -8px;
        left: 13px;
        background: #fff;
        color: #7a869a;
        font-size: 14px;
      }
  
      .MuiInput-underline:after,
      .MuiInput-underline:before {
        opacity: 0;
      }
  
      input:-internal-autofill-selected {
        background-color: #fff !important;
        -webkit-box-shadow: 0 0 0px 1000px white inset;
      }
  
      .MuiOutlinedInput-root {
        background: #deebff;
        border-radius: 22px;
        border: none;
      }
  
      span {
        &.info-box {
          position: absolute;
          right: 30px;
          top: 17px;
          z-index: 99;
  
          svg {
            font-size: 16px;
            color: #0065ff;
          }
        }
      }
  
      &.confirm-new-password {
        margin-top: 25px;
      }
  
      .MuiInputAdornment-root {
        button {
          svg {
            font-size: 16px;
          }
        }
      }
  
      &.form-control-login {
        .MuiFormControl-root {
          margin: 0;
        }
  
        input {
          padding: 3px 15px 10px;
        }
  
        .MuiInputLabel-animated {
          top: -8px;
          left: 8px;
          background: #fff;
          color: #7a869a;
          font-size: 14px;
        }
  
        .MuiInputLabel-shrink {
          top: -7px;
          left: 14px;
          background: #fff;
          color: #7a869a;
          font-size: 14px;
        }
      }
    }

  button {
    &.signin-btn {
      background-color: #2680eb;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      border: none;
      margin: 0px auto 10px;
      display: block;
      height: 44px;
      text-transform: capitalize;
      cursor: pointer;
      width: 100%;
       border-radius: 8px;
      &.MuiButton-root.Mui-disabled{
        background-color: #00000029;
      }
    }
  }

  span {
    &.loader-icon {
      position: absolute;

      .MuiCircularProgress-colorPrimary,
      .MuiCircularProgress-indeterminate {
        color: #fff !important;
      }
    }
  }
  .relogin-box{
    margin:20px 0;
    p{
      margin:0;
      strong{
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  .otp-field {
    flex-direction: row;
    column-gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5rem 0 0;
    input {
      height: 45px;
      width: 42px;
      border-radius: 6px;
      outline: none;
      font-size: 1.125rem;
      text-align: center;
      border: 1px solid #ddd;
      &:focus{
        border:1px solid  #253858;
      }
    }
  }
  .resend {
    font-size: 12px;
  }
  button{
    text-transform: capitalize;
    padding: 0;
    &.verify-btn{
      color: #fff;
      background-color: #2da51a;
      border-radius: 5px;
      margin: 18px 0 10px;
      min-width: 100%;
      height: 44px;
      line-height: 40px;
    }
    &.login-btn{
      color: #fff;
      background-color: #0065ff;
      border-radius: 5px;
      margin: 10px 0;
      min-width: 100%;
      font-weight: 500;
      height: 44px;
      line-height: 40px;
      
    }
  }
.password-strength{
  display: flex;
  justify-content: space-between;
  padding: 0 5px 5px 5px;
  margin: 0px 0 0;
  position: relative;
  top: -3px;
  span{
    display: block;
    height: 5px;
    border-radius: 8px;
    width: 31%;
    &.weak{
      background-color: hsla(353, 100%, 38%, 1),;
    }
    &.medium{
      background-color: hsla(40, 71%, 51%, 1);
    }
    &.strong{
      background-color: hsla(134, 73%, 30%, 1);
    }
    &.no-color{
      background-color: grey;
    }
  }
}
.otp-loader{
  > div{
    display: block;
  }
}
.password-strength-text{
  font-size: 11px;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}
}
.info-box-view{
  background: #d6e4fd;
  padding: 10px;
  color:#253858;
  border: 1px solid #0075FF;
  border-radius: 10px;
  h1{
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    color:#253858;
    margin-bottom: 6px;
  }
  ul{
    padding: 0 15px;
    li{
      list-style-type: disc;
      font-size: 11px;
      margin-bottom: 10px;
      position: relative;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
.input-no-spin::-webkit-inner-spin-button, 
.input-no-spin::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    appearance: none; 
}

/* Firefox */
.input-no-spin {
    -moz-appearance: textfield;
}

// insurerDashboard
.insurer-dashbaord {
  position: relative;
  padding: 0 0 0 49px;

  .profile-box {
    position: fixed;
    right: 0;
    background-color: #f2f7fe;
    top: 0px;
    border-radius: 0 0 0 5px;
    min-width: 80px;
    text-align: right;
    padding: 0;
    box-shadow: 2px 3px 3px 1px #00000029;
    z-index: 99;

    ul {
      li {
        margin: 0;
        color: #2196f3;
        width: auto !important;
        padding: 8px 5px !important;

        i {
          background: #2196f3;
          width: 20px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          color: #fff;
          border-radius: 10px;
        }
      }
    }

    i {
      background: #2196f3;
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      color: #fff;
      border-radius: 10px;
      margin-left: 8px;
    }
  }

  .dashboard {
    background-color: #f2f7ff;

    ul {
      li {
        width: -webkit-fill-available;
        padding: 8px;
        vertical-align: top;
        display: inline-block;

        a {
          color: #2196f3;
          font-size: 12px;
          text-decoration: none;
          cursor: pointer;

          i {
            font-size: 18px;
          }
        }

        &.leftbar {
          width: 2%;

          >ul {
            li {
              padding: 4px 0;
              text-align: center;
              margin-bottom: 10px;
            }
          }
        }

        &.rightbar {
          background-color: #fff;
          height: calc(100vh - 76px);
          padding: 5px 10px 20px;
          padding-top: 58px;
        }
      }
    }
  }

  .user-manage {
    padding: 10px;

    h2 {
      color: #0a458c;
      font-size: 16px;
      font-weight: 600;
      margin: 0px 0px 15px;
      width: 100%;
      padding: 10px 0 0;
    }

    .create-user-box {
      ul {
        background: #f9f9f9;
        padding: 0 15px 25px;
        border-radius: 5px;
        margin: 15px 0;

        li {
          width: 20%;
          display: inline-block;
          margin: 15px 15px 0 0;
          padding: 0;

          .check-box {
            width: 100%;
            border: 1px solid #95affc;
            margin: 0;
            border-radius: 4px;
            height: 40px;
            background-color: #fff;
          }

          .form-control {
            width: 100%;

            .MuiOutlinedInput-notchedOutline {
              border-color: #95affc;
            }

            .MuiOutlinedInput-input {
              padding: 12.5px 14px;
              background-color: #fff;
            }

            label+.MuiInput-formControl {
              margin-top: 0px;
            }

            &.select-agent {
              border: 1px solid #95affc;
              padding: 5px 0;
              border-radius: 4px;
              height: 30px;
              background-color: #fff;

              .MuiInputLabel-shrink {
                top: -8px !important;
                left: 11px;
                color: #253858;
                font-size: 16px;
                background: #fff;
                padding: 0 2px;
              }

              .MuiSelect-select.MuiSelect-select {
                padding: 8px;
              }

              .MuiInputLabel-animated {
                top: -11px;
                left: 11px;
                background: #fff;
                color: #253858;
              }
            }

            .MuiInput-underline:after,
            .MuiInput-underline:before {
              opacity: 0;
            }

            input:-internal-autofill-selected {
              background-color: #fff !important;
              -webkit-box-shadow: 0 0 0px 1000px white inset;
            }
          }

          button {
            text-transform: capitalize;
            font-size: 14px;
            min-width: 80px;

            &.save-btn {
              background-color: #2196f3;
              color: #f2f7ff;
              margin-right: 10px;
              height: 40px;
            }

            &.cancel-btn {
              background-color: #fff;
              color: #3197f3;
              border: 1px solid #cfcfcf;
            }
          }
        }
      }
    }

    button {
      cursor: pointer;

      &.create-btn {
        color: #2196f3;
        display: block;
        padding: 5px 8px;
        background: #f2f7fe;
        font-size: 14px;
        box-shadow: 2px 3px 3px 1px #00000029;
        text-transform: capitalize;
        cursor: pointer;

        &:hover {
          background: #f2f7fe;
        }
      }
    }

    .user-detail {
      margin: 20px 0;
      // padding: 14px;
      // background: #f2f7fe;
      border-radius: 4px;

      .user-detail-head {
        padding: 0 0px 3px !important;
        background-color: #fff;

        ul {
          padding: 10px 0;
          background-color: #f5f5f5;

          li {
            span {
              font-size: 12px;
              font-weight: 900;
              text-align: left;
              color: #303030;
              line-height: 16px;
            }
          }
        }
      }

      .user-detail-body {
        margin-top: 5px;
        // background-color: #fafafa;
        border-radius: 4px;
        padding: 3px 0;
        height: calc(275px - 20px);
        overflow-y: scroll;

        ul {
          background-color: #fff;
          box-shadow: 0px 9px 9px #24242430;
          margin-bottom: 5px;

          li {
            span {
              font-size: 12px;
              font-weight: 900;
              color: #253858;
              margin-bottom: 5px;
              display: none;
            }

            strong {
              font-weight: 500;
              word-break: break-word;
              padding: 0px 3px 0 0;
              display: block;
            }
          }
        }
      }

      ul {
        width: 100%;
        background-color: #fff;
        padding: 5px 0;
        border-radius: 4px;

        li {
          display: inline-block;
          width: 15%;
          padding: 0 8px;
          vertical-align: middle;

          &:nth-child(2) {
            width: 20%;
          }

          &:last-child {
            width: 6%;

            button {
              padding: 0;
              text-align: center;

              span {
                display: block;
              }

              &.edit-btn {
                color: #2196f3;
                min-width: 48px;

                i {
                  color: #2196f3;
                }

                &:hover {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }

  .insurer-admin-dash {
    position: relative;
    top: 15px;

    h2 {
      color: #0a458c;
      font-size: 16px;
      font-weight: 600;
      margin: 0px 0px 15px;
      width: 100%;
      padding: 10px 0 0;
    }

    .ticket-list-view {
      ul {
        li {
          display: inline-block;
          width: 7%;
          padding: 0;
          margin: 0 10px 15px 0;

          div {
            &.ticket-box {
              box-shadow: 2px 3px 3px 1px #00000029;
              border-radius: 0 0 5px 5px;
              min-height: 120px;

              .ticket-haed {
                border-radius: 5px 5px 0 0;
                text-align: center;
                // height: 36px;
                // line-height: 36px;
                padding: 5px 0;
                color: #fff;

                i {
                  font-size: 18px;
                }

                &.pb-pendency {
                  background-color: #ffd200;
                }

                &.new-ticket {
                  background-color: #53d192;
                }

                &.open-ticket {
                  background-color: #e55957;
                }

                &.tat-brust {
                  background-color: #a285c5;
                }
                &.resolved-today{
                  background-color: #4db05b;
                }

                &.unassiged-ticket {
                  background-color: #6ccac9;
                }

                &.pending-ticket {
                  background-color: #f39c12;
                }

                &.endoresment-ticket {
                  background-color: #839192;
                }

                &.pt-miss {
                  background-color: #2c76cf;
                }

                &.reopen-ticket {
                  background-color: #fbb168;
                }
                &.info-fromPB-ticket{
                  background-color: #fbb168;
                }
              }

              .ticket-text {
                p {
                  padding: 10px 0 4px;
                  font-size: 20px;
                  line-height: 20px;
                  text-align: center;
                  color: #848890;
                  cursor: pointer;
                  font-family: "robotomedium";
                  margin: 5px 0 0;

                  span {
                    font-size: 12px;
                    font-weight: 500;
                    display: block;
                    //    color:#0A458C;
                    line-height: 16px;
                    padding: 5px 0 0;
                  }

                  &.open-ticket {
                    span {
                      color: #e55957;
                    }
                  }

                  &.new-ticket {
                    span {
                      color: #53d192;
                    }
                  }

                  &.pb-pendency {
                    span {
                      color: #ffd200;
                    }
                  }

                  &.reopen-ticket {
                    span {
                      color: #fbb168;
                    }
                  }

                  &.tat-brust {
                    span {
                      color: #a285c5;
                    }
                  }
                  &.resolved-today {
                    span {
                      color: #4db05b;
                    }
                  }
                  &.unassiged-ticket {
                    span {
                      color: #6ccac9;
                    }
                  }

                  &.pending-ticket {
                    color: #f39c12;
                  }

                  &.endoresment-ticket {
                    span {
                      color: #839192;
                    }
                  }

                  &.pt-miss {
                    span {
                      color: #2c76cf;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .product-search{
        margin: 0 0 20px;
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
        @media all and (max-width: 540px) {
          flex-direction: column;
        }
        &:nth-child(1){
          width: 450px;
          @media all and (max-width: 540px) {
            width: 100%;
          }
        }
        .MuiAutocomplete-root{
          .MuiInputBase-root{
            padding: 4px;
          }
          .MuiInputLabel-outlined {
            transform: translate(14px, 12px) scale(1);
          }
          .MuiInputLabel-outlined.MuiInputLabel-shrink {
            transform: translate(14px, -6px) scale(0.75);
          }
          .MuiChip-root{
            height: 26px;
            .MuiChip-deleteIcon {
              width: 14px;
              height: 24px;
            }
          }
        }
        button{
          &.go-btn{
            background-color: #2196f3;
            color: #fff;
            text-transform: capitalize;
            min-width:80px;
            border-radius: 22px;
            height: 40px;
            font-size: 14px;
          }
        }
      }
    }

    .search-box {
      border-bottom: 2px solid #dfddfd;
      border-top: 2px solid #dfddfd;
      margin: 3px 0 0;

      .MuiFormControlLabel-root {
        margin: 10px 10px 10px 0;
      }

      .radio-box {
        .MuiRadio-root {
          padding: 0 5px;

          svg {
            width: 20px;
            height: 20px;
          }

          span {
            font-size: 14px;
          }

          svg {
            width: 18px;
            height: 18px;
          }
        }

        .MuiTypography-body1 {
          font-size: 14px;
          font-family: "robotomedium";
        }
      }

      button {
        .radio-box {
          .MuiRadio-root {
            padding: 10px;

            svg {
              width: 20px;
              height: 20px;
            }
          }
        }

        .MuiFormGroup-root {
          width: 24px;
        }

        color: #0a458c;
        text-transform: capitalize;

        &:hover {
          background-color: transparent;
        }
      }
    }

    .search-option-list {
      ul {
        padding: 20px 0;

        li {
          width: 11.8%;
          display: inline-block;
          padding: 0;
          margin: 0 14px 11px 0;

          .form-control {
            width: 100%;
            border: 1px solid #d5d5d5;
            border-radius: 4px;
            height: 40px;

            .MuiInput-underline:before,
            .MuiInput-underline:after {
              display: none;
            }

            label+.MuiInput-formControl {
              margin-top: 0;
              padding: 6px 0 0 6px;
            }

            .MuiInputLabel-formControl {
              top: -11px;
              left: 9px;
              font-size: 14px;
            }

            .MuiInputLabel-shrink {
              top: -7px;
              left: 9px;
              color: #495973;
              background-color: #fff;
            }

            .MuiSelect-select.MuiSelect-select {
              font-size: 14px;
              padding: 6px 3px;
              color: #253858;
              font-weight: 400;
            }

            .MuiFormControl-marginNormal {
              margin: 0;
            }

            .MuiInputBase-input {
              font-size: 14px;
              font-weight: 400;
              padding: 3% 5% 6% 3px;
            }

            .MuiSelect-icon {
              top: calc(66% - 16px);
            }
          }
          &.insurer-status-list {
            width: 16%;
          }
          &.search-box-list {
            width:8%;
          }
          &.calander-list{
            width:18%;
            margin-bottom: 15px;
            .form-control {
              .MuiInputLabel-shrink {
                font-size: 14px;
                top:-7px;
                left:7px;
              }
    
              .MuiFormControl-marginNormal {
                margin: 0;
              }
            }
    
            .MuiFormHelperText-root.Mui-error{
              font-size: 10px;
              position: relative;
              top:-5px;
              left:5px;
            }
            
    
            .error-msg {
              color: #f44336;
              position: absolute;
              bottom: -18px;
              left: 2px;
              font-size: 10px;
            }
            .select-product-error.MuiFormHelperText-root.Mui-error{
              top:-7px !important;
            }
            .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
              height: 40px;
              overflow:auto;
              padding:0 32px 0 14px;
              font-size: 14px;
            }
            .MuiAutocomplete-root {
              &.Mui-focused{
                outline: none;
                border: none;
              }
            }
            .MuiAutocomplete-clearIndicator {
              padding: 0;
              .MuiSvgIcon-fontSizeSmall {
                font-size: 12px;
             }
            }
            .MuiOutlinedInput-notchedOutline{
              display: none;
            }
            .MuiAutocomplete-tag {
              height: 25px;
              font-size: 10px;
              margin: 9px;
              max-width: calc(92% - 6px);
              svg{
                height: 25px;
                font-size: 10px;
                margin: 9px;
                width: 15px;
              }
            }
            .MuiAutocomplete-inputFocused{
              outline: none;
              border: none;
            }
            .MuiFormControl-root {
              margin: 0;
              // height: 40px;
              
              .MuiFormLabel-root{
                transform: translate(1px, 23px) scale(0.9);
              }
              .MuiInputLabel-shrink{
                transform: translate(7px, 3px) scale(0.7);
                opacity: 1;
                color: #253558;
                font-weight: 500;
              }
              .MuiInputBase-input{
                font-size: 14px;
                color: #253858;
                font-weight: 400;
                padding: 1% 0 3px;
                &.Mui-focused{
                  outline: none;
                  border: none;
                }
              }
            }
            .selected-item-info{
              font-size: 10px;
            }
          }
        }

        button {
          &.search-btn {
            background-color: #2196f3;
            color: #fff;
            text-transform: capitalize;
            min-width: 114px;
            border-radius: 22px;
            height: 40px;
            font-size: 14px;
          }
        }
      }

      &.policy-link-view {
        ul {
          padding: 5px 0;
        }
      }
    }

    .ticket-sammary-list {
      margin: 20px 0 0;

      .ticket-summary-head {
        background-color: #f2f7ff;

        ul {
          padding: 8px;

          li {
            width: 9.6%;
            display: inline-block;
            text-align: center;
            padding: 0;

            &.name {
              width: 13%;
              text-align: left;
            }
          }
        }
      }

      .ticket-summary-body {
        ul {
          &:nth-child(even) {
            background-color: rgba(0, 0, 0, 0.04);
          }

          padding: 0 8px;
          margin: 3px 0 0;
          box-shadow: 2px 3px 3px 1px #00000029;
          border-radius: 4px;

          li {
            width: 9.6%;
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            padding: 0;

            &.name {
              width: 13%;
              text-align: left;
            }

            span {
              display: none;
              font-size: 12px;
              font-weight: 600;
            }

            strong {
              display: block;
              font-size: 12px;
              font-weight: 500;
            }

            button {
              &:hover {
                background-color: transparent;
              }

              span {
                display: block;
                color: #0a458c;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  // summary-list
  .summary-list {
    background-color: #f7f7f7;
    padding: 5px;

    button {
      &.export-btn {
        background-color: #2680eb;
        color: #fff;
        font-size: 14px;
        height: 30px;
        text-transform: capitalize;
        cursor: pointer;
        border-radius: 4px;
        margin: 10px 5px;

        img {
          max-width: 20px;
        }
      }

      &.export {
        background-color: #0065ff;
        color: #fff;
        font-size: 14px;
        height: 26px;
        text-transform: capitalize;
        cursor: pointer;
        border-radius: 4px;
        margin: 0 5px;
        min-width: auto;
        position: absolute;
        top: 9px;
        left: 0;

        img {
          max-width: 20px;
        }
      }
    }

    h3 {
      margin: 0 0 7px;
      padding: 15px 8px 10px;
      position: relative;
      border-bottom: none;
    }

    div {
      &.export-data {
        text-align: right;
        padding: 5px 10px;
        background: #d7e1ec6e;

        button {
          min-width: auto;
          text-transform: capitalize;

          &.check-icon {
            color: #f39c12;

            &:hover {
              background-color: transparent;
            }
          }

          &.update-btn {
            color: #fff;
            background-color: #2196f3;
            min-width: 80px;
            padding: 0 5px;
            border-radius: 4px;
            height: 35px;

            &:hover {
              background-color: #2196f3;
            }
          }
        }

        .checkbox {
          margin-left: 2px;

          span {
            padding: 0;

            svg {
              font-size: 20px;
            }
          }
        }
      }
    }

    .suammry-list-view {
      overflow-x: scroll;
      overflow-y: scroll;
      white-space: nowrap;
      width: auto;
      margin: 0;
      padding: 0;
      min-height: 120px;
      max-height: 250px;
      position: relative;

      &.scrool-toll {
        &::-webkit-scrollbar-track {
          box-shadow: transparent;
          background-color: #fff;
        }

        &::-webkit-scrollbar {
          width: 4px;
          background-color: #d8d8d8;
          height: 5px;
          border: 3px;
        }
      }

      .scrool-toll::-webkit-scrollbar-thumb {
        background-color: #d8d8d8;
      }

      // .table-body{
      //     overflow-y: scroll;
      //     height:350px;
      // }
      ul {
        padding: 10px 5px;
        display: table;
        box-shadow: 2px 3px 3px 1px #00000029;
        margin-bottom: 5px;
        border-radius: 0 0 4px 4px;
        position: relative;

        &:nth-child(even) {
          background-color: #fbf9f9;
        }

        &:nth-child(odd) {
          background-color: #fff;
        }

        li {
          margin-right: 11px;
          display: inline-block;
          width: 125px;
          padding: 0;
          vertical-align: middle;

          &:nth-child(1) {
            width: 30px;
          }

          &.name {
            width: 125px;
          }

          &:nth-child(5) {
            width: 160px;
          }
          &:nth-child(6){
            width: 120px;
          } 
          &:nth-child(3),
          &:nth-child(7) {
            width: 120px;
          }

          &:nth-child(14) {
            width: 180px;
          }

          &:nth-child(18) {
            width: 190px;
          }

          &:nth-child(15),
          &:nth-child(20),
          &:nth-child(22) {
            width: 130px;
          }

          &.checkbox-list {
            width: 50px;

            button {
              min-width: 10px;
            }
          }

          span {
            font-size: 11px;
            color: #414141;
            font-weight: 600;
            font-family: "robotomedium";
          }

          strong {
            font-size: 12px;
            font-weight: 400;
            color: #253858;
            word-break: break-word;
            white-space: initial;
            width: 100%;
            cursor: pointer;

            button {
              cursor: pointer;
              color: #253858;
              min-width: auto;
              padding: 0 5px;
              border: 1px solid #d4d4d4b0;
              border-radius: 50%;
              background: #fff;

              &:hover {
                border: 1px solid #3197f3;
                color: #fff;
              }

              &:focus {
                border: 1px solid #3197f3;
                color: #fff;
              }
            }
          }
          button{
            &.action-btn{
              background-color: transparent;
              padding:0;
              span{
                color:#2196f3;
                font-size: 12px;
                text-transform: capitalize;
              }
            }
          }
          .checkbox {
            margin-left: 2px;

            span {
              padding: 0;

              svg {
                font-size: 20px;
              }
            }
          }
         }
      }
    }

    #pagination {
      width: auto;
      margin: 10px 0;
      padding: 10px;

      ul {
        background-color: #f7f7f7;
        box-shadow: none;

        li {
          width: 30px;

          .MuiPaginationItem-page.Mui-selected {
            background: #2196f3;
            color: #fff;
          }
        }
      }
    }

    .ticket-summary-list {
      overflow-x: scroll;
      overflow-y: scroll;
      white-space: nowrap;
      width: auto;
      margin: 0;
      padding: 0;
      min-height: 120px;
      max-height: 520px;
      position: relative;

      ul {
        padding: 10px 5px;
        display: table;
        box-shadow: 2px 3px 3px 1px #00000029;
        margin-bottom: 5px;
        border-radius: 0 0 4px 4px;
        position: relative;

        &:nth-child(even) {
          background-color: #fbf9f9;
        }

        &:nth-child(odd) {
          background-color: #fff;
        }

        li {
          margin-right: 11px;
          display: inline-block;
          width: 125px;
          padding: 0;
          vertical-align: middle;

          &:nth-child(1) {
            width: 30px;
            margin: 0 4px;
          }

          &.name,
          &:nth-child(8) {
            width: 125px;
          }

          &:nth-child(13),
          &:nth-child(14) {
            width: 180px;
          }

          &:nth-child(5) {
            width: 150px;
          }

          &:nth-child(7),
          &:nth-child(9),
          &:nth-child(12),
          &:nth-child(18) {
            width: 190px;
          }

          &:nth-child(15),
          &:nth-child(20),
          &:nth-child(22) {
            width: 130px;
          }

          &.checkbox-list {
            width: 50px;
          }

          span {
            font-size: 11px;
            color: #414141;
            font-weight: 600;
            font-family: "robotomedium";
          }

          strong {
            font-size: 12px;
            font-weight: 400;
            color: #253858;
            word-break: break-word;
            white-space: initial;
            width: 100%;
            cursor: pointer;

            button {
              cursor: pointer;
              color: #253858;
              min-width: auto;
              padding: 0 5px;
              border: 1px solid #d4d4d4b0;
              border-radius: 50%;
              background: #fff;

              &:hover {
                border: 1px solid #3197f3;
                color: #fff;
              }

              &:focus {
                border: 1px solid #3197f3;
                color: #fff;
              }
            }
          }

          .checkbox {
            margin-left: 2px;

            span {
              padding: 0;

              svg {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }

  .table-view {
    margin: 20px 0;

    .table-head {
      background-color: #f2f7ff;

      .MuiTableCell-head {
        line-height: 16px;
        font-size: 12px;
        color: #253858;
        font-weight: 500;
        padding: 10px 5px;
      }
    }

    .MuiTableBody-root {
      background-color: #f5f5f5;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 5px;
      padding: 5px;
      box-shadow: 2px 3px 3px 1px #00000029;
      color: #253858;

      &:nth-child(odd) {
        background-color: #fff;
      }

      .MuiTableCell-body {
        text-align: left;
        padding: 10px 5px;
        font-size: 12px;
      }

      .checkbox {
        margin-left: 2px;

        span {
          padding: 0;

          svg {
            font-size: 20px;
          }
        }
      }
    }

    button {
      &.export-btn {
        background-color: #2680eb;
        color: #fff;
        font-size: 14px;
        height: 30px;
        text-transform: capitalize;
        cursor: pointer;
        border-radius: 4px;
        margin: 10px;
      }
    }
  }
}

#simple-menu {
  .MuiPopover-paper {
    margin: 30px 0 0 -20px;
    border-radius: 0 0 5px 5px;
    width: 200px !important;

    ul {
      width: 190px;
      padding: 5px 0;

      li {
        font-size: 14px;
        color: #253858;
        padding: 3px 0 3px 10px;
      }
    }
  }

  &#more-option-box {
    ul {
      li {
        cursor: pointer;
        color: #253858;
        font-size: 12px;
      }
    }
  }
}

// Assign-ticket-dialog
.assign-ticket-dialog {
  h5 {
    background-color: #c9e5f859;
    width: auto;
    padding: 15px 20px;
    font-size: 14px;
    color: #253858;
    position: relative;

    button {
      &.close-icon {
        position: absolute;
        right: 0px;
        top: 6px;
      }
    }
  }

  ul {
    display: block;
    max-height: 300px;
    overflow-y: scroll;
    padding: 10px 10px 20px;
    text-align: center;

    li {
      padding: 10px;
      width: 23%;
      display: inline-block;
      border: 1px solid #dfddfd;
      margin: 0 5px 10px 0;
      border-radius: 5px;

      .MuiFormControl-root {
        .MuiIconButton-colorPrimary {
          padding: 0 5px;

          svg {
            font-size: 20px;
          }
        }
      }

      .MuiIconButton-label {
        width: 15px;
        height: 15px;
      }

      .MuiTypography-body1 {
        font-size: 12px;
      }
    }

    div {
      button {
        &.submit-btn {
          background-color: #2680eb;
          border-radius: 22px;
          color: #fff;
          text-transform: capitalize;
          padding: 5px 7px;
          min-width: 82px;
          margin: 10px 0 0;
          font-size: 14px;
        }
      }
      .MuiButton-root.Mui-disabled{
        display: none;
      }
    }
  }

  .MuiDialog-paperWidthSm {
    width: 520px;
    max-width: 100%;
  }
}

// pagination-view
.pagination-view {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  min-width: auto;

  ul {
    &.MuiPagination-ul {
      text-align: right;
      display: block;
      margin: 0px 12px 0 45px;
      padding: 4px 0 0;

      li {
        width: 20px;
        display: inline-block;
        padding: 9px 5px;

        button {
          &.MuiPaginationItem-page.Mui-disabled {
            background-color: #2196f3;
            border-radius: 50%;
            color: #253858;
          }

          &.MuiPaginationItem-page.Mui-selected {
            background-color: #2196f3;
          }

          &.MuiPaginationItem-rounded {
            border-radius: 50%;
            min-width: 20px;
            height: 20px;
          }

          .MuiPaginationItem-root {
            min-width: 20px;
            height: 20px;
            font-size: 12px;
            vertical-align: middle;
            text-align: center;
          }
        }
      }
    }
  }
}

// insurer-mail-chain
.insurer-mail-chain {
  width: 96.5%;
  margin: 9px auto;

  .MuiAppBar-colorDefault {
    box-shadow: none;
    border-bottom: 1px solid #eaeaea;
  }

  .MuiTabs-scrollable {
    background-color: #fff;
    border-bottom: 1px solid #eaeaea;
  }

  .MuiBox-root {
    padding: 0;
    background-color: #fff;
  }

  .MuiTab-textColorPrimary {
    padding: 0 10px;
    min-width: 130px;
    color: #253858;
    text-transform: capitalize;
    border-radius: 10px 10px 0 0;
    min-height: 40px;
    position: relative;
    font-size: 14px;
    text-align: center;

    &.Mui-selected {
      color: #0065ff;
    }
  }

  .MuiExpansionPanel-root.Mui-expanded {
    margin: 8px 0;
  }

  .email-chain {
    width: 97.6%;
    margin: 10px 0 0 0px;
    padding: 4px 8px 10px 15px;

    &:nth-child(1) {
      margin-top: 10px;
    }

    .MuiExpansionPanelSummary-content.Mui-expanded {
      margin: 10px 0;
    }

    >ul {
      position: relative;
      padding: 0;

      &::before {
        content: ".";
        border: 2px solid #0052cc;
        position: absolute;
        left: -18px;
        top: 15px;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        padding: 0px;
        color: transparent;
        background: #c2d5f1;
      }

      >li {
        margin-bottom: 0px;
        position: relative;
        width: 100%;
        display: block;

        &:nth-child(2) {
          padding: 0;
        }

        &::before {
          content: "";
          border-left: 2px solid #86ade7;
          padding: 0px 5px 4px;
          margin: 0 0 5px;
          position: absolute;
          left: -12px;
          top: 27px;
          height: 100%;
        }

        .MuiPaper-elevation1 {
          box-shadow: none;
          position: relative;
        }

        .MuiExpansionPanelSummary-root,
        .MuiExpansionPanelSummary-root.Mui-expanded,
        .MuiExpansionPanel-root:before {
          min-height: 40px;
          border-radius: 8px 8px 0 0;
          padding: 0px 12px;
          margin: 10px 0 0;
        }

        .MuiExpansionPanel-root.Mui-expanded {
          box-shadow: 0px 6px 16px #3469cb29;
          border-radius: 8px;

          .attach-box {
            position: relative;
          }
        }

        svg {
          &.arrow {
            color: $blueM;
          }
        }

        &.email-customer {

          .MuiExpansionPanelSummary-root,
          .MuiExpansionPanelSummary-root.Mui-expanded,
          .MuiExpansionPanel-root:before {
            background: #f2f7ff;
          }
        }

        &.insurer {

          .MuiExpansionPanelSummary-root,
          .MuiExpansionPanelSummary-root.Mui-expanded,
          .MuiExpansionPanel-root:before {
            background: #ffdec9;
          }
        }

        &.email-sent-customer {

          .MuiExpansionPanelSummary-root,
          .MuiExpansionPanelSummary-root.Mui-expanded,
          .MuiExpansionPanel-root:before {
            background: #d9d9ff;
          }
        }

        &.chatboat {

          .MuiExpansionPanelSummary-root,
          .MuiExpansionPanelSummary-root.Mui-expanded,
          .MuiExpansionPanel-root:before {
            background-color: #a6eddd;
          }
        }

        &.outbound {

          .MuiExpansionPanelSummary-root,
          .MuiExpansionPanelSummary-root.Mui-expanded,
          .MuiExpansionPanel-root:before {
            background-color: #fffce8;
          }
        }

        strong {
          &.inbound-timer {
            color: #000000;
            font-size: 24px;
            font-weight: 300;
            line-height: 32px;
            display: inline-block;

            span {
              display: block;
              font-size: 12px;
              font-weight: 900;
              color: #253858;
              line-height: 32px;
            }
          }
        }

        span {
          &.user-bg {
            vertical-align: top;
            background-color: #d8ffeb;
            width: 56px;
            height: 56px;
            border: 1px solid #06a857;
            display: inline-block;
            text-align: center;
            line-height: 56px;
            border-radius: 50%;
            margin: 1px 15px 0 0px;

            i {
              color: #06a857;
              font-size: 26px;
              line-height: 51px;
            }
          }
        }

        .mail-timing {
          position: absolute;
          right: 39px;
          top: 13px;
          color: $blackB;
          font-size: 12px;
          font-weight: 700;
          line-height: 16px;

          a {
            &.recording-box {
              padding: 0 8px;
              position: relative;
              top: -1px;
            }
          }
        }

        .mail-heading {
          border-bottom: 1px solid #eaeaea;
          padding: 0 0 5px;
          margin: 0 0 0px;
          position: relative;
          width: 100%;

          p {
            font-size: 12px;
            font-weight: 700;
            color: $blackB;
            padding: 5px 0 0;
            margin: 0;
          }

          span {
            &.from-to {
              color: $blueM;
              font-size: 12px;
              font-weight: 400;
              padding: 5px 2px;
              white-space: break-spaces;
              word-break: break-all;
            }
          }

          .link-comments {
            text-align: right;
            padding: 5px 0;

            i {
              font-size: 22px;
              margin: 0px 7px;
              transform: rotate(-90deg);
              vertical-align: middle;
            }

            .comments-tag {
              border: 1px solid #eaeaea;
              border-radius: 21px;
              background: $whiteM;
              display: inline-block;
              width: 48px;
              height: 30px;
              text-align: center;
              padding: 0;
              cursor: pointer;
              line-height: 16px;
              position: relative;

              em {
                background: #e84444;
                border-radius: 50%;
                color: $whiteM;
                font-size: 10px;
                font-weight: 400;
                display: block;
                position: absolute;
                width: 16px;
                height: 16px;
                right: 0;
                top: -5px;
                font-style: normal;
              }

              img {
                margin: 6px 0 0;
              }
            }
          }

          .send-to-insurer {
            text-align: right;
            font-size: 10px;
            margin: -5px 0;

            span {
              font-size: 12px;

              svg {
                width: 20px;
                height: 20px;
              }
            }
          }
        }

        .inbound-data-box {
          box-shadow: 0px 6px 16px #3469cb29;
          margin: 20px 0;
          border-radius: 0 0 8px 8px;

          .innboud-call-heading {
            background-color: #ebe3ff;
            padding: 10px 15px;
            border-radius: 8px 8px 0 0;

            ul {
              &::before {
                content: none;
              }

              &.inbound-call-option {
                text-align: right;
                margin: 0;
                padding: 5px 0;

                li {
                  display: inline-block;
                  width: auto;
                  margin: 0 15px;
                  vertical-align: middle;
                  padding: 0;
                  text-align: center;

                  &::before {
                    content: none;
                  }

                  button {
                    background-color: $whiteM;
                    box-shadow: 0px 6px 16px #3469cb29;
                    min-width: 32px;
                    height: 32px;
                    margin-bottom: 5px;
                    padding: 0;
                    cursor: pointer;

                    &.call-end-btn {
                      min-width: 48px;
                      height: 48px;
                    }

                    .mic-on {
                      display: block;
                      margin: 10px;
                      height: 13px;
                      font-size: 18px;

                      .calender-icon,
                      .call-end-btn {
                        font-size: 16px;
                        color: $lightG;
                      }
                    }

                    span {
                      .mute-btn {
                        display: none;

                        .mic-off {
                          color: #d31414;
                          font-size: 26px;
                          margin: 7px 0 0;
                        }

                        .call-spilt {
                          color: #2196f3;
                          font-size: 26px;
                          margin: 7px 0 0;
                        }

                        .calender-icon {
                          color: #ff9800;
                          font-size: 26px;
                          margin: 7px 0 0;
                        }

                        .call-end-btn {
                          color: #d31414;
                          font-size: 28px;
                          margin: 7px 0 0;
                        }
                      }
                    }

                    &.active {
                      min-width: 48px;
                      height: 48px;

                      span {
                        .mute-btn {
                          display: block;
                        }
                      }

                      .mic-on {
                        display: none;
                      }
                    }
                  }

                  span {
                    font-size: 10px;
                    display: block;
                    font-weight: 300;
                    color: $blackB;
                  }
                }
              }
            }
          }

          .inbound-body-data {
            padding: 15px;
          }
        }

        span {
          &.email-tag {
            font-size: 12px;
            border-radius: 4px;
            display: inline-block;
            margin: 0 10px 0 0;
            // height: 20px; 
            min-width: 60px;
            text-align: center;
            padding: 2px 10px;
            line-height: 21px;

            &.email {
              background: #dcf6e2;
              color: #4daa51;
            }

            &.inbound {
              color: #be8609;
              background-color: #fee4c0;
            }

            &.my-account {
              background-color: #ffc9c9;
              color: #a55151;
            }

            &.chatbot {
              background-color: #4086a7;
              color: #deebf1;
            }

            &.outbound {
              background-color: #ffffcc;
              color: #888800;
            }

            &.endorsement {
              background-color: #e8c4fc;
              color: #9f4fcb;
            }

            &.esc {
              color: $whiteM;
              background-color: #ff7474;
              padding: 2px 5px;

              em {
                color: $whiteM;
                font-size: 22px;
                font-style: normal;
                vertical-align: bottom;
                padding: 0 3px 0 2px;
              }
            }
          }
        }

        .mail-data {
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          color: $blackB;
          padding: 10px 5px;

          p {
            margin: 5px 0;
            font-size: 16px;
          }

          .downlaod-item {
            border: 1px solid #eaeaea;
            position: relative;
            padding: 10px 0px;
            width: 148px;
            display: inline-block;
            border-radius: 8px;
            height: 42px;
            vertical-align: middle;
            margin: 10px 8px 5px 0;

            // box-shadow: 0px 0px 3px 1px #3469cb29;
            .file-extn {
              position: absolute;
              left: 10px;
              top: 9px;

              img {
                width: 22px;
                margin: 1px 0 0;
              }
            }

            p {
              padding: 3px 0 0 45px;
              color: #303030;
              font-size: 12px;
              font-weight: 400;
              line-height: 14px;

              span {
                &.doc-type {
                  font-size: 12px;
                  color: $lightG;
                  display: block;
                  font-weight: 400;
                }
              }
            }
          }

          .audio-box {
            border: 1px solid #eaeaea;
            border-radius: 8px;
            width: 120px;
            padding: 10px 8px;
            margin: 10px 0;
            max-width: 100%;
            position: relative;

            a {
              text-decoration: underline;

              button {
                min-width: auto;
                color: #0065ff;
                font-size: 12px;
                line-height: 16px;
                padding: 0 5px 0 0;

                img {
                  margin-right: 3px;
                }
              }
            }

            button {
              min-width: 50px;
            }

            .player {
              position: relative;
              top: 0;
              width: 270px;
              max-width: 80%;
              display: inline-block;
              left: -5px;

              .MuiLinearProgress-colorPrimary {
                border-radius: 4px;
              }

              .MuiLinearProgress-barColorPrimary {
                background-color: $blueM;
              }
            }

            .audio-progress {
              max-width: 100%;
            }

            .time {
              display: flex;
              position: absolute;
              right: 13px;
              top: 34%;
              font-size: 12px;
              color: #4887f7;
            }
          }

          table {
            pre {
              white-space: pre-wrap;
              /* Since CSS 2.1 */
              white-space: -moz-pre-wrap;
              /* Mozilla, since 1999 */
              white-space: -pre-wrap;
              /* Opera 4-6 */
              white-space: -o-pre-wrap;
              /* Opera 7 */
              word-wrap: break-word;
              /* Internet Explorer 5.5+ */
              table{
                tr{
                  td{
                    p{
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
          pre {
            white-space: pre-wrap; /* Since CSS 2.1 */
            white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
            white-space: -pre-wrap; /* Opera 4-6 */
            white-space: -o-pre-wrap; /* Opera 7 */
            word-wrap: break-word; /* Internet Explorer 5.5+ */
            p{
              font-size: 12px;
            }
          }
        }

        button {
          text-transform: capitalize;

          // box-shadow: 0px 6px 6px #3469cb29;
          &.response-chip {
            border: 1px solid #eaeaea;
            font-size: 12px;
            padding: 6px 10px;
            display: inline-block;
            border-radius: 38px;
            margin: 3px 3px 0;
          }

          &:hover {
            background-color: transparent;
          }

          &.active {
            box-shadow: 0px 6px 6px #3469cb29;
            border: 1px solid #4887f7b8;

            span {
              color: #0065ff;
            }
          }
        }

        .create-update {
          text-align: right;
          padding-right: 1px;
        }

        .ticket-status {
          border: 1px solid #eaeaea;
          width: 349px;
          border-radius: 16px 16px 16px 0px;
          position: relative;
          padding: 15px 0 0 70px;
          margin: 20px 0 10px;
          max-width: 75%;
          min-height: 77px;

          .ticket-time {
            position: absolute;
            right: 10px;
            top: 14px;
            font-size: 12px;
            color: $blackB;
          }

          h6 {
            margin: 0 0 5px;
            font-size: 12px;
            font-weight: 600;
            color: $blackB;
          }

          p {
            margin-bottom: 5px;
            font-size: 12px;
            color: $blackM;

            a {
              color: $blueM;
            }
          }

          button {
            min-width: auto;
            cursor: pointer;

            &.reply-btn {
              color: $blueM;
              margin-bottom: 10px;
              font-size: 12px;
              padding: 0;
            }
          }

          .employe-name {
            width: 43px;
            height: 26px;
            display: block;
            text-align: center;
            background: #fda62f4d;
            border-radius: 50%;
            padding: 10px 0;
            font-size: 14px;
            position: absolute;
            left: 10px;
            line-height: 24px;
          }
        }

        .inner-ticket-status {
          width: 339px;
          position: relative;
          padding: 15px 0 0 70px;
          margin: 10px 0 30px 10px;
          max-width: 74%;
          border: none;
          border-left: 1px solid #eaeaea;
          border-radius: 0;

          .employe-name {
            background: #2ffd7e4d;
          }
        }

        &.pb-reply {
          &::before {
            display: none;
          }
        }
      }
    }

    .logs-block {
      margin: 40px 0 0;

      &::before {
        display: none;
      }

      li {
        &::before {
          display: none;
        }
      }
    }

    .sub-status {
      background: #a0b0c633;
      border-radius: 8px;
      padding: 15px 10px;
      position: relative;
      margin: 0px 0 0 10px;
      width: 97.6%;
      border: 1px solid #ddd;
      opacity: 0.9;

      /* display: none; */
      &::before {
        position: absolute;
        background: #a0b0c6;
        width: 8px;
        height: 8px;
        content: "";
        left: -16px;
        top: 46%;
        border-radius: 50%;
      }

      &:hover {
        &::before {
          background: #0065ff;
        }

        border: 1px solid #0065ff;
        opacity: 1;
      }

      .status-date-time {
        position: absolute;
        right: 8px;
        top: 3px;
        color: $blackB;
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
      }

      .status-txt {
        position: relative;

        h5 {
          font-size: 14px;
          color: $blackB;
          line-height: 20px;
          font-weight: 300;
          margin: 0 0 10px;
          width: 84%;

          strong {
            font-weight: 700;
          }
        }

        p {
          font-size: 12px;
          color: $blackB;
          font-weight: 300;
          line-height: 16px;
          margin: 0 0 10px;

          strong {
            font-weight: 700;
          }
        }
      }
    }

    .inbound-call-data {
      .MuiAppBar-colorDefault {
        background-color: transparent;
        box-shadow: none;

        button {
          box-shadow: none;
        }
      }

      .tab-pannel {
        border-top: 2px solid #eaeaea;

        .MuiBox-root {
          padding: 0;
        }

        .enter-remarks {
          textarea {
            border: 1px solid #5e6c84;
            font-family: "Roboto", sans-serif;
            border-radius: 8px;
            width: 96%;
            padding: 10px;
            margin: 10px auto;
            display: block;
            resize: none;
          }

          .submit-box {
            padding: 10px;
            text-align: right;

            button {
              cursor: pointer;

              &.submit-btn {
                border: 1px solid $blueM;
                min-width: 120px;
                height: 36px;
                background-color: $blueM;
                text-align: center;

                span {
                  color: $whiteM;
                }
              }
            }
          }
        }

        .payment-detail {
          textarea {
            border: 1px solid #5e6c84;
            font-family: "Roboto", sans-serif;
            border-radius: 8px;
            width: 96%;
            padding: 10px;
            margin: 10px auto;
            display: block;
            resize: none;
          }

          .submit-box {
            padding: 10px;
            text-align: right;

            button {
              cursor: pointer;

              &.submit-btn {
                border: 1px solid $blueM;
                min-width: 120px;
                height: 36px;
                background-color: $blueM;
                text-align: center;

                span {
                  color: $whiteM;
                }
              }
            }
          }
        }
      }
    }

    .spinner-loader {
      position: relative;
      left: 50%;
      right: 50%;
      margin: auto;
      width: 100%;
      top: 0;

      svg {
        color: $blueM;
      }
    }
  }

  // responsive
  @media all and (max-width: 1360px) {
    .insurer-dashbaord {
      .dashboard {
        ul {
          li {
            width: 94.8%;
          }
        }
      }
    }
  }
}

@media all and (max-width: 1180px) {
  .insurer-dashbaord {
    .dashboard {
      ul {
        li {
          width: 94.8%;
        }
      }
    }
  }
}

@media all and (max-width: 1080px) {
  .insurer-dashbaord {
    .dashboard {
      ul {
        li {
          width: 94.5%;
        }
      }
    }
  }
}

@media all and (max-width: 1024px) {
  .insurer-dashbaord {
    .dashboard {
      ul {
        li {
          width: 94.3%;
        }
      }
    }
  }
}

@media all and (max-width: 680px) {
  .insurer-dashbaord {
    .dashboard {
      ul {
        li {
          &.leftbar {
            width: 5.5%;
          }

          &.rightbar {
            width: auto;
          }
        }
      }
    }

    .insurer-admin-dash {
      .profile-box {
        width: 123px;
      }

      .ticket-sammary-list {
        .ticket-summary-head {
          display: none;
        }

        .ticket-summary-body {
          box-shadow: 2px 3px 3px 1px #00000029;
          max-height: 350px;
          overflow-y: scroll;
          padding: 5px;
          background-color: #efefef;

          ul {
            background-color: #fff;
            margin: 0px 5px 10px;

            &:nth-child(even) {
              background-color: #fff;
            }

            li {
              width: 45%;
              padding: 5px;

              &.name {
                width: 45%;
              }

              span {
                display: block;
                padding-bottom: 5px;
              }

              text-align: left;

              &:nth-child(even) {
                text-align: right;
              }

              button {
                min-width: 0;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .insurer-dashbaord {
    .user-manage {
      .user-detail {
        .user-detail-head {
          display: none;
        }

        .user-detail-body {
          ul {
            li {
              width: 15%;

              &:nth-child(even) {
                //  text-align: right;
              }

              &:last-child {
                width: 9%;
              }

              span {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 480px) {
  .insurer-dashbaord {
    .user-manage {
      .user-detail {
        .user-detail-head {
          display: none;
        }

        .user-detail-body {
          ul {
            li {
              width: 28%;

              //  &:nth-child(even){
              //      text-align: right;
              //  }
              &:last-child {
                width: 28%;
              }

              span {
                display: block;
              }
            }
          }
        }
      }

      .create-user-box {
        ul {
          li {
            width: 100%;
            margin: 15px 0 0;

            &:nth-child(1) {
              margin-top: 20px;
            }

            .form-control {
              .MuiFormControl-marginNormal {
                margin-top: 0px;
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }

    .insurer-admin-dash {
      .insurer-admin-dash {
        .ticket-list-view {
          ul {
            li {
              width: 29%;
            }
          }
        }
      }

      .search-option-list {
        ul {
          li {
            width: 95%;
          }
        }
      }
    }
  }
}

// insurer-ticket-sidebar
.insurer-ticket-sidebar {
  background-color: #f2f7ff;
  padding: 5px 15px 50px;
  width: 300px;
  position: fixed;
  left: 48px;
  top: 55px;
  height: calc(100vh - 4%);
  overflow: scroll;
  border-left: 1px solid #ccc;

  .agent-name {
    position: relative;
    margin: 5px 0 8px;
    min-height: 60px;
    vertical-align: middle;

    span {
      &.short-name {
        position: absolute;
        background-color: #c9e5f8;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border-radius: 50%;
        border: 4px solid #fff;
        left: 0;
        color: #0065ff;
        font-size: 14px;
        font-weight: 900;
      }
    }

    h5 {
      padding: 16px 0 0 70px;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
    }

    h2 {
      color: grey;
      text-align: left;
      display: inline-block;
      font-size: 14px;
      position: relative;
      font-weight: 500;
      margin: 30px 9px 5px;

      span {
        a {
          color: #0065ff;
          font-size: 12px;
          font-weight: 600;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .all-ticket-header {
    position: relative;
    vertical-align: middle;
    margin: 10px 0;
    min-height: 179px;

    h2 {
      margin: 5px 5px 0;
    }

    .user-info-detail {
      margin: 0 0 8px;

      .logo-box {
        position: absolute;
        top: 5px;
        left: 0;
        background: $whiteM;
        padding: 4px 0;

        img {
          max-width: 100px;
          max-height: 42px;
        }
      }

      .title-box {
        padding: 4px 0 0 115px;
        min-height: 45px;

        h5 {
          font-size: 18px;
          line-height: 24px;
          font-weight: 500;
          padding: 7px 0 0;
        }
      }
    }

    .booking-deatil-box {
      margin: 5px 0 0;

      .edit-mode-view {
        margin: 15px 0;

        .MuiFormControl-marginNormal {
          margin: 0;
        }

        .MuiOutlinedInput-input {
          padding: 12.5px 14px;
          font-size: 12px;
          color: #253858;
          text-transform: capitalize;
        }

        .MuiInputLabel-outlined {
          top: -5px;
          font-size: 12px;
          color: #253858;
          opacity: 1;
          min-width: auto;
          text-transform: capitalize;
        }

        .MuiInputLabel-outlined.MuiInputLabel-shrink {
          top: 1px;
          font-size: 14px;
          // background-color: #fff;
        }

        .MuiSelect-select:focus {
          background-color: transparent;
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
          border-color: $blueM;
          border-width: 1px;
        }
      }

      ul {
        &.booking-list-view {
          margin: 0 0 5px;

          li {
            width: 46%;
            display: inline-block;
            margin: 6px 0;
            padding: 0 5px;

            span {
              font-size: 10px;
              color: #253858;
              line-height: 16px;
              font-weight: 300;
            }

            strong {
              font-size: 12px;
              color: #253858;
              display: block;
              line-height: 19px;
              font-weight: 400;
              word-break: break-word;
            }
          }
        }
      }
    }
  }

  .card {
    border-radius: 8px;
    margin: 8px 0 20px;
    position: relative;
    box-shadow: 0px 6px 16px #3469cb29;
    background-color: $whiteM;
    // cursor: pointer;

    &.active {
      border: 2px solid $blueM;

      .tiked-icon {
        display: block;
      }
    }

    &.pb-parnter {
      background-color: #c9dffd;
    }

    .tiked-icon {
      position: absolute;
      right: 6px;
      top: 15px;
      display: none;
    }

    h4 {
      padding: 15px 15px 5px;
      font-size: 15px;
      line-height: 20px;
      margin: 0;
      font-weight: 500;

      button {
        &.auto-closue-btn {
          background-color: #0065ff;
          margin: 5px;
          display: inline-block;
          min-width: 20px;
          height: 20px;
          line-height: 20px;
          padding: 0 3px;
          font-weight: 700;

          span {
            color: #fff;
          }
        }
      }
    }

    .ticket-card-list {
      ul {
        display: block;
        padding: 0 15px 10px 15px;
        margin: 0;

        li {
          display: inline-block;
          width: 49%;
          margin: 5px 0;
          padding: 0;
          word-break: break-word;
          vertical-align: top;

          span {
            color: #919bab;
            font-weight: 300;
            line-height: 16px;
            font-size: 11px;

            &.cust-res {
              color: #00bcd4;
            }

            &.target-res {
              color: #ff900b;
            }
          }

          strong {
            color: $blackB;
            font-size: 13px;
            line-height: 19px;
            display: block;
            padding: 0 4px 0 0;
            font-weight: 400;

            &.ticket-id-bold {
              font-weight: 900;
            }

            span {
              display: block;
              word-break: break-word;
              color: #253858;
              font-size: 12px;
            }
          }
        }

        div {
          &.issue-name {
            li {
              margin: 0 0 5px;
              width: 100%;

              span {
                color: #172b4d;
                font-weight: 300;
                line-height: 16px;
                font-size: 12px;
              }

              strong {
                color: $blackB;
                font-size: 14px;
                line-height: 19px;
                display: block;
                font-weight: 400;

                span {
                  font-size: 12px;
                  font-weight: 500;
                }

                button {
                  &.auto-closue-btn {
                    background-color: #0065ff;
                    margin: 5px;
                    display: inline-block;
                    min-width: 20px;
                    height: 20px;
                    line-height: 20px;
                    padding: 0 3px;
                    font-weight: 700;

                    span {
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    button {
      &.right-btn {
        position: absolute;
        right: 15px;
        top: 18px;
      }
    }

    .ticket-card-footer {
      background: #fffbed;
      position: relative;
      padding: 10px 15px;
      border-radius: 0 0 8px 8px;

      &.resolved {
        background: #e3fcef;

        .footer-data {
          h6 {
            color: #19b24d;
            margin: 3px 0 5px;
            line-height: 21px;
            font-weight: 600;
          }
        }

        img {
          top: 16px;
          left: 14px;
        }
      }

      img {
        position: absolute;
        left: 15px;
        top: 14px;
      }

      .footer-data {
        padding: 0 0 0 20px;

        h6 {
          color: #ff900b;
          font-size: 16px;
          line-height: 21px;
          font-weight: 600;
          margin: 0px 0 3px;
          line-height: 21px;
        }

        strong {
          font-size: 14px;
          color: #172b4d;
          line-height: 19px;
          font-weight: 500;
          display: block;
          margin-bottom: 3px;
        }

        span {
          color: #5e6c84;
          font-size: 12px;
        }
      }
    }

    &:hover {
      cursor: pointer;
    }

    .tag-btn {
      position: relative;
      width: 81%;
      padding: 10px 15px 0;

      span {
        display: inline-block;
        min-width: 40px;
        padding: 5px;
        //height: 16px;
        line-height: 18px;
        text-align: center;
        background: #f6bfbe;
        color: #b65757;
        border-radius: 4px;
        margin-right: 5px;

        &.krg-icon {
          background: #dcf6e2;
          color: #4daa51;
        }
      }
    }
  }
}

// insurer-ticekt-view
.insurer-ticekt-view {
  padding: 0 0 0 15px;
  max-width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 8px);

  button{
    &.endorsement-req-btn{
      padding: 8px 19px;
      background-color: #0075FF;
      color: #fff;
      border-radius: 22px;
      min-width: 90px;
      height: 38px;
      text-transform: capitalize;
      margin: 7px 10px 0 0;
      div{
        display: inline-block;
      }
      .MuiCircularProgress-colorPrimary {
        color: #eceefa;
        position: relative;
        top: 4px;
        width: 18px!important;
        height: 18px !important;
      }
    }
  }

  ul {
    li {
      &.right-bar {
        padding-left: 370px;
        background: #fff;
        padding-top: 60px;

        .comment-history {
          ul {
            padding: 0;
            height: 300px;
            display: flex;

            li {
              &.comment-pannel {
                button {
                  &.submit-btn {
                    margin: 8px 0 0;
                    background-color: #0075ff;
                    color: #fff;
                    border-radius: 22px;
                    width: 90px;
                    height: 38px;
                    text-transform: capitalize;
                  }
                }
              }

              ul {
                display: block;

                &.comment-trail {
                  min-height: 100px;
                  max-height: 233px;
                  overflow-y: scroll;
                }

                li {
                  width: 93%;
                }
              }
            }
          }
        }
      }
    }
  }
}
.MuiAutocomplete-popper{
  ul{
    padding:8px;
    color:#ff0000;
    li{
      margin: 8px 0;
      font-size: 14px;
      color: #253858;
      font-weight: 400;
    }
    
  }
}