// ticket-chace-management
.ticket-chace-management{
    padding:10px 20px;
    background: #fff;
    h2{
        font-size: 18px;
        line-height: 22px;
        text-transform: capitalize;
        color:#253858;
        font-weight: 900;
        display: block;
    }
    .ticket-chace-management-box{
        background-color: #e5e9ef;
        .cache-serch-filter{
            margin: 10px 10px 20px;
            position: relative;
            top: 15px;
            height: 40px;
            border-bottom: 2px solid #ccc;
            padding: 0 0 9px;
            z-index: 99;
            .form-control{
                background-color: #fff;
                border-radius: 4px;
                width:30%;
                input{
                    padding: 8px;
                    border-radius: 4px;
                }
                .MuiInput-underline:before,.MuiInput-underline:after{
                    display: none;
                }
            }
        }
        ul{
            &.main-list{
                padding: 0 0 50px;
                display: inline-block;
                width: 100%;
                max-height:600px;
                overflow-x: hidden;
                overflow-y:auto;
                position: relative;
                padding-top: 10px;
                @media (max-width: 1366px){
                    max-height:400px;
                }
                li{
                    display: inline-block;
                    font-size: 14px;
                    font-weight: 600;
                    vertical-align: top;
                    width: 98.5%;
                    text-transform: capitalize;
                    position: relative;
                    padding: 0 0 0 20px;
                    &::before{
                        content: "";
                        background-color:#0165ff;
                        border-radius: 50%;
                        height: 10px;
                        width: 10px;
                        position: absolute;
                        left: 10px;
                        top: 15px;
                    }
                    &::after{
                        content: "";
                        background-color: #0165ff;
                        height: 100%;
                        width: 2px;
                        position: absolute;
                        left: 14px;
                        top: 15px;
                    }
                    h4{
                        padding: 10px;
                        font-size: 14px;
                        text-transform: capitalize;
                        font-weight: 900;
                        color:#253858;
                    }
                    ul{
                        &.inner-list{
                            padding: 10px 0;
                            width: 100%;
                            background-color: #fff;
                            padding:10px;
                            li{
                                display: inline-block;
                                background-color: #fff;
                                width: auto;
                                padding: 0;
                                margin: 0 20px 5px 0;
                                position: relative;
                                button{
                                    margin-right: 10px;
                                    border-radius: 4px;
                                    background-color: #f5f5f5;
                                    border:1px solid #ccc;
                                    display: inline-block;
                                    margin-bottom: 5px;
                                    cursor: pointer;
                                    text-transform: capitalize;
                                    color:#0052cc;
                                    font-size: 12px;
                                    font-weight: 900;
                                    padding: 5px 8px 5px 5px;
                                    &:last-child{
                                        margin: 0;
                                    }
                                    &.delete-btn{
                                        background-color: #c73e3e;
                                        border: none;
                                        color: #fff;
                                        font-size: 10px;
                                        min-width: 14px;
                                        padding: 0;
                                        height: 14px;
                                        border-radius: 20px;
                                        line-height: 11px;
                                        position: absolute;
                                        top: 0;
                                        right: -6px;
                                        bottom: 0;
                                        margin: auto;
                                    }
                                }
                                &::before,&::after{
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
    
}
.view-cache{
    padding:20px;
    word-break: break-word;
    line-height: 22px;
    height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
}