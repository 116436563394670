// create-dialog-box
.create-dialog-box {
  position: fixed;
  box-shadow: -7px 6px 6px #4c4c4c29;
  top: 0;
  width: 410px;
  right: 0;
  z-index: 99;
  background-color: #fff;
  height: 100%;
  .MuiToolbar-regular {
    padding: 0;
    min-height: 10px;
  }
  .all-comments {
    padding: 10px;
    width: 95%;
    height: 570px;
    overflow-x: scroll;
    overflow-y: scroll;
    ul {
      &.comments {
        text-align: left;
        ul {
          li {
            .upload-item {
              border: 1px solid #eaeaea;
              position: relative;
              padding: 5px 0px;
              width: 125px;
              display: inline-block;
              border-radius: 8px;
              min-height: 32px;
              vertical-align: middle;
              margin: 2px 6px 5px 0;
              // box-shadow: 0px 0px 3px 1px #3469cb29;
              .file-extn {
                position: absolute;
                left: 10px;
                top: 6px;
                img {
                  width: 20px;
                  margin: 3px 0 0;
                }
              }
              p {
                padding: 10px 2px 0 34px;
                color: #303030;
                font-size: 10px;
                font-weight: 400;
                line-height: 14px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                span {
                  &.doc-type {
                    font-size: 12px;
                    color: $lightG;
                    display: block;
                    font-weight: 400;
                  }
                }
              }
            }
          }
        }
      }
    }
    .comment-submit-data {
      background-color: #fff;
      width: 95%;
      .MuiSelect-select {
        text-align: left;
      }
      li {
        margin-right: 0;
        &:nth-child(1) {
          width: 72%;
        }
        .attach-box {
          position: relative;
          i {
            padding: 0;
            height: 20px;
            border: transparent;
          }
          .upload {
            left: -13px;
            width: 38px;
            top: -14px;
            height: 43px;
          }
        }
        &.attach-submit {
          button {
            box-shadow: none;
            border: transparent;
            padding: 0;
          }
        }
      }
    }
  }
}


@media all and (max-width: 1366px){
  .create-dialog-box{
    .all-comments{
      width: 93%;
      height: 494px;
      overflow-x: scroll;
      overflow-y: scroll;
    }
  }
}