// check-eligibility
.check-eligibility{
    button{
        &.check-eligibility-btn{
            color: #0065FF;
            text-transform: capitalize;
            font-size: 12px;
            padding: 0 12px 4px;
            &:hover{
                background-color: transparent;
            }
        }
    }
}

.cancellation-msg-box{
    text-align: center;
    font-size: initial;
    display: flex;
    justify-content: center;
    margin-top: 54px;
}

.check-eligibility-dialog{
    .MuiDialog-paperWidthSm{
        min-width:450px;
        max-width:700px;
        // max-width:100%;
        // min-height:100px;
        padding-bottom: 25px;
        .spinner-box{
            margin:10% auto;
        }
    }
    .dialog-head{
        position: relative;
        background-color: #A6EDDD;
        padding:15px 10px;
        p{
            color:#19B24D;
            font-size: 18px;
            font-weight: 600;
            img{
                width:18px;
            }
        }
        button{
            min-width: auto;
            &.close-icon{
                position: absolute;
                right: 5px;
                top: 0;
                bottom: 0;
                margin:auto;
                svg{
                    color:#808080;
                    width:20px;
                    height: auto;
                }
            }
        }
        &.ineligibility{
            background-color: #FBEBDF;
            p{
                color:#B65757;
                font-size: 18px;
                font-weight: 600;
            }
        }
    }
    .refund-cards{
        text-align: center;
        li{
            display: inline-block;
            padding:8px 10px;
            vertical-align: top;
            &:nth-child(1){
                width: 40%;
                .leftbar-card{
                    background-color: #EBF0F8;
                    border-radius: 12px;
                    margin: 20px 4px 20px 4px;
                    padding:15px 5px;
                    .header-box{
                        position: relative;
                        min-height: 50px;
                        .img-block{
                            background-color: #FFFFFF;
                            border-radius: 4px;
                            padding: 10px;
                            width: 80px;
                            margin: 4px 0;
                            position: absolute;
                            img{
                                max-width: 92%;
                                height: auto;
                                display: block;
                            }
                        }
                        h4{
                            font-size: 14px;
                            line-height: 21px;
                            color: #253858;
                            padding: 8px 8px 0 110px;
                        }
                    }
                    ul{
                        li{ 
                            width:50%;
                            display: inline-block;
                            margin:5px 0 0;
                            &:nth-child(even){
                                text-align: right;
                            }
                            span{
                                font-size: 10px;
                                color: #919BAB;
                                font-weight: 400;
                                // &.net-deduct{
                                //     color:#FF2C2C;
                                // }
                                // &.net-refund{
                                //     color:#00C63D;
                                // }
                                i{
                                    font-size: 11px;
                                    padding-right: 2px;
                                }
                            }
                            strong{
                                color: #253858;
                                display: block;
                                font-size: 12px;
                                line-height: 24px;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
            &:nth-child(2){
                width: 60%;
                ul{
                    &.eligiblty-list{
                        padding:20px 10px;
                        li{ 
                            width:50%;
                            display: inline-block;
                            margin:5px 0 0;
                            &:nth-child(odd){
                                text-align: right;
                            }
                            p{
                                font-size: 12px;
                                color:#3B3E51;
                                &.net-deduct{
                                    color:#FF2C2C;
                                }
                                &.net-refund{
                                    color:#00C63D;
                                }
                                i{
                                    font-size: 11px;
                                    padding-right: 2px;
                                }
                            }
                        }
                        .category-box{
                            background-color: #EBF0F8;
                            border-radius: 22px;
                            padding: 4px 5px;
                            margin-bottom: 8px;
                            li{
                                margin: 0;
                                &:nth-child(1){
                                    text-align: left;
                                }
                                &:nth-child(2){
                                    text-align: right;
                                }
                            }
                        }
                    }
                }
            }
            .flc-disclaimer{
                margin: 30px 0 0;
                width:calc(100% - 20px);
                p{
                    font-size: 14px;
                    line-height:18px;
                    padding-bottom: 10px;
                    font-family: "robotoregular";
                    strong{
                        font-weight: 900;
                        font-family: "robotobold";
                    }
                }
            }
        }
        &.pg-screen{
            > li{
               width:100%;
            }
        }
    
    }
    
   
    .reason-box{
        margin: 0 auto;
        padding: 0px 0;
        width: 100%;
        text-align: center;
    }
    button{
        cursor: pointer;
        &.save-btn{
            background-color: #0065FF;
            color: #fff;
            display: block;
            width: 100%;
            margin: 10px 0;
            border-radius: 8px;
            text-transform: capitalize;
            font-weight: 400;
            height: 42px;
            line-height: 34px;
            box-shadow: 0px 6px 16px #3469CB29;
            &.MuiButton-root.Mui-disabled{
                background-color: #D6D6D6;
            }
        }
        
        &.close-btn{
            background-color: #0065FF;
            color: #fff;
            display: block;
            width: 100%;
            margin: 10px 0;
            border-radius: 8px;
            text-transform: capitalize;
        }
    }
    .form-control{
        width:92%;
        border:2px solid #e2e2e27d;
        border-radius: 4px;
        background: transparent;
        .MuiInput-underline:before,.MuiInput-underline:after{
            border: transparent;
        }
        .MuiOutlinedInput-input {
            padding: 14.5px 14px;
        }
        .MuiInput-underline:hover:not(.Mui-disabled):before{
            border: transparent;
        }
        label + .MuiInput-formControl {
            margin-top: 0;
            padding: 2px 0;
        }
        .MuiInputLabel-shrink {
            top: -8px !important;
            left: 13px;
            background: #fff;
            z-index: 99;
            font-size: 16px !important;
        }
        .MuiInputLabel-formControl{
            top: -12px;
            left: 7px;
            font-size: 12px;
            color: #253858;
        }
        .MuiInputBase-input{
            padding: 8px 6px;
            font-size: 15px;
            font-weight: 500;
        }
        .MuiOutlinedInput-input {
            padding: 12.5px 14px;
            font-size: 12px;
            color: #253858;
            text-transform: capitalize;
          }
          .MuiInputLabel-outlined {
            top: -5px;
            font-size: 12px;
            color: #253858;
            opacity: 1;
            min-width: auto;
            text-transform: capitalize;
          }
          .MuiSelect-select.MuiSelect-select,
          .MuiSelect-select:focus {
            background-color: #fbfbfb5c;
          }
          .MuiInputLabel-outlined.MuiInputLabel-shrink {
            top: 1px;
            font-size: 14px;
            background-color: transparent;
          }
          .MuiSelect-select:focus {
            background-color: transparent;
          }
          .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: #0065ff;
            border-width: 1px;
          }
          .MuiSelect-selectMenu{
            padding: 8px;
            padding-right: 24px;
            font-size: 12px;
            color: #253858;
            font-weight: 500;
          }
    }
    .not-eligible{
        text-align: center;
        // display: none;
        .img-block{
            margin: 30px 0;
        }
        h5{
            font-size: 24px;
            line-height: 21px;
            color: #3B3E51;
            padding: 4px 8px;
            margin:20px 0;
        }
        p{
            font-size: 18px;
            line-height: 24px;
            color:#707070;
            margin:10px 0 20px;
            strong{
                font-weight: 600;
            }
        }
        button{
            cursor: pointer;
            &.close-btn{
                width: 192px;
                background: #0065FF;
                box-shadow: 0px 6px 16px #3469CB29;
                display: block;
                margin: 15px auto;
            }
            &.merge-ticket-btn{
                width: auto;
                background: #0065FF;
                box-shadow: 0px 6px 16px #3469cb29;
                display: block;
                margin: 15px auto;
                color: #fff;
                font-size: 12px;
                text-transform: capitalize;
                i{
                    margin:0 5px;
                }
            }
        }
        
        .eligible-yes{
            display:block;
        }
        .not-eligible-box{
            display:block;
            padding: 0 20px;
            p{
                &.not-eligible-txt{
                    background-color: #FFEBD6;
                    border-radius: 8px;
                    padding: 10px 8px;
                    text-align: center;
                    color:#A05B12;
                    font-size: 14px;
                    min-width:100px;
                }
            }
        } 
        
    }
    .eligible-yes{
        display: block;
    }
    .spinner-box{
        width:50px;
        margin:10px auto;
    }
}
.are-you-sure{
    display:block;
    text-align: center;
    padding: 30px 20px;
    h5{
        color:#3B3E51;
        font-size: 20px;
        line-height: 24px;
    }
    p{
        &.disclaimer-box{
            background-color: #FFEBD6;
            color: #914E07;
            border-radius: 30px;
            height: 20px;
            width: 450px;
            margin: 20px auto;
            max-width: 100%;
            padding: 12px 0 5px;
            font-size: 12px;
            strong{
                font-weight: 600;
            }
        }
    }
    button{
        width: 190px;
        height: 42px;
        line-height: 40px;
        border-radius: 8px;
        &.yes-btn{
            background-color: #0065FF;
            margin-right: 10px;
            color:#fff;
            &:hover{
                background-color: #0065FF;
            }
        }
        &.no-btn{
            background-color: #fff;
            border:1px solid #0055D9;
            color:#0065FF;
            &:hover{
                background-color: #fff;
            }
        }
    }
}