.one-ticke-tl{
    min-height: 400px;
    padding: 10px;
    .upload-container{
        border: 1.5px dashed #ccc;
        padding: 10px;
        margin-bottom: 20px;
        width: 470px;
        background-color: #ddebff4a;
        text-align: left;
        @media (max-width: 768px){
            max-width: 95%;
        }
    }
    h5{
        font-size: 16px;
        margin: 0 0 3px;
        padding: 5px 4px;
        font-weight: 700;
    }
    .uplaod-doc-view{
        vertical-align: middle;
        display: inline-block;
        position: relative;
        left: 0;
        top: 0;
        span{
            &.file-detail{
                font-size: 12px;
                background: #ddebff;
                border-radius: 22px;
                height: 30px;
                display: inline-block;
                line-height: 28px;
                position: relative;
                top: -1px;
                padding: 0 0 0 10px;
                margin: 0 0 0 7px;
                svg{
                    font-size: 12px;
                    background: grey;
                    border-radius: 50%;
                    padding: 2px;
                    color: #fff;
                    margin: -6px 3px 0 0;
                    height: 10px;
                    width: 10px;
                    top: 4px;
                    position: relative;
                    right: -6px;
                }
            }
        }
    }
    button{
        &.download-sample-btn{
            background-color: #0165ff;
            color: #fff;
            text-transform: capitalize;
            border: 1px solid #0165ff;
            height: 36px;
            margin: 15px 0 5px;
            min-width: 100px;
            i{
                margin-right: 10px;
            }
            &:disabled{
                background-color: #cfcfd0;
                color:#808080;
                border:1px solid #cfcfd0;
            }
        }
        &.selected-btn{
            background-color: transparent;
            color: #0165ff;
            min-width: 40px;
            padding: 0 4px;
            border: 1px solid #0165ff;
            margin:4px 0;
            svg{
                margin-right: 0;
                font-size: 24px;
                color: #0165ff;
            }
        }
        
        svg{
            color:#fff;
        }
    }
    a{
        &.download-sample-btn{
            color:#0165ff;
            padding:0; 
            font-size: 14px;
            text-decoration: underline;
            margin:5px 0;
            display: block;
            cursor: pointer;
        }
    }
}