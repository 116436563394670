.attach-box {
  position: absolute;
  margin: 0;
  top: -1px;
  width: 30px;
  padding: 0;
  .upload-btn {
    position: relative;
    z-index: 0;
    display: inline-block;
    cursor: pointer;
    padding: 10px;
    font-size: 20px;
    // border: 2px solid #3498db;
  }
  .upload {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 69px;
    height: 37px;
    top: -21px;
    left: 7px;
    opacity: 0;
    cursor: pointer;
  }
  &.email-attach-download{
    width:100%;
    .attachemt-link{
      cursor: pointer;
    }
    button{
      &.download-btn{
        color:#0065ff;
        font-size: 12px;
        // border:1px solid #0065ff;
        // border-radius: 4px;
        padding: 4px;
      }
    }
    // button{
    //   &.attach-item{
    //     background: transparent;
    //     color: #2196f3;
    //   }
    // }
  }
}

 

