button{
    &.show-more-btn{
        color: #253858;
        font-size: 12px;
        width: 100%;
        text-align: center;
        position: relative;
        &::after{
            border-bottom: 1px solid #EAEAEA;
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            top: 11px;
            z-index: 0;
            opacity: 1.3;
            height: 4px;
            box-shadow: 3px 5px 3px -2px #3469cb29;
            text-transform: capitalize;
        }
        span{
            >span{
                background-color: #fff;
                border-radius: 4px;
                color: #007bff;
                z-index: 5;
                width: 75px;
                text-transform: capitalize;
            }
        }
        &:hover{
            background-color: transparent;
            cursor: pointer;
        }
    }
    &.show-detail-btn{
        color: #007bff;
        font-size: 12px;
        padding:0 2px;
        min-width: auto;
        text-align: center;
        position: relative;
        text-transform: capitalize;
        display: block;
        
        // &::after{
        //     content: ">";
        //     position: absolute;
        //     right: -11px;
        //     top: -4px;
        //     z-index: 0;
        //     opacity: 1.3;
        //     height: 8px;
        //     text-transform: capitalize;
        //     font-size: 16px;
        // }
        &:hover{
            background-color: transparent;
            cursor: pointer;
        }
    }
    
}
