// follow-up-setup
.follow-up-setup{
    button{
        min-width: auto;
        padding:0;
        &.add-cal{
            margin:0 3px;
            img{
                width:32px;
            }
        }
    }
    .btn-sec{
        text-align: right;
    }
    .date-sec{
        padding:14px 0px 0;
        .react-datepicker__input-container{
            margin:0 8px;
        }
        .follow-up-cal{
            position: absolute;
            width: 90%;
            padding:5px 0 10px;
            span{
                display: inline-block;
                min-width: 45px;
                background: #f5f5f5;
                text-align: center;
                padding: 10px 0;
                border-radius: 4px;
                margin: 0 3px;
                height: 23px;
                line-height: 14px;
                color: #000;
                cursor: pointer;
                &.active{
                    background: #2196f3;
                    font-weight: 700;
                    font-size: 14px;
                    color: #fff;
                }
            }
            .react-datepicker__input-container {
                position: relative;
                display: inline-block;
                width: 100%;
                min-height: 40px;
                line-height: 41px;
            }
        }
    }
    .time-slot{
        margin:55px 0 0;
        ul{
            li{
                width:auto;
                display: inline-block;
                margin:0 4px 10px 0;
                padding:0;
                position: relative;
                button{
                    border: 1px solid #E0E0E0;
                    color:#2E2E2E;
                    font-size: 12px;
                    text-align: center;
                    border-radius: 8px;
                    min-width: 56px;
                    height: 32px;
                    line-height: 32px;
                    margin:0 2px;
                    &:hover{
                        border:1px solid #09B18A;
                        background-color: #1DDBAF;
                        color:#fff;
                    }
                    &:focus{
                        border:1px solid #09B18A;
                        background-color: #1DDBAF;
                        color:#fff;
                    }
                }
                ul{
                    box-shadow: 0px 0px 16px #00000029;
                    border-radius: 8px;
                    display: none;
                    position: absolute;
                    background-color: #fff;
                    min-width: 160px;
                    z-index: 99;
                    text-align: center;
                    left:0;
                    right: 0;
                    margin:auto;
                    span{
                        color:#808080;
                        font-size: 12px;
                        line-height: 24px;
                        display: block;
                        padding:0;
                    }
                    em{
                        &.arrow-icon{
                            width: 0;
                            height: 0;
                            border-left: 10px solid transparent;
                            border-right: 10px solid transparent;
                            border-bottom: 12px solid #fff;
                            font-size: 0;
                            line-height: 0;
                            top: -10px;
                            position: absolute;
                            // right: 0;
                            left: 15px;
                            margin: auto;
                            box-shadow: none;
                            
                        }
                    }
                    li{
                      button{
                          border-radius: 50%;
                          border: 1px solid #707070;
                            min-width: 25px;
                            height: 25px;
                            line-height: 25px;
                            color: #808080;
                            font-size: 12px;
                            border-radius: 26px;
                      }  
                    }
                }
                &:hover{
                    ul{
                        display: block;
                    }
                }
            }
        }
    }
}